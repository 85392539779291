import React from "react";

function ProfileDetailsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_62_69"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_62_69)">
        <path
          d="M26.6 13V10H44V13H26.6ZM26.6 21V18H44V21H26.6ZM26.6 29V26H44V29H26.6ZM16 27.85C14.3 27.85 12.8917 27.2917 11.775 26.175C10.6583 25.0583 10.1 23.65 10.1 21.95C10.1 20.25 10.6583 18.8417 11.775 17.725C12.8917 16.6083 14.3 16.05 16 16.05C17.7 16.05 19.1083 16.6083 20.225 17.725C21.3417 18.8417 21.9 20.25 21.9 21.95C21.9 23.65 21.3417 25.0583 20.225 26.175C19.1083 27.2917 17.7 27.85 16 27.85ZM4 40V36.7C4 36.0712 4.15 35.4723 4.45 34.9034C4.75 34.3345 5.15 33.9333 5.65 33.7C7.15 32.6333 8.79167 31.8417 10.575 31.325C12.3583 30.8083 14.1667 30.55 16 30.55C17.8333 30.55 19.6333 30.8333 21.4 31.4C23.1667 31.9667 24.8167 32.7333 26.35 33.7C26.8167 34.0333 27.2083 34.4583 27.525 34.975C27.8417 35.4917 28 36.0667 28 36.7V40H4ZM6.75 36.45V37H25.25V36.45C23.95 35.5833 22.45 34.8833 20.75 34.35C19.05 33.8167 17.4667 33.55 16 33.55C14.5333 33.55 12.9417 33.8167 11.225 34.35C9.50833 34.8833 8.01667 35.5833 6.75 36.45ZM16 24.85C16.8667 24.85 17.5667 24.5833 18.1 24.05C18.6333 23.5167 18.9 22.8167 18.9 21.95C18.9 21.0833 18.6333 20.3833 18.1 19.85C17.5667 19.3167 16.8667 19.05 16 19.05C15.1333 19.05 14.4333 19.3167 13.9 19.85C13.3667 20.3833 13.1 21.0833 13.1 21.95C13.1 22.8167 13.3667 23.5167 13.9 24.05C14.4333 24.5833 15.1333 24.85 16 24.85Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default ProfileDetailsIcon;
