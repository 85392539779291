import React from "react";

function SaveCardsIcon() {
  return (
    <svg
      width="180"
      height="180"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 57.8125H53.125C56.5768 57.8125 59.375 55.0143 59.375 51.5625V21.875C59.375 18.4232 56.5768 15.625 53.125 15.625H6.25C2.79824 15.625 0 18.4232 0 21.875V51.5625C0 55.0143 2.79824 57.8125 6.25 57.8125Z"
        fill="#5A5254"
      />
      <path
        d="M32.8125 51.5625H51.5625C52.4262 51.5625 53.125 50.8629 53.125 50C53.125 49.1371 52.4262 48.4375 51.5625 48.4375H32.8125C31.9488 48.4375 31.25 49.1371 31.25 50C31.25 50.8629 31.9488 51.5625 32.8125 51.5625Z"
        fill="white"
      />
      <path
        d="M32.8125 45.3125H39.0625C39.9262 45.3125 40.625 44.6129 40.625 43.75C40.625 42.8871 39.9262 42.1875 39.0625 42.1875H32.8125C31.9488 42.1875 31.25 42.8871 31.25 43.75C31.25 44.6129 31.9488 45.3125 32.8125 45.3125Z"
        fill="white"
      />
      <path
        d="M20.3125 45.3125H26.5625C27.4262 45.3125 28.125 44.6129 28.125 43.75C28.125 42.8871 27.4262 42.1875 26.5625 42.1875H20.3125C19.4488 42.1875 18.75 42.8871 18.75 43.75C18.75 44.6129 19.4488 45.3125 20.3125 45.3125Z"
        fill="white"
      />
      <path
        d="M20.3125 51.5625H26.5625C27.4262 51.5625 28.125 50.8629 28.125 50C28.125 49.1371 27.4262 48.4375 26.5625 48.4375H20.3125C19.4488 48.4375 18.75 49.1371 18.75 50C18.75 50.8629 19.4488 51.5625 20.3125 51.5625Z"
        fill="white"
      />
      <path
        d="M7.8125 45.3125H14.0625C14.9262 45.3125 15.625 44.6129 15.625 43.75C15.625 42.8871 14.9262 42.1875 14.0625 42.1875H7.8125C6.94883 42.1875 6.25 42.8871 6.25 43.75C6.25 44.6129 6.94883 45.3125 7.8125 45.3125Z"
        fill="white"
      />
      <path
        d="M51.5625 37.5H7.8125C6.94883 37.5 6.25 36.8004 6.25 35.9375C6.25 35.0746 6.94883 34.375 7.8125 34.375H51.5625C52.4262 34.375 53.125 35.0746 53.125 35.9375C53.125 36.8004 52.4262 37.5 51.5625 37.5Z"
        fill="white"
      />
      <path d="M59.375 21.875H0V29.6875H59.375V21.875Z" fill="white" />
      <path
        d="M93.75 84.375H46.875C43.4232 84.375 40.625 81.5768 40.625 78.125V48.4375C40.625 44.9857 43.4232 42.1875 46.875 42.1875H93.75C97.2018 42.1875 100 44.9857 100 48.4375V78.125C100 81.5768 97.2018 84.375 93.75 84.375Z"
        fill="#EB2675"
      />
      <path
        d="M82.8129 58.8213C81.6676 60.1189 79.9918 60.9375 78.125 60.9375C74.6732 60.9375 71.875 58.1393 71.875 54.6875C71.875 51.2357 74.6732 48.4375 78.125 48.4375C79.9924 48.4375 81.6686 49.2564 82.8139 50.5551"
        fill="#F8BFAE"
      />
      <path
        d="M59.375 60.9375H50C48.274 60.9375 46.875 59.5385 46.875 57.8125V51.5625C46.875 49.8365 48.274 48.4375 50 48.4375H59.375C61.101 48.4375 62.5 49.8365 62.5 51.5625V57.8125C62.5 59.5385 61.101 60.9375 59.375 60.9375Z"
        fill="#FFD100"
      />
      <path
        d="M54.6875 71.875H48.4375C47.5738 71.875 46.875 71.1754 46.875 70.3125C46.875 69.4496 47.5738 68.75 48.4375 68.75H54.6875C55.5512 68.75 56.25 69.4496 56.25 70.3125C56.25 71.1754 55.5512 71.875 54.6875 71.875Z"
        fill="white"
      />
      <path
        d="M67.1875 78.125H48.4375C47.5738 78.125 46.875 77.4254 46.875 76.5625C46.875 75.6996 47.5738 75 48.4375 75H67.1875C68.0512 75 68.75 75.6996 68.75 76.5625C68.75 77.4254 68.0512 78.125 67.1875 78.125Z"
        fill="white"
      />
      <path
        d="M67.1875 71.875H60.9375C60.0738 71.875 59.375 71.1754 59.375 70.3125C59.375 69.4496 60.0738 68.75 60.9375 68.75H67.1875C68.0512 68.75 68.75 69.4496 68.75 70.3125C68.75 71.1754 68.0512 71.875 67.1875 71.875Z"
        fill="white"
      />
      <path
        d="M79.6875 71.875H73.4375C72.5738 71.875 71.875 71.1754 71.875 70.3125C71.875 69.4496 72.5738 68.75 73.4375 68.75H79.6875C80.5512 68.75 81.25 69.4496 81.25 70.3125C81.25 71.1754 80.5512 71.875 79.6875 71.875Z"
        fill="white"
      />
      <path
        d="M79.6875 78.125H73.4375C72.5738 78.125 71.875 77.4254 71.875 76.5625C71.875 75.6996 72.5738 75 73.4375 75H79.6875C80.5512 75 81.25 75.6996 81.25 76.5625C81.25 77.4254 80.5512 78.125 79.6875 78.125Z"
        fill="white"
      />
      <path
        d="M92.1875 71.875H85.9375C85.0738 71.875 84.375 71.1754 84.375 70.3125C84.375 69.4496 85.0738 68.75 85.9375 68.75H92.1875C93.0512 68.75 93.75 69.4496 93.75 70.3125C93.75 71.1754 93.0512 71.875 92.1875 71.875Z"
        fill="white"
      />
      <path
        d="M87.5 60.9375C90.9518 60.9375 93.75 58.1393 93.75 54.6875C93.75 51.2357 90.9518 48.4375 87.5 48.4375C84.0482 48.4375 81.25 51.2357 81.25 54.6875C81.25 58.1393 84.0482 60.9375 87.5 60.9375Z"
        fill="#FFD100"
      />
      <path
        d="M54.6875 53.125V48.4375H51.5625V60.9375H54.6875V56.25H62.5V53.125H54.6875Z"
        fill="#FF9500"
      />
    </svg>
  );
}

export default SaveCardsIcon;
