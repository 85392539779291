import { create } from "zustand";
import { persist } from "zustand/middleware";

export const userDetailsStore = create(
  persist(
    (set) => ({
      userDetails: {
        first_name: "",
        last_name: "",
        user_phone: "",
        userId: "",
      },
      setUserDetails: (payload) => set((state) => ({ userDetails: payload })),
    }),
    {
      name: "user-storage", // unique name for the storage key
      getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
    }
  )
);
