import React, { Fragment, useEffect, useState } from "react";
import classes from "../NavbarMenu.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { Button, Container, Dropdown, Form } from "react-bootstrap";
import { FiHeart } from "react-icons/fi";
import { GrCart } from "react-icons/gr";
import { BsPeople } from "react-icons/bs";
import {
  CartPath,
  LoginPath,
  profileNavbarData,
  ProfilePath,
  WishListPath,
} from "../../../constant/constant";
import axios from "axios";
import { getUserId } from "../../../utils/getUserId";
import { useCountStore, useCurrencyCode } from "../../../store/CountStore";
import { useMobileScreen } from "../../../utils/isMobileScreen";
import { userDetailsStore } from "../../../store/GlobalUserData";
import { addToCartCountStore } from "../../../store/CartCountStore";
import IndianFlag from "../../../Svgs/IndianFlag";
import USAFlag from "../../../Svgs/USAFlag";
import CommonConsentPopup from "../../CommonComponents/CommonConsentPopup";

function RightSideMenu({
  setIsSearchModalOpen,
  searchInputHandler,
  searchHandler,
  searchProduct,
}) {
  const navigate = useNavigate();
  const customerId = getUserId();
  const [cartCurrency, setCartCurrency] = useState('')
  const [openConsent, setOpenConsent] = useState(false)
  const { counts, updateCount, updateWishListIds } = useCountStore();
  const { defaultCurrency, updateCurrency } = useCurrencyCode();
  let tabScreen = useMobileScreen(1091);
  const [cartId, setCartId] = useState('')
  const { userDetails, setUserDetails } = userDetailsStore();
  const { addToCartCount, setAddToCartCount, setItemIdsinCart } =
    addToCartCountStore();
  const location = useLocation();
  const pathname = location.pathname;
  const [profileMenu,setProfileMenu]=useState(true)

  const fetchWishlistProductData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PATH}wishlist?id=${customerId}`)
      .then((res) => {
        let ids = res.data?.map((ele) => ele.items[0].product_id);
        updateWishListIds(ids);
        updateCount(ids?.length);
      })
      .catch((err) => console.log(err));
  };

  const getCartDetails = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}cartDetails?cartId=${userDetails?.notes}`
      )
      .then((res) => {
        setAddToCartCount(res?.data?.data?.line_items?.physical_items?.length);        
        setCartCurrency(res?.data?.data?.currency.code)
        updateCurrency(res?.data?.data?.currency.code)
        setCartId(res?.data?.data?.id)
        setItemIdsinCart(
          res?.data?.data?.line_items?.physical_items.map((v) => v.variant_id)
        );
      });
  };
  useEffect(() => {
    !isNaN(customerId) && fetchWishlistProductData();
    !isNaN(customerId) && userDetails?.notes !== "" && getCartDetails();
  }, [userDetails]);

  
  const handleLogout = () => {
    document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setUserDetails({
      user_fname: "",
      user_lname: "",
      user_phone: "",
      userId: "",
    });
    updateCount(0);
    setAddToCartCount(0);
    updateWishListIds([]);
    setItemIdsinCart([]);

    localStorage.clear();
    navigate(LoginPath, {
      state: { from: location.pathname + location.search },
    });
    localStorage.setItem(
      "previous",
      JSON.stringify({ state: { from: location.pathname + location.search } })
    );
};
  
const updateUser = () => {
  axios
  .post(`${process.env.REACT_APP_API_PATH}updateCustomer`, {id: customerId, notes: '' })
  .then((response) => {
   setUserDetails(response?.data[0])
   setAddToCartCount(0)
  })
  .catch((error) => {
    console.log(error);
  });
}

 const handleDeleteCart = () => {
  axios
  .post(`${process.env.REACT_APP_API_PATH}deleteCart`, { cartId : cartId })
  .then((response) => {
    updateCurrency(cartCurrency)
    updateUser()
  })
  .catch((error) => {
    console.log(error);
  });
 }

 useEffect(() => {
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      if (defaultCurrency?.length === 0) {
        if(cartCurrency !== undefined || cartCurrency !== ""){
          updateCurrency(response.data.country == "IN" ? "INR" : "USD");
        }
        else{
          updateCount(cartCurrency)
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });
}, [cartCurrency]);
  
  return (
    <div className={classes.navForm}>
      <div className={classes.headerRight}>
        <div className={classes.searchInputWrapper}>
          <span
            className={`${classes.iconBtn} ${classes.searchIconBtn}`}
            onClick={() => tabScreen && setIsSearchModalOpen(true)}
          >
            <CiSearch />
          </span>
          <form>
          <input
            type="text"
            value={searchProduct}
            name="headerSearch"
            placeholder="Search..."
            onChange={
              window.location.pathname !== "/products"
                ? searchHandler
                : searchInputHandler
            }
          />
          </form>
         
        </div>
        <div className="d-flex">
          {/* <div className={`${classes.mega_menu} ${classes.dropDown_menu}`}>
             <Dropdown.Toggle
              variant="light"
              size='sm'
              id="dropdown-basic"
              className="custom-select-label-currency"
            >
              {defaultCurrency === "INR" ? (
                  <Fragment><IndianFlag /><p className={classes.currency_text} style={{ marginLeft: '5px', fontSize: '15px' }}>INR</p></Fragment>
              ) : (
                <Fragment><USAFlag /><p className={classes.currency_text} style={{ marginLeft: '5px', fontSize: '15px' }}>USD</p></Fragment>
              )}
            </Dropdown.Toggle>
            <div
              style={{ padding: "10px", width: "200px" }}
              className={classes.mega_wrap}
            >
              <Container key={`inline`}>
                <b>
                  <h6>Select currency</h6>
                </b>
                <hr className="m-1" />
                {profileNavbarData?.partFour.map((item, index) => (
                  <div key={index} style={{ display: "flex", alignItems:"center", cursor:"pointer" }} 
                  onClick={()=>{
                    if(defaultCurrency.length !== 0  && addToCartCount !== 0){
                      setOpenConsent(true)
                      setCartCurrency(item.value)
                     }else{
                      updateCurrency(item.value)
                     }
                    }
                  }>
                    <input
                      style={{ width: "10%", marginRight: "5px", marginTop: "0px" }}
                      value={defaultCurrency}
                      name="Select currency"
                      type="radio"
                      className={classes.radio_input}
                      checked={item.value == defaultCurrency}
                      id={index}
                    />
                    <p>{item.title}</p>
                  </div>
                ))}
              </Container>
            </div>
          </div> */}
          <Link to={isNaN(customerId) ? LoginPath : WishListPath}>
          <Button
            className={`${classes.iconBtn} ${
              pathname === WishListPath ? classes.activeIcon : ""
            }`}
            onClick={() => {
              isNaN(customerId) &&
                navigate(LoginPath, {
                  state: { from: location.pathname + location.search },
                });
              localStorage.setItem(
                "previous",
                JSON.stringify({
                  state: { from: location.pathname + location.search },
                })
              );
            }}
          >
              <FiHeart />
            {counts > 0 && <span>{counts}</span>}
          </Button>
          </Link>  
          <Link to={CartPath}>
          <Button
            className={`${classes.iconBtn} ${
              pathname === CartPath ? classes.activeIcon : ""
            }`}
          >
              <GrCart />
            {addToCartCount > 0 && <span>{addToCartCount}</span>}
          </Button>
          </Link>
          <div className={classes.mega_menu}>
            <button
              className={`${classes.userButton} ${
                pathname === ProfilePath ? classes.profileActiveIcon : ""
              }`}
              onClick={()=>setProfileMenu(true)}
              onMouseEnter={()=>setProfileMenu(true)}
            >
              {/* <Link to={ProfilePath}> */}
                <BsPeople />
              {/* </Link> */}
            </button>
            <div className={`${classes.mega_wrap}  ${!profileMenu && classes.hideMenu} `}
            onClick={()=>setProfileMenu(false)} >
              <div className={classes.row}>
                <div className={classes.welcome_section}>
                  <p>
                    Welcome {!isNaN(customerId) && `${userDetails?.first_name} ${userDetails?.last_name}`}
                  </p>
                  {isNaN(customerId) && (
                    <Fragment>
                      <p>To access account and manage orders</p>
                      <p
                        className={classes.loginButton}
                        onClick={() => handleLogout()}
                      >
                        <Link
                          to={LoginPath}
                          state={{ from: pathname + location.search }}
                        >
                          login / Register
                        </Link>
                      </p>
                    </Fragment>
                  )}
                </div>
                <ul className={classes.profilelisting}>
                  {profileNavbarData?.partOne.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={
                          item?.activeTab
                            ? `${item.redirectURL}?activeTab=${item.activeTab}`
                            : item.redirectURL
                        }
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <ul className={classes.profilelisting}>
                  {profileNavbarData?.partTwo.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={`${item.redirectURL}?activeTab=${item.activeTab}`}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                {!isNaN(customerId) && (
                  <ul className={classes.profilelisting}>
                    {profileNavbarData?.partThree.map((item, index) => (
                      <li key={index}>
                        {item.activeTab ? (
                          <Link
                            to={`${item.redirectURL}?activeTab=${item.activeTab}`}
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <p onClick={() => handleLogout()}>{item.title}</p>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonConsentPopup open={openConsent} modalType="Change Currency" handleClose={()=>setOpenConsent(false)} handleSubmit={() => {
        handleDeleteCart()
      }}>Warning: Switching the currency will reset your cart. Do you wish to proceed?</CommonConsentPopup>
    </div>
  );
}

export default RightSideMenu;
