import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import classes from "../../AddressesTab/AddAddressModal/AddAddressModal.module.css";
import newClasses from "./ReviewModal.module.css";
import { userDetailsStore } from "../../../../store/GlobalUserData";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "../../../../utils/toastUtils";

function ReviewModal({ handleClose, rating, productDetails, setReRender }) {
  console.log(handleClose, rating, productDetails, setReRender)
  const { userDetails } = userDetailsStore();
  const [text, setText] = useState(productDetails.reviewDetails[0]?.text ?? "");

  const handleReviewSubmit = async () => {
    const payload = {
      title: "good Product",
      text: text,
      rating: rating,
      email: userDetails.email,
      name: userDetails.first_name + " " + userDetails.last_name,
      product_id:productDetails.details.id,
      review_id:productDetails.reviewDetails[0]?.id
    };
    if (productDetails.reviewDetails[0]?.text) {
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}updateProductReview`,
          payload
        )
        .then((res) => {
          showSuccessToast("Review Added Successfully!");
          setReRender(prev => prev + 1)
          handleClose();
        })
        .catch((err) => {
          if (!err.response) {
            showErrorToast("Something went wrong!");
          } else {
            showErrorToast(err.response.data.message);
          }
          console.log(err);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}productReviews?product_id=${productDetails.details.id}`,
          payload
        )
        .then(() => {
          showSuccessToast("Review Added Successfully!");
          setReRender(prev=> prev + 1)
          handleClose();
        })
        .catch((err) => {
          if (!err.response) {
            showErrorToast("Something went wrong!");
          } else {
            showErrorToast(err.response.data.message);
          }
          console.log(err);
        });
    }
  };
  return (
    <Modal
      show={true}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div className={classes.modalContent}>
        <div className={classes.modalHeaderWrapper}>
          <h5 className={classes.modalHeader}>Write Review</h5>
          <button
            className={classes.closeBtn}
            onClick={handleClose}
            tabIndex={0}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className={classes.addressFormUI}>
          <div className={newClasses.addressFormUIDiv}>
            <div className={newClasses.ImageAndDetails}>
              <img
                src={
                  productDetails.details.images.find(
                    (v) => v.is_thumbnail === true
                  ).url_thumbnail
                }
              />
              <div>
                <p className={newClasses.productTitle}>{productDetails.name}</p>
                <p className={newClasses.productSize}>
                  {
                    productDetails.product_options.find(
                      (v) => v.display_name === "Size"
                    ).display_value
                  }
                </p>
              </div>
            </div>
            <div className={newClasses.reviewTextarea}>
              <textarea
                placeholder="Please write product review here."
                name="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
              ></textarea>
            </div>
            <div className={classes.contentWrapper}>
              <p>
                <b>Share a video or photo</b>
              </p>
              <input
                type="file"
                id="myFile"
                name="filename"
                onChange={(e) => console.log(e.target.files[0])}
              />
            </div>
            <p className={newClasses.reviewText}>
              By submitting your review, you agree to let us publish and use
              your personal information according to our{" "}
              <span className={newClasses.reviewTerm}>Terms</span> and{" "}
              <span className={newClasses.reviewTerm}>Privacy Policy.</span>
            </p>
          </div>
        </div>
        <div className={classes.modalFooter}>
          <button className={classes.primaryBtn} onClick={handleReviewSubmit}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ReviewModal;
