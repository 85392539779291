import React from 'react'
import classes from './BlogsHeader.module.css'

function BlogsHeader() {
  return (
    <>
        <div className={classes.blogsHeader}>
            <div className={classes.HeaderContent}>
                <button>Blog</button>
                <h1>Welcome to Our Blog</h1>
                <p>Welcome to the official blog of Upvan Fashion. Here, we share insightful articles, tips, and updates on topics related to Upvan Fashion.</p>
            </div>
        </div>
    </>
  )
}

export default BlogsHeader