import React from "react";

function NextBtn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.76751 11.8159C5.53783 11.577 5.54528 11.1972 5.78414 10.9675L8.93434 8L5.78414 5.0325C5.54528 4.80282 5.53783 4.423 5.76751 4.18413C5.99718 3.94527 6.37701 3.93782 6.61587 4.1675L10.2159 7.5675C10.3335 7.68062 10.4 7.83679 10.4 8C10.4 8.16321 10.3335 8.31938 10.2159 8.4325L6.61587 11.8325C6.37701 12.0622 5.99718 12.0547 5.76751 11.8159Z"
        fill="#626262"
      />
    </svg>
  );
}

export default NextBtn;
