import React, { useEffect, useState } from "react";
import classes from "./ProfileDetailsTab.module.css";
import ProfileIcon from "../../../Svgs/ProfileIcon";
import EditProfileModal from "./EditProfileModal/EditProfileModal";
import axios from "axios";
import { getUserId } from "../../../utils/getUserId";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../errorMessage/ErrorMessage";
import { Field, Form, Formik } from "formik";
import { profileValidationSchema } from "../../CommonComponents/ValidationSchema";
import Loader from "../../Loader/Loader";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";

function ProfileDetailsTab() {
  const [isEditModal, setIsEditModal] = useState(false);
  const [userData, setUserData] = useState({});
  const userId = getUserId();
  const [reRender, setReRender] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editType, setEditType] = useState("");
  const fetchCustomerData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_PATH}customer?id=${userId}`)
      .then((res) => {
        setLoading(false);
        setUserData(res?.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    !isNaN(userId) && fetchCustomerData();
  }, [reRender]);

  return (
    <div className={classes.tabcontent}>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.profileDetailsSection}>
          <div className={classes.profileImageWrapper}>
            <ProfileIcon />
            <p>
              {userData?.first_name} {userData?.last_name}
            </p>
          </div>
          <div className={classes.profileOtherDetails}>
            <div className={classes.modalContent}>
              <Formik
                initialValues={userData}
                enableReinitialize
                validationSchema={profileValidationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await axios
                    .post(
                      `${process.env.REACT_APP_API_PATH}updatecustomer`,
                      values
                    )
                    .then((res) => {
                      console.log({ res });
                      // showSuccessToast("User Details Updated!");
                      setReRender((prev) => prev + 1);
                    })
                    .catch((err) => {
                      if (!err.response) {
                        showErrorToast("Something went wrong!");
                      } else {
                        showErrorToast(err.response.data.message);
                      }
                      console.log({ err });
                    });
                  setSubmitting(false);
                }}
              >
                {({ setFieldValue, errors, values, touched }) => {
                  return (
                    <Form>
                      <div>
                        <h5 className={classes.modalHeader}>Edit Details</h5>
                      </div>
                      <div className={classes.addressFormUI}>
                        <div className={classes.addressList}>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>
                              First Name*
                            </label>
                            <Field
                              type="text"
                              placeholder="First Name"
                              name="first_name"
                            />
                            {errors.first_name && touched.first_name && (
                              <ErrorMessage message={errors.first_name} />
                            )}
                          </div>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>
                              Last Name
                            </label>
                            <Field
                              type="text"
                              placeholder="Last Name"
                              name="last_name"
                            />
                            {errors.last_name && touched.last_name && (
                              <ErrorMessage message={errors.last_name} />
                            )}
                          </div>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>
                              Mobile Number
                            </label>
                            <PhoneInput
                              name="phone"
                              value={values?.phone}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              disabled
                              country={"in"}
                              onChange={(phone, data) =>
                                setFieldValue("phone", phone)
                              }
                            />
                            <ErrorMessage message={errors.phone} />
                          </div>{" "}
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>Email</label>
                            <Field
                              disabled
                              type="email"
                              placeholder="test@gmail.com"
                              name="email"
                            />
                            <ErrorMessage message={errors.email} />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className={classes.modalFooter}
                      >
                        <div style={{display:'contents'}}>
                          <button
                            type="button"
                            className={classes.secondaryBtn}
                            onClick={() => {
                              setIsEditModal(true);
                              setEditType("mobile");
                            }}
                          >
                            Change Phone Number
                          </button>
                          <button
                            type="button"
                            className={classes.secondaryBtn}
                            onClick={() => {
                              setIsEditModal(true);
                              setEditType("email");
                            }}
                          >
                            Change Email Address
                          </button>
                        </div>
                        <button type="submit" className={classes.primaryBtn}>
                          Save Details
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          {isEditModal && (
            <EditProfileModal
              editType={editType}
              setReRender={setReRender}
              setIsEditModal={setIsEditModal}
              userData={userData}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileDetailsTab;
