import React from "react";

function PayLaterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_36_139"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_36_139)">
        <path
          d="M11.4 39.3C7.8 38.2333 5 36.2583 3 33.375C1 30.4917 0 27.3667 0 24C0 20.6333 1 17.5083 3 14.625C5 11.7417 7.8 9.76667 11.4 8.7V11.8C8.93333 12.6667 6.91667 14.2167 5.35 16.45C3.78333 18.6833 3 21.2 3 24C3 26.8 3.78333 29.3083 5.35 31.525C6.91667 33.7417 8.93333 35.3 11.4 36.2V39.3ZM28 40C23.6333 40 19.875 38.425 16.725 35.275C13.575 32.125 12 28.3667 12 24C12 19.6333 13.575 15.875 16.725 12.725C19.875 9.575 23.6333 8 28 8C29.7667 8 31.5583 8.31667 33.375 8.95C35.1917 9.58333 36.8167 10.5667 38.25 11.9L36.25 13.95C35.0833 12.9833 33.7667 12.25 32.3 11.75C30.8333 11.25 29.4 11 28 11C24.4333 11 21.375 12.275 18.825 14.825C16.275 17.375 15 20.4333 15 24C15 27.5667 16.275 30.625 18.825 33.175C21.375 35.725 24.4333 37 28 37C29.4 37 30.8333 36.7583 32.3 36.275C33.7667 35.7917 35.0833 35.0667 36.25 34.1L38.25 36.1C36.8167 37.4333 35.1917 38.4167 33.375 39.05C31.5583 39.6833 29.7667 40 28 40ZM40.35 31.55L38.2 29.4L42.35 25.25H26.15V22.55H42.35L38.2 18.4L40.35 16.25L48 23.9L40.35 31.55Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default PayLaterIcon;
