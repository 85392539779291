import React from "react";

function EMIIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_36_145"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_36_145)">
        <path
          d="M6 40V28.75H11.1V40H6ZM16.35 27.6V18.35H21.4V27.6H16.35ZM26.65 17.2V8H31.7V17.2H26.65ZM36.95 40V8H42V40H36.95Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default EMIIcon;
