import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { BsEnvelope } from "react-icons/bs";
import classes from "../footer/Footer.module.css";
import { footerData, footerIcon } from "../../constant/constant";
import { Link, useNavigate } from "react-router-dom";
import { scrollToTop } from "../CommonComponents/CommonHelperFunctions";

function Footer() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const handleRedirect = (url) => {
    navigate(url);
    scrollToTop();
  };
  return (
    <footer>
      <Container style={{ padding: "40px 15px" }}>
        <Row className="px-3">
          {footerData?.map((item, index) => (
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12" key={index}>
              <p className={classes.dataTitle}>{item.title}</p>
              <ul className={classes.dataUl}>
                {item.listData.map((ele, i) => (
                  <li
                    style={{cursor: 'pointer'}}
                    key={i}
                    className={classes.listTitle}
                    onClick={() =>
                      ele.listURL !== "" && handleRedirect(ele.listURL)
                    }
                  >
                    <Link to={ele.listURL}>{ele.listTitle}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          {/* <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
            <p className={classes.dataTitle}>Email signup</p>
            <p className={`${classes.offertext} primary-color`}>
              <strong>
                Get 10% OFF when you signup for our email newsletter!
              </strong>
            </p>
            <div className={classes.emailWrapper}>
              <input
                className={classes.emailInput}
                type="email"
                value={email}
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button className={classes.emailBtn}>
                <BsEnvelope />
              </button>
            </div>
            <p className={classes.bottomText}>
              By entering your email address, you agree to receive offers,
              promotions, and other commercial messages RAB Fashion.
            </p>
          </div> */}
        </Row>
      </Container>
      <section className="bg-dark">
        <div className={classes.footer}>
          <div className={classes.copyrightText}>
            Copyright © 2017{" "}
            <span className="primary-color">UPVAN Shopping.</span> All rights
            reserved.
          </div>
          <div className={classes.iconWrapper}>
            {footerIcon?.map((item, index) => (
              <button key={index}>{item.icon}</button>
            ))}
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
