import React from "react";
import classes from "./loginSignupWrapper.module.css"
import { Container, Row } from "react-bootstrap";

function LoginSignupWrapper({children,title}) {
  return (
    <div className={classes.section}>
      <Container style={{margin:"50px 0px"}}>
        <Row className={classes.loginBox}>
          <div className={classes.formContainer}>
            {title !== "" && <h4>{title}</h4>}
            <div className={classes.formWrapper}>
                {children}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default LoginSignupWrapper;
