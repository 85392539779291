import React, { Fragment, memo, useEffect, useState } from "react";
import classes from "./OrderTab.module.css";
import closeBtn from "../ProfileDetailsTab/ProfileDetailsTab.module.css";
import axios from "axios";
import { FaBackspace, FaStar } from "react-icons/fa";
import clsx from "clsx";
import { Accordion, Card, Button } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import ReviewModal from "./ReviewModal/ReviewModal";
import OtherItemsAccordion from "./OtherItemsAccodion";
import { getBigComToken, getUserId } from "../../../utils/getUserId";
import { userDetailsStore } from "../../../store/GlobalUserData";
import { useCurrencyCode } from "../../../store/CountStore";

const OrderDetails = ({
  orderId,
  setOrderId,
  closeOrderDetails,
  orderIndex,
}) => {
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(0);
  const [rating, setRating] = useState(0);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const customer_id = getUserId();
  const { defaultCurrency } = useCurrencyCode()
  const [reRender, setReRender]=useState(0)
  const { userDetails } = userDetailsStore();
  const [otherItems, setOtherItems] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const BigComToken = getBigComToken()
  const [orderDetails, setOrderDetails] = useState({
    products: [],
    shipping_address: [],
  });

  const getOrderById = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}getOrderById?email=${userDetails.email}`,
        {
          order_id: orderId,
          customer_id: customer_id,
          currencyCode: defaultCurrency,

        },
        {
          headers:{
            Authorization: 'Bearer' + BigComToken
          }
        }
      )
      .then((response) => {
        setOrderDetails(response.data);
        setProductDetails(response.data.products);
        setOtherItems(
          response.data.products.filter(
            (item) =>
              ![response.data.products[0]].map((v) => v.id).includes(item.id)
          )
        );
        setLoading(false);
      })
      .catch((err) => {
        console.error("error", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getOrderById();
  }, [reRender]);

  useEffect(() => {
    productDetails[0]?.reviewDetails[0]?.rating &&
      setRating(productDetails[0]?.reviewDetails[0]?.rating ?? 0);
  }, [productDetails]);

  const handleReviewModalClose = () => {
    setIsReviewModalOpen(false);
  };

  console.log(productDetails)

  return (
    <Fragment>
      {isReviewModalOpen && (
        <ReviewModal
          handleClose={handleReviewModalClose}
          rating={rating}
          setReRender={setReRender}
          productDetails={productDetails[0]}
        />
      )}
      <div className={classes.orderList}>
        {loading ? (
          <Loader />
        ) : productDetails.length === 0 ? (
          <div>No Items Found!</div>
        ) : (
          <div className={`${classes.list} ${classes.orderDetails}`}>
            <div className={classes.orderIdAndDeliverStatus}>
              <p style={{ alignSelf: "center" }} className={classes.orderId}>
                <b>Order ID</b> #{orderDetails.id}
              </p>
              <div
                onClick={() => {
                  setOrderId("");
                  closeOrderDetails(false);
                }}
                className={classes.backButton}
              >
                <FaBackspace
                  style={{
                    color: "#eb2675",
                    fontSize: "2rem",
                    marginRight: "5px",
                  }}
                />
                <span>Back to Orders list</span>
              </div>
            </div>
            <div className={classes.itemImageAndDetails}>
              <img
                src={
                  productDetails[0].details.images.find(
                    (v) => v.is_thumbnail === true
                  ).url_thumbnail
                }
              />
              <div className={classes.orderDetailsListContent}>
                <div className={classes.details}>
                  <p className={classes.productTitle}>
                    {productDetails[0].name}
                  </p>
                  <p className={classes.productSize}>
                    {
                      productDetails[0].product_options.find(
                        (v) => v.display_name === "Size"
                      ).display_value
                    }
                  </p>
                  <p className={classes.productSize}>
                    QTY: <span>{productDetails[0].quantity}</span>
                  </p>
                </div>
                <div className={clsx(classes.details, classes.addressContent)}>
                  <p className={classes.heading}>
                    <b>Delivery Address</b>
                  </p>
                  <div className={classes.nameAndPhonenumber}>
                    <p className={classes.name}>
                      <b>
                        {orderDetails.shipping_address[0].first_name}{" "}
                        {orderDetails.shipping_address[0].last_name}
                      </b>
                    </p>
                    <p className={classes.middlePipe}></p>
                    <p className={classes.phoneNumber}>
                      <b>{orderDetails.shipping_address[0].phone}</b>
                    </p>
                  </div>
                  <p className={classes.addressText}>
                    {`${orderDetails.shipping_address[0].street_1},
                      ${orderDetails.shipping_address[0].city},
                      ${orderDetails.shipping_address[0].state},
                      ${orderDetails.shipping_address[0].zip}`}
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`${classes.ratingAndReviwOrderDetails} ${classes.orderIdAndDeliverStatus}`}
            >
              <div className={classes.rateProduct}>
                <p className={classes.heading}>Rate this product</p>
                <div className={classes.ratingStars}>
                  {[...Array(5)].map((v, i) => {
                    const starValue = i + 1;
                    return (
                      <FaStar
                        key={i}
                        onMouseEnter={() => setHovered(starValue)}
                        onMouseLeave={() => setHovered(0)}
                        onClick={() => setRating(starValue)}
                        color={
                          starValue <= (hovered || rating)
                            ? "#eb2675"
                            : "#e4e5e9"
                        }
                        style={{ cursor: "pointer" }}
                      />
                    );
                  })}
                </div>
              </div>
              <div className={classes.writeReview}>
                <p onClick={() => setIsReviewModalOpen(true)}>
                  {productDetails[0]?.reviewDetails?.length
                    ? "Update a Review"
                    : "Write a Review"}
                </p>
              </div>
            </div>
            <div
              className={`${classes.trackingSection} ${classes.orderIdAndDeliverStatus}`}
            >
              <div className={classes.deliveryDates}>
                <p>Nov 20, 2024</p>
                <p>Nov 21, 2024</p>
                <p>Nov 22, 2024</p>
                <p>Nov 24, 2024</p>
              </div>
              <div className={classes.deliveryLine}></div>
              <div className={classes.deliveryTrack}>
                <p>Order Confirmed</p>
                <p>Shipped</p>
                <p>Out for delivery</p>
                <p>Delivered</p>
              </div>
            </div>
            <div className={classes.orderIdAndDeliverStatus}>
              <div className={classes.totalPriceSection}>
                <p className={classes.heading}>
                  Total Item Price
                  <br />
                  {productDetails[0].applied_discounts.length === 0
                        ? <span className={classes.savedPrice}>No Discounts applied!</span>
                        : <span className={classes.savedPrice}>
                        You saved{" "}
                        <span className={classes.greenColor}>
                        {orderDetails.currency_code==='INR'?`₹`:`$`}
                          {parseFloat(
                                productDetails[0].applied_discounts[0].amount
                              ).toFixed(2)}
                        </span>{" "}
                        on this order
                      </span>}
                </p>
                <div className={classes.priceAndPayementMethod}>
                  <p className={classes.priceText}>
                    {orderDetails.currency_code==='INR'?`₹`:`$`} {parseFloat(productDetails[0].total_inc_tax).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.otherItemsInThisOrder}>
              {otherItems.length !== 0 && (
                <Fragment>
                  <div className={classes.headingAndOrderId}>
                    <p className={classes.heading}>Other items in this order</p>
                    <p className={classes.orderId}>Order {orderDetails.id}</p>
                  </div>
                  <Accordion>
                    {otherItems.map((v, i) => (
                      <OtherItemsAccordion
                        key={i}
                        i={i}
                        setReRender={setReRender}
                        orderIndex={orderIndex}
                        productDetails={productDetails}
                        orderDetails={orderDetails}
                        setHovered={setHovered}
                        v={v}
                        hovered={hovered}
                      />
                    ))}
                  </Accordion>
                </Fragment>
              )}
              <div className={classes.totalPriceSection}>
                <p className={classes.heading}>Total Ordered Price</p>
                <div className={classes.priceAndPayementMethod}>
                  <p className={classes.priceText}>
                  {orderDetails.currency_code==='INR'?`₹`:`$`} {parseFloat(orderDetails.total_inc_tax).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default memo(OrderDetails);
