// ZoomableImage.js
import React, { useState } from 'react';
import './ZoomableImage.css'; 

const ZoomableImage = ({ original }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [bgPosition, setBgPosition] = useState({ x: '50%', y: '50%' });

  const zoom = (e) => {
    e.preventDefault(); 
    const zoomer = e.currentTarget;
    const rect = zoomer.getBoundingClientRect(); 

    // Calculate offsets for mouse and touch events
    const offsetX = e.touches ? e.touches[0].clientX - rect.left : e.clientX - rect.left;
    const offsetY = e.touches ? e.touches[0].clientY - rect.top : e.clientY - rect.top;

    // Calculate background position percentages
    const x = (offsetX / zoomer.offsetWidth) * 100;
    const y = (offsetY / zoomer.offsetHeight) * 100;

    // Set background position and zoom state
    setBgPosition({ x: `${x}%`, y: `${y}%` });
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false); // Reset zoom on mouse leave
  };

  return (
    <figure
      className="zoom"
      onMouseMove={zoom}
      onTouchMove={zoom}
      onMouseLeave={handleMouseLeave}
      style={{
        backgroundImage: isZoomed ? `url(${original})` : 'none',
        backgroundPosition: isZoomed ? `${bgPosition.x} ${bgPosition.y}` : 'center',
        backgroundSize: isZoomed ? '200% 200%' : 'cover', 
        margin: "0px"
      }}
    >
      <img src={original} alt="Zoomable" />
    </figure>
  );
};

export default ZoomableImage;