import React from 'react'
import NavbarMenu from '../components/navbar/NavbarMenu'
import Register from '../components/loginRegister/Register'

function UserRegister() {
  return (
    <>
        <NavbarMenu/>
        <Register/>
    </>
  )
}

export default UserRegister