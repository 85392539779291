import React, { useState } from "react";
import classes from "./EditProfileModal.module.css";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik, Form, Field } from "formik";
import ErrorMessage from "../../../errorMessage/ErrorMessage";
import SuccessMessage from "../../../successMessage/SuccessMessage";
import { profileValidationSchema } from "../../../CommonComponents/ValidationSchema";
import axios from "axios";
import { getUserId } from "../../../../utils/getUserId";
import _ from "lodash";
import { showSuccessToast, showErrorToast } from "../../../../utils/toastUtils";

function EditProfileModal({ setIsEditModal, userData, editType, setReRender }) {
  const userId = getUserId();
  const [isOTP, setIsOTP] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [enable, setEnable] = useState(true)
  const [responseMsg, setResponseMsg] = useState("");
  const [userDetails] = useState({
    ...userData,
    phone: userData?.phone ?? "",
    email: userData?.email ?? "",
    otp: "",
    id: userId ?? "",
  });

  const handleClose = () => {
    setIsEditModal(false);
  };

  const otpHandle = async (value) => {
    setLoading1(true);
    let isNewNumber = false
    if(userData?.phone == value.phone){
      showErrorToast("Change Phone Number")
      setLoading1(false);
    }
    else{
      let url = `${process.env.REACT_APP_API_PATH}isCustomerNumberExist?phone=${value.phone}&profilePage=true` 
      await axios
      .get(url)
      .then((res) => {
        console.log({res});
        if (res?.data?.isEmail && res?.data?.isMobile) {
          showErrorToast(res?.data?.message);
        } else if (res?.data?.isEmail) {
          showErrorToast(res?.data?.message);
        } else if (res?.data?.isMobile) {
          showErrorToast(res?.data?.message);
        }else {
          isNewNumber=true
        }
        setLoading1(false);
      }
    )
      .catch((err)=> {
        isNewNumber=false
        setLoading1(false);
        showErrorToast(err?.response?.data?.mobileMessage)
      })
    }
    if(isNewNumber){
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}sendSMS`,
          _.pickBy(
            { ...value, mobile_Number: value.phone },
            (value) => !_.isEmpty(value)
          )
        )
        .then((res) => {
          console.log({ res });
          setIsOTP(true);
          showSuccessToast(res.data.message);
          setLoading1(false);
        })
        .catch((err) => {
          console.log({ err });
          setLoading1(false);
          if (!err.response) {
            showErrorToast("Something went wrong!");
          } else {
            showErrorToast(err.response.data.message);
          }
        });
    }
  };
  const verifyOtpEmail = async (values) => {
    setResponseMsg("");
    setLoading2(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}verify_otp_email`,
        _.pickBy(
          { ...values, email: values.email },
          (value) => !_.isEmpty(value)
        )
      )
      .then((res) => {
        if (res.data.success === true) {
          setIsOTP(false);
          setResponseMsg(res.data.message);
          showSuccessToast(res.data.message);
          setLoading2(false);
          handleSubmit(values)
        } else {
          console.log("error!");
          setLoading2(false);
          setResponseMsg("");
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoading2(false);
        setResponseMsg("");
        if (!err.response) {
          showErrorToast("Something went wrong!");
        } else {
          showErrorToast(err.response.data.message);
        }
      });
  };
  const verifyOtp = async (values) => {
    setResponseMsg("");
    setLoading2(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}verify_otp`,
        _.pickBy(
          { ...values, mobile_Number: values.phone },
          (value) => !_.isEmpty(value)
        )
      )
      .then((res) => {
        if (res.data.success === true) {
          setIsOTP(false);
          setResponseMsg(res.data.message);
          showSuccessToast(res.data.message);
          setLoading2(false);
          handleSubmit(values)
        } else {
          console.log("error!");
          setLoading2(false);
          setResponseMsg("");
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoading2(false);
        setResponseMsg("");
        if (!err.response) {
          showErrorToast("Something went wrong!");
        } else {
          showErrorToast(err.response.data.message);
        }
      });
  };

  const emailOtpHandle = async (value) => {
    setLoading1(true);
    let isNewEmail = false
    if(userData?.email == value.email){
      showErrorToast("Change Email address")
      setLoading1(false);
    }
    else{
      let url = `${process.env.REACT_APP_API_PATH}isCustomerNumberExist?email=${value.email}&profilePage=true`
      await axios
      .get(url)
      .then((res) => {
        if (res?.data?.isEmail && res?.data?.isMobile) {
          showErrorToast(res?.data?.message);
        } else if (res?.data?.isEmail) {
          showErrorToast(res?.data?.message);
        } else if (res?.data?.isMobile) {
          showErrorToast(res?.data?.message);
        }else {
          isNewEmail = true
        }
        setLoading1(false)
        console.log({res}); 
      }
    )
      .catch((err)=> {
        isNewEmail=false
        setLoading1(false);
        console.log({err});
        showErrorToast(err?.response?.data?.message)
      })
    }
    if(isNewEmail){
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}sendEmailOtp`,
          _.pickBy(value, (value) => !_.isEmpty(value))
        )
        .then((res) => {
          console.log({ res });
          setLoading1(false);
          setIsOTP(true);
          showSuccessToast(res.data.message);
          // setResponseMsg(res.data);
        })
        .catch((err) => {
          console.log({ err });
          setLoading1(false);
          if (!err.response) {
            showErrorToast("Something went wrong!");
          } else {
            showErrorToast(err.response.data.message);
          }
          // setResponseMsg("");
        });
    }
  };

  const LoadingSpinner = () => (
    <svg width="24" height="24" viewBox="0 0 50 50" className={classes.spinner}>
      <circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
        stroke="#000"
        strokeLinecap="round"
        strokeDasharray="126" // 2 * Math.PI * 20 (full circumference)
        strokeDashoffset="0"
      >
        <animate
          attributeName="stroke-dashoffset"
          values="126;0"
          dur="1s"
          repeatCount="indefinite"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="1s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );

  const handleSubmit = (values) => {
    axios
   .post(`${process.env.REACT_APP_API_PATH}updatecustomer`, values)
   .then((res) => {
    //  showSuccessToast("User details updated successfully!");
     handleClose();
     setReRender((prev) => prev + 1);
   })
   .catch((err) => {
     if (!err.response) {
       showErrorToast("Something went wrong!");
     } else {
       showErrorToast(err.response.data.message);
     }
     console.log({ err });
   });
 }

  return (
    <Modal
      show={true}
      backdrop={false}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div className={classes.modalContent}>
        <Formik
          initialValues={userDetails}
          validationSchema={profileValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            editType === 'mobile' ?  verifyOtp(values) :  verifyOtpEmail(values)
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, errors, values, su }) => {
            return (
              <Form>
                <div style={{ position: "relative" }}>
                  <h5 className={classes.modalHeader}>
                    {editType === "mobile"
                      ? `Change Phone Number`
                      : "Change Email Adress"}
                  </h5>
                  <button
                    className={classes.closeBtn}
                    onClick={handleClose}
                    tabIndex={0}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className={classes.addressFormUI}>
                  {editType === "mobile" ? (
                    <div className={classes.addressList}>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>
                          Mobile Number
                        </label>
                        <div style={{position:"relative"}}>
                        <PhoneInput
                          name="phone"
                          value={values.phone}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          country={"in"}
                          onChange={(phone, data) =>
                            setFieldValue("phone", phone)
                          }
                        />

                        {loading1 ? (
                          <LoadingSpinner />
                        ) : (
                          <button
                            style={{backgroundColor: isOTP && 'grey'}}
                            onClick={() => otpHandle(values)}
                            className={classes.changeBtn}
                            type="button"
                            disabled={isOTP}
                          >
                            Get OTP
                          </button>
                        )}
                        </div>
                        <ErrorMessage message={errors.mobile_Number} />
                      </div>
                      {isOTP && (
                        <div className={classes.inputWrapper}>
                          <label className={classes.inputLabel}>
                            Enter OTP
                          </label>
                          <Field type="text" placeholder="123456" name="otp" />
                          <ErrorMessage message={errors.email} />
                        </div>
                      )}
                      <SuccessMessage message={responseMsg} />
                    </div>
                  ) : (
                    <div className={classes.addressList}>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>Email</label>
                        <div style={{position:"relative"}}>
                        <Field
                          type="email"
                          placeholder="test@gmail.com"
                          name="email"
                        />
                        {loading1 ? (
                          <LoadingSpinner />
                        ) : (
                          <button
                            disabled={isOTP}
                            style={{backgroundColor: isOTP && 'grey'}}
                            className={classes.changeBtn}
                            onClick={() => emailOtpHandle(values)}
                            type="button"
                          >
                            Get OTP
                          </button>
                        )}
                        </div>
                        <ErrorMessage message={errors.email} />
                      </div>
                      {isOTP && (
                        <div className={classes.inputWrapper}>
                          <label className={classes.inputLabel}>
                            Enter OTP
                          </label>
                          <Field type="text" placeholder="" name="otp" />
                          <ErrorMessage message={errors.email} />
                        </div>
                      )}
                      <SuccessMessage message={responseMsg} />
                    </div>
                  )}
                </div>
                <div className={classes.modalFooter}>
                  <button
                    className={classes.secondaryBtn}
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    style={{backgroundColor: !isOTP &&'grey'}}
                    className={classes.primaryBtn}
                    disabled={!isOTP}
                  >
                    Verify & Update
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditProfileModal;
