import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import VTlist from "./VTlist";
import VTcontent from "./VTcontent";
import Accordion from "react-bootstrap/Accordion";

import "./VerticalTab.css";
import ProductDescription from "./ProductDescription";
import AdditionalInformation from "./AdditionalInformation";
import Reviews from "./Reviews";
import { useMobileScreen } from "../../utils/isMobileScreen";
import { useParams } from "react-router-dom";
import axios from "axios";
import MainContext from "../../contexts/MainContext";

function VerticalTab(props) {
  const [activeTabId, setActiveTabId] = useState("Product description");
  let smallScreen = useMobileScreen();
  const {product_id} = useParams()
  const context = useContext(MainContext);
  function btnClick(id) {
    setActiveTabId(id);
  }
  const accordionData = [
    {
      title: "Product description",
      component: <ProductDescription />,
    },
    ...(context?.productAdditionalSpecification?.length > 0
      ? [{
          title: "Additional Information",
          component: <AdditionalInformation additionalInformation={context?.productAdditionalSpecification} />,
        }]
      : []),
    {
      title: "Reviews",
      component: <Reviews />,
    },
  ];
  // const getProductAdditionalInformation = async (product_id) => {
  //     await axios
  //       .get(
  //         `${process.env.REACT_APP_API_PATH}productAdditionalInformation?product_id=${product_id}`
  //       )
  //       .then((res) => {
  //         setAdditionalInformation(res.data);
  //       });
  // };

  // useEffect(() => {
  //     getProductAdditionalInformation(product_id);
  // }, []);

  return (
    <>
      {smallScreen ? (
        <div className="productDescriptionAccordion">
          <Accordion defaultActiveKey="0">
            {accordionData.map((item, index) => (
              <Accordion.Item eventKey={JSON.stringify(index)} key={index}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.component}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      ) : (
        <>
          <Row className=" border-top">
            <Col sm="3">
              <div className="Description-styledTab">
                <ul className="Description-styledTabList">
                  {accordionData.map((item, index) => (
                    <VTlist
                      key={index}
                      onClick={btnClick}
                      tab={item.title}
                      index={index}
                      activeTabId={activeTabId}
                    />
                  ))}
                </ul>
              </div>
            </Col>
            <Col sm="9">
                <VTcontent
                  activeTabId={activeTabId}
                  additionalInformation={context?.productAdditionalSpecification}
                />
            </Col>
          </Row>
          <span
            className={
              activeTabId === 0
                ? "index1-chosen"
                : activeTabId === 1
                ? "index2-chosen"
                : "index3-chosen"
            }
          >
            &nbsp;
          </span>
        </>
      )}
    </>
  );
}

export default VerticalTab;
