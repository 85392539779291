import React, { useEffect, useRef, useState } from "react";
import classes from "./OTPComponent.module.css";
import OTPicon from "../../Svgs/OTPicon";
import { Field } from "formik";
import { useNavigate } from "react-router-dom";

function OTPComponent({
  values,
  setFieldValue,
  formikRef,
  phone,
  setResendOTP,
}) {
  const [time, setTime] = useState("00:30");
  const [resend, setResend] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").split("");
    if (
      pastedData.length === 6 &&
      pastedData.every((char) => /^[0-9]$/.test(char))
    ) {
      setFieldValue(`otp`, pastedData);
      pastedData.forEach((char, i) => {
        if (inputRefs.current[i]) {
          inputRefs.current[i].value = char;
        }
      });
      if (inputRefs.current[5]) {
        inputRefs.current[5].focus();
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      decrementTime();
    }, 1000);

    return () => clearTimeout(timer);
  }, [time]);

  const decrementTime = () => {
    const [minutes, seconds] = time.split(":").map(Number);
    let newMinutes = minutes;
    let newSeconds = seconds - 1;

    if (newSeconds < 0) {
      newSeconds = 59;
      newMinutes -= 1;
    }

    if (newMinutes < 0) {
      newMinutes = 0;
      newSeconds = 0;
      setResend(true);
    }

    const formattedTime = `${String(newMinutes).padStart(2, "0")}:${String(
      newSeconds
    ).padStart(2, "0")}`;
    setTime(formattedTime);
  };

  useEffect(() => {
    if (values.otp.join("").length == 6) {
      formikRef.current?.handleSubmit();
    }
  }, [values.otp]);
  
  return (
    <div className={classes.OTPWrapper}>
      <OTPicon />
      <div className={classes.otpContent}>
        <h4 className={classes.contactTitle}>Verify with OTP</h4>
        <p className={classes.contactSubTitle}>Sent to +{phone}</p>
      </div>
      <div className={classes.otpField}>
        {values.otp?.map((digit, index) => (
          <Field
            key={index}
            name={`otp[${index}]`}
            type="text"
            maxLength="1"
            className="otp-input"
            style={{
              border: digit !== "" ? "1px solid #EB2675" : "1px solid #7A7A7A",
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (/^[0-9]$/.test(newValue) || newValue === "") {
                setFieldValue(`otp[${index}]`, newValue);
                if (newValue && index < 5) {
                  document
                    .querySelector(`input[name='otp[${index + 1}]']`)
                    .focus();
                } 
              }
            }}
            onPaste={handlePaste}
            onFocus={(e)=>{
              e.target.select();
            }}
            onKeyDown={(e) => {
              if (e.key === "ArrowLeft" && index > 0) {
                document.querySelector(`input[name='otp[${index - 1}]']`).focus();
              } else if (e.key === "ArrowRight" && index < 5) {
                document.querySelector(`input[name='otp[${index + 1}]']`).focus();
              } else if (e.key === "Backspace") {
                setFieldValue(`otp[${index}]`, "");
                if (index > 0) {
                  document.querySelector(`input[name='otp[${index - 1}]']`).focus();
                }
              }
            }}
          />
        ))}
      </div>
      {resend ? (
        <p
          className={classes.resend}
          onClick={() => {
            setTime("00:30");
            setResendOTP(true);
            formikRef.current?.handleSubmit();
            let i = 6 
            for(i=0;i<6;i++){
              setFieldValue(`otp[${i}]`, "");
            }
          }}
        >
          RESEND OTP
        </p>
      ) : (
        <p>
          <span className={classes.contactSubTitle}>Resend OTP in:</span>
          {time}
        </p>
      )}
    </div>
  );
}

export default OTPComponent;
