import React, { Fragment, useEffect, useRef, useState } from "react";
import classes from "./Login.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import {
  HomePath,
  PrivacyPolicyPath,
  ResetPasswordPath,
  SignupPath,
  TermsPath,
} from "../../constant/constant";
import LoginSignupWrapper from "../../components/loginSignupWrapper/loginSignupWrapper";
import { Field, Formik, Form } from "formik";
import { loginValidationSchema } from "../../components/CommonComponents/ValidationSchema";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import OTPComponent from "../../components/otpComponent/OTPComponent";
import Loader from "../../components/Loader/Loader";
import { userDetailsStore } from "../../store/GlobalUserData";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { Helmet } from "react-helmet";
import { useCurrencyCode } from "../../store/CountStore";
import { getLiveLocation } from "../../components/CommonComponents/CommonHelperFunctions";

function Login() {
  const [isMobileNumber, setIsmobileNumber] = useState(true);
  const [isOTP, setIsOTP] = useState(false);
  const { setUserDetails } = userDetailsStore();
  const [isPassword, setIsPassword] = useState(false);
  const { defaultCurrency, updateCurrency } = useCurrencyCode()
  const [isMobileNumberInput, setIsMobileNumberInput] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const formikRef = useRef();
  const navigate = useNavigate();
  let now = new Date();
  let time = now.getTime();
  let expireTime = time + 86400 * 1000 * 7;
  const location = useLocation();
  const redirectTo = location.state?.from ?? JSON.parse(localStorage.getItem('previous'))?.state?.from;

  const getLiveLocation = () => {
    axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      if (defaultCurrency.length === 0) {
        updateCurrency(response.data.country == "IN" ? "INR" : "USD")
      }
    })
    .catch((error) => {
      console.log(error);
    });
  } 

  const loginHandler = async (data) => {
    setLoading(true);
    let isRedirct = false;
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}isCustomerNumberExist?phone=${data.phone}&email=${data.email}&loginPage=true`
      )
      .then((res) => {
        if (res?.data?.isEmail && res?.data?.isMobile) {
          showErrorToast(res?.data?.message);
        } else if (res?.data?.isEmail) {
          showErrorToast(res?.data?.message);
        } else if (res?.data?.isMobile) {
          showErrorToast(res?.data?.message);
        }
        setLoading(false);
        if (res.data.redirct) {
          navigate(SignupPath, {
            state: _.omitBy(data, (value) => value === ""),
          });
          isRedirct = true;
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
        // showErrorToast(err?.response?.data?.message)
        isRedirct = true;
        navigate(SignupPath, {
          state: _.omitBy(data, (value) => value === ""),
        });
      });
    if (isMobileNumberInput && !isRedirct) {
      if (isMobileNumber) {
        setIsmobileNumber(false);
        setIsOTP(true);
        await axios
          .post(`${process.env.REACT_APP_API_PATH}sendSMS`, {
            mobile_Number: data.phone,
          })
          .then((res) => {
            showSuccessToast(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log({ err });
            setLoading(false);
            if (!err.response) {
              showErrorToast("Something went wrong!");
            } else {
              showErrorToast(err.response.data.message);
            }
          });
      } else if (isOTP) {
        if (resendOTP) {
          await axios
            .post(`${process.env.REACT_APP_API_PATH}sendSMS`, {
              mobile_Number: data.phone,
            })
            .then((res) => {
              showSuccessToast(res.data);
              setResendOTP(false);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              if (!err.response) {
                showErrorToast("Something went wrong!");
              } else {
                showErrorToast(err.response.data.message);
              }
            });
        } else {
          await axios
            .post(`${process.env.REACT_APP_API_PATH}verify_otp`, {
              mobile_Number: data.phone,
              otp: data.otp.join(""),
            })
            .then(async (res) => {
              if (!res.data.success) {
                showErrorToast(res.data.message);
              } else {
                await axios
                  .get(
                    `${process.env.REACT_APP_API_PATH}getCustomer?phone=${data.phone}`
                  )
                  .then(async(res) => {
                      showSuccessToast("Logged in successful!");
                      now.setTime(expireTime);
                      document.cookie =
                        `userId=${res?.data?.data[0]?.id};expires=` +
                        now.toUTCString() +
                        ";path=/";
                      setUserDetails(res?.data?.data[0]);
                      if(redirectTo === undefined || redirectTo == "/login"){
                        navigate(HomePath);
                      }
                      else{
                        navigate(redirectTo);
                      }
                      setLoading(false);
                  });
              }
            })
            .catch((err) => {
              console.log({ err });
              setLoading(false);
              if (!err.response) {
                showErrorToast("Something went wrong!");
              } else {
                showErrorToast(err.response.data.message);
              }
            });
        }
      }
    } else if (isPassword) {
      await axios
        .post(`${process.env.REACT_APP_API_PATH}login`, {
          email: data.email,
          password: data.password,
        })
        .then(async (res) => {
           if (!res.data.success) {
          showErrorToast(res?.data?.message);
        } else {
          showSuccessToast("Logged in successful!");
          now.setTime(expireTime);
          document.cookie =
            `userId=${res?.data?.data?.customer_id};expires=` +
            now.toUTCString() +
            ";path=/";
          const getCustomer = await axios(
            `${process.env.REACT_APP_API_PATH}customer?id=${res?.data?.data?.customer_id}`
          );
          setUserDetails(getCustomer.data[0]);
          JSON.parse(localStorage.getItem('currency')) ? updateCurrency(defaultCurrency) : getLiveLocation()
          if(redirectTo === undefined || redirectTo == "/login"){
            navigate(HomePath);
          }
          else{
            navigate(redirectTo);
          }
        }
          setLoading(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
          if (!err.response) {
            showErrorToast("Something went wrong!");
          } else {
            showErrorToast(err.response.data.message);
          }
        });
    } else {
      setIsPassword(true);
      setIsMobileNumberInput(false);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Upvan Fashion - Sign In</title>
        <meta name="description" content="Upvan Fashion - Sign In" />
      </Helmet>
      <LoginSignupWrapper title={isOTP ? "" : "Sign in"}>
        {loading && <Loader />}
        <Formik
          innerRef={formikRef}
          initialValues={{
            otp: Array(6).fill(""),
            phone: "",
            email: "",
            password: "",
          }}
          validationSchema={loginValidationSchema(
            isMobileNumber,
            isPassword,
            isMobileNumberInput
          )}
          onSubmit={async (values) => {
            loginHandler(values);
          }}
        >
          {({ setFieldValue, errors, values }) => {
            console.log(values.email);
            
            return (
              <Form className={classes.formWrapper}>
                {isMobileNumber && !isPassword && (
                  <div className={classes.inputGroup}>
                    <label>Email or mobile phone number</label>
                    {isMobileNumberInput ? (
                      <PhoneInput
                        name="phone"
                        value={values.phone}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true
                        }}
                        country={"in"}
                        onChange={(phone) => {
                          if (phone.length > 0) {
                            setFieldValue("phone", phone);
                          } else {
                            setIsMobileNumberInput(false);
                          }
                          setFieldValue("email", "");
                        }}
                      />
                    ) : (
                      <Field
                        type="email"
                        placeholder="Email or mobile phone number"
                        name="email"
                        onChange={(e) => {
                          let input = e.target.value;
                          if (input.length > 3) {
                            if (/^\d+$/.test(input)) { 
                              setFieldValue("phone", `91${input}`);
                              setIsMobileNumberInput(true);
                              setFieldValue("email", ""); 
                            } else { 
                              setIsMobileNumberInput(false);
                              setFieldValue("email", input);
                              setFieldValue("phone", ""); 
                            }
                          } else {
                            setFieldValue("email", input); 
                          }
                        }}
                      />
                    )}
                    {errors.email && !isMobileNumberInput && values.email == "" && (
                      <ErrorMessage message={errors.email} />
                    )}
                    {errors.phone && isMobileNumberInput && (
                      <ErrorMessage message={errors.phone} />
                    )}
                  </div>
                )}
                {isPassword && (
                  <div className={classes.inputGroup}>
                    <label>Password</label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        autoComplete="off"
                      />
                      <div
                        className={classes.isPasswordVisible}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                      </div>
                    </div>
                    {errors.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                  </div>
                )}
                {isOTP && (
                  <OTPComponent
                    values={values}
                    setFieldValue={setFieldValue}
                    formikRef={formikRef}
                    phone={values.phone}
                    setResendOTP={setResendOTP}
                  />
                )}
                {!isOTP && (
                  <div className={classes.inputGroup}>
                    <button className={classes.submitButton} type="submit">
                      log in
                    </button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
        {!isOTP && (
          <>
            <div className={classes.inputGroup}>
              {isPassword ? (
                <p className={classes.privacyText}>
                  Forgot your password?{" "}
                  <span onClick={() => navigate(ResetPasswordPath)}>
                    Reset Here
                  </span>
                </p>
              ) : (
                <p className={classes.privacyText}>
                  By continuing, you agree to Upvan{" "}
                  <span onClick={() => navigate(TermsPath)}>
                    Conditions of Use
                  </span>{" "}
                  and
                  <span onClick={() => navigate(PrivacyPolicyPath)}>
                    {" "}
                    Privacy Notice.
                  </span>
                </p>
              )}
            </div>
            <div className={classes.line} />
            <div>
              <p className={classes.newUpvanText}>New to Upvan Fashion?</p>
              <button
                className={classes.loginBtn}
                onClick={() => navigate(SignupPath)}
              >
                Create New Account
              </button>
            </div>
          </>
        )}
      </LoginSignupWrapper>
    </Fragment>
  );
}

export default Login;
