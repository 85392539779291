export function numberToWords(num) {
    const belowTwenty = [
        'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 
        'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];

    const tens = [
        '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];


    if (num === 0) return 'Zero';

    function convert(num) {
        if (num < 20) return belowTwenty[num];
        if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 > 0 ? ' ' + belowTwenty[num % 10] : '');
        if (num < 1000) return belowTwenty[Math.floor(num / 100)] + ' Hundred' + (num % 100 > 0 ? ' ' + convert(num % 100) : '');
        if (num < 100000) return convert(Math.floor(num / 1000)) + ' Thousand' + (num % 1000 > 0 ? ' ' + convert(num % 1000) : '');
        if (num < 10000000) return convert(Math.floor(num / 100000)) + ' Lakh' + (num % 100000 > 0 ? ' ' + convert(num % 100000) : '');

        return convert(Math.floor(num / 10000000)) + ' Crore' + (num % 10000000 > 0 ? ' ' + convert(num % 10000000) : '');
    }

    return convert(num);
}
