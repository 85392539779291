import React from "react";
import classes from "./SaveCardsTab.module.css";
import SaveCardsIcon from "../../../Svgs/SaveCardsIcon";
import MasterCardIcon from "../../../Svgs/MasterCardIcon";
import VisaCardIcon from "../../../Svgs/VisaCardIcon";

function SaveCardsTab() {
  return (
    <div className={classes.tabcontent}>
      <div className={classes.saveCardsWrapper}>
        <SaveCardsIcon />
        <p className={classes.heading}>
          Save your credit/debit cards during payment
        </p>
        <p className={classes.para}>
          It's convenient to pay with saved cards. Your card information will be
          secure, we use 128-bit encryption.
        </p>
      </div>
      <div className={classes.savedCardsContent}>
        <div className={classes.addCardWrapper}>
          <p>Saved Cards</p>
          <button className={classes.addNewCardBtn}>+ ADD NEW CARDS</button>
        </div>
        <div className={classes.allCardsWrapper}>
          <div className={classes.cards}>
            <div className={classes.cardDetails}>
              <p>
                <b>HDFC Credit Card</b>
                <br />
                **** 0000
              </p>
              <butto className={classes.removeBtn}>
                Remove
              </butto>
            </div>
            <div className={classes.cardLogoWrapper}>
              <MasterCardIcon />
              <p>Aman Panwar</p>
            </div>
          </div>
          <div className={classes.cards}>
            <div className={classes.cardDetails}>
              <p>
                <b>AXIS Debit Card</b>
                <br />
                **** 0000
              </p>
              <butto className={classes.removeBtn}>
                Remove
              </butto>
            </div>
            <div className={classes.cardLogoWrapper}>
              <VisaCardIcon />
              <p>Jigar Patel</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveCardsTab;
