import * as Yup from "yup";

export const addressValidationSchema = Yup.object({
  first_name: Yup.string()
    .min(1, "First Name must be at least 1 characters")
    .max(225, "First Name must be 225 characters or less")
    .required("First Name is required"),
  last_name: Yup.string()
    .min(1, "Last Name must be at least 1 characters")
    .max(225, "Last Name must be 225 characters or less")
    .required("Last Name is required"),
  phone: Yup.string()
    .min(0, "Phone Number must be at least 0 characters")
    .max(50, "Phone Number must be 50 characters or less")
    .required("Phone Number is required"),
  city: Yup.string()
    .min(0, "City must be at least 0 characters")
    .max(100, "City must be 100 characters or less")
    .required("City is required"),
  address1: Yup.string().required("Address is required"),
  address2: Yup.string().required("Locality is required"),
  postal_code:Yup.string()
  .required("Pin Code is required")
  .test('is-valid-pincode', 'Invalid Pin Code', function (value) {
    const { country } = this.parent;
    if (country && country.values === 'IN') {
      return /^[0-9]{6}$/.test(value);
    } else if (country && country.values === 'US') {
      return /^[0-9]{5}$/.test(value);
    }
    return true; // If country is neither IN nor US, skip this validation
  }),
});

export const profileValidationSchema = Yup.object({
  first_name: Yup.string()
    .min(1, "First Name must be at least 1 characters")
    .max(100, "First Name must be 100 characters or less")
    .required("First Name is required"),
  last_name: Yup.string()
    .min(1, "Last Name must be at least 1 characters")
    .max(100, "Last Name must be 100 characters or less")
    .required("Last Name is required"),
  phone: Yup.string()
    .min(0, "Phone Number must be at least 0 characters")
    .max(50, "Phone Number must be 50 characters or less")
    .required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .min(3, "Email must be at least 3 characters")
    .max(225, "Email must be 225 characters or less")
    .required("Email is required"),
});

export const giftCertificateSchema = Yup.object({
  to_name: Yup.string()
    .min(1, "Name must be at least 1 characters")
    .max(100, "Name must be 100 characters or less")
    .required("Name is required"),
  from_name: Yup.string()
    .min(1, "Name must be at least 1 characters")
    .max(100, "Name must be 100 characters or less")
    .required("Name is required"),
  to_email: Yup.string()
  .email("Invalid email address")
    .min(3, "Email must be at least 3 characters")
    .max(225, "Email must be 225 characters or less")
    .required("Email is required"),
  from_email: Yup.string()
    .email("Invalid email address")
    .min(3, "Email must be at least 3 characters")
    .max(225, "Email must be 225 characters or less")
    .required("Email is required"),
    amount: Yup.string()
    .min(0, "Number must be at least 0 characters")
    .max(50, "Number must be 50 characters or less")
    .required("Number is required"),
});


export const loginValidationSchema = (
  isMobileNumber,
  isPassword,
  isMobileNumberInput,
) => {
  return Yup.object({
    email:
      isMobileNumber && !isPassword && !isMobileNumberInput
        ? Yup.string().email("Invalid email").required("Email is required")
        : Yup.string().notRequired(),
    password: isPassword
      ? Yup.string()
        .min(7, 'Password must be at least 7 characters long')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)/, 'Password must contain both alphabetic and numeric characters')
        .required('Password is required')
      : Yup.string().notRequired(),
    phone: isMobileNumberInput
      ? Yup.string().required("phone is required")
      : Yup.string().notRequired(),
  });
};

export const resetPasswordValidationSchema = (
  isMobileNumberInput,
  isPasswordScreen
) => {
  return Yup.object({
    phone: isMobileNumberInput
      ? Yup.string().required("phone is required")
      : Yup.string().notRequired(),
    password: isPasswordScreen
      ? Yup.string()
          .min(7, 'Password must be at least 7 characters long')
          .matches(/^(?=.*[A-Za-z])(?=.*\d)/, 'Password must contain both alphabetic and numeric characters')
          .required('Password is required')
      : Yup.string().notRequired(),
    confirm_password: isPasswordScreen
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Password is required")
      : Yup.string().notRequired(),
  });
};

export const signupValidationSchema = () => {
  return Yup.object({
    full_name: Yup.string()
      .min(1, "First Name must be at least 1 characters")
      .max(100, "First Name must be 100 characters or less")
      .required("First Name is required"),
    phone: Yup.string()
      .min(0, "Phone Number must be at least 0 characters")
      .max(50, "Phone Number must be 50 characters or less")
      .required("Phone Number is required"),
    email: Yup.string()
      .email("Invalid email address")
      .min(3, "Email must be at least 3 characters")
      .max(225, "Email must be 225 characters or less")
      .required("Email is required"),
    password: Yup.string()
      .min(7, 'Password must be at least 7 characters long')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)/, 'Password must contain both alphabetic and numeric characters')
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password is required"),
  });
};
