import React, { useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import classes from './BlogSlider.module.css'
import { Link } from 'react-router-dom';



function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",
        }}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  }
function BlogSlider(props) {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
        
      }

      if(window.innerWidth <1440 && window.innerWidth >1024){
        const slidesToShow = 3
        settings = {...settings,slidesToShow}
      }else if(window.innerWidth <1024 && window.innerWidth >767){
        const slidesToShow = 2
        settings = {...settings,slidesToShow}
      }else if(window.innerWidth <767){
        const slidesToShow = 1
        settings = {...settings,slidesToShow}
      }

  return (
    <div className='slider-container'>
        <Slider {...settings} className={classes.Slider}>
        {
                props.relatedBlogs.map((item,index)=>{
                var date = new Date(item.published_date_iso8601);

                // Format the date
                var options = { year: 'numeric', month: 'long', day: '2-digit' };
                var formattedDate = date.toLocaleDateString('en-US', options);

                    return(
                        <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 col p-2' key={index}>
                            <Link to={`/blog-details/${item.id}/`}>
                                <div className={classes.card}>
                                    <div className={classes.image_section}>
                                        <img src={`https://store-cv6fgg2tx1.mybigcommerce.com${item.thumbnail_path}`} alt="blog"/>
                                    </div>
                                    <div className={classes.card_content}>
                                        <div className={classes.title_and_date}>
                                            <p className={classes.title}>Spotlight</p>
                                            <div className={classes.card_date}><p>{formattedDate}</p></div>
                                        </div>
                                        <div className={classes.heading_para}>
                                            <p className={classes.heading}>{item.title}</p>
                                            <p className={classes.para}>{item.summary}</p>
                                        </div>
                                        <button className={classes.learn_more_btn}>Learn More<i className="pe-7s-angle-right"></i></button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }
    </Slider>
    </div>
  )
}

export default BlogSlider