import axios from "axios";
import _ from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Carousel, Container, Row } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { MdArrowRightAlt } from "react-icons/md";
import {
  TiStarFullOutline,
  TiStarHalfOutline,
  TiStarOutline,
} from "react-icons/ti";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BestSelling, HomePath, LoginPath, NewArrivals, ProductsPath } from "../../constant/constant";
import MainContext from "../../contexts/MainContext";
import FilterContext from "../../FilterContext";
import { useCountStore, useCurrencyCode } from "../../store/CountStore";
import { getBigComToken, getUserId } from "../../utils/getUserId";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import BreadCrumb from "../breadCrumb/BreadCrumb";
import Loader from "../Loader/Loader";
import ProductPagination from "../pagination/ProductPagination";
import classes from "./Products.module.css";
import { extractOptionsAndCountVariants } from "./ProductsHelpers";
import { scrollToTop } from "../CommonComponents/CommonHelperFunctions";
import { useProductsPageStore } from "../../store/ProductsPageStore";
import { Helmet } from "react-helmet";

function Products({flag}) {
  const [sortValue, setSortValue] = useState(window.location.pathname == '/best_selling'? 'BEST_SELLING' : "NEWEST");
  const [hasNextPage, setHasNextPage] = useState(true);
  const [hasPrevPage, setPrevPage] = useState(false);
  const [afterPage, setAfterPage] = useState(null);
  const BigComToken = getBigComToken()
  const [beforePage, setBeforePage] = useState(null);
  const mainContext = useContext(MainContext);
  const { searchProduct } = useProductsPageStore();
  const [allData, setAllData] = useState([]);
  const customerId = getUserId();
  const { updateCount , updateWishListIds } = useCountStore();
  const [wishListIds, setWishlistIds] = useState();
  const { defaultCurrency } = useCurrencyCode();
  const [loading, setLoading] = useState(false);
  const [priceBelow500, setPriceBelow500] = useState();
  const [price500to1000, setPrice500to1000] = useState();
  const [price1001to1500, setPrice1001to1500] = useState();
  const [price1501to2000, setPrice1501to2000] = useState();
  const [price2001to2500, setPrice2001to2500] = useState();
  const [priceAbove500, setPriceAbove500] = useState();
  const [colorCount, setColorCount] = useState({})
  const [sizeCount, setSizeCount] = useState({})
  const [selectedRange,setSelectedRange]=useState([])
  const [ searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [categoryList, setCategoryList] = useState([]);
  const [metatitle,setMetaTitle]=useState(searchParams.get("category") ? searchParams.get("category")?.split(",") : [])
  const [currencyData, setCurrencyData]= useState([])
  const [currency_exchange_rate, setCurrency_exchange_rate]= useState()
  
  useEffect(() => {
   if(BigComToken) {
    const categoriesByGql = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_PATH}categoriesList?BigComToken=${BigComToken}`)
        .then((res) => setCategoryList(res?.data?.site?.categoryTree[0]?.children));
    };
    categoriesByGql();
   }
  }, [BigComToken]);

  useEffect(()=>{
    const defaultRate = currencyData?.find((item)=> item.currency_code === defaultCurrency)
    setCurrency_exchange_rate(defaultRate?.currency_exchange_rate)
  },[defaultCurrency,currencyData])

  

  const [selectedCategories, setSelectedCategories] = useState(searchParams.get("selectedCategory") ? searchParams.get("selectedCategory")?.split(",") : []);

  useEffect(()=>{
    location.pathname == '/best_selling'? setSortValue('BEST_SELLING') : setSortValue("NEWEST")
    setSelectedCategories([])
  },[location.pathname])
  const categoryHandler = (event) => {
    scrollToTop()
    setFilters(mainContext);
    const isChecked = event.target.checked;
    if (isChecked) {
      let selectedCategoriesArray = [...selectedCategories, event.target.value]
      setSelectedCategories([...selectedCategoriesArray]);
      navigate((window.location.pathname == ProductsPath || window.location.pathname == NewArrivals  || window.location.pathname == BestSelling ) && `${window.location.pathname}?selectedCategory=${selectedCategoriesArray}`);
    }

    if (!isChecked) {
      const newCatList = selectedCategories.filter(
        (item) => item !== event.target.value
      );
      setSelectedCategories([...newCatList]);
      newCatList.length === 0 ? navigate((window.location.pathname == ProductsPath || window.location.pathname == NewArrivals  || window.location.pathname == BestSelling ) && window.location.pathname) : navigate((window.location.pathname == ProductsPath || window.location.pathname == NewArrivals  || window.location.pathname == BestSelling ) && `${window.location.pathname}?selectedCategory=${newCatList}`);
    }
  };

  const [selectedPriceRange, setSelectedPriceRange] = useState({});
  const [lessPrice, setLessPrice] = useState({});
  const [greaterPrice, setGreaterPrice] = useState({});
  const [enteredPriceRange, setEnteredPriceRange] = useState({
    minPrice: "",
    maxPrice: "",
  });
  
  useEffect(() => {
    setSelectedPriceRange({});
    setGreaterPrice({});
    setLessPrice({});
    setSelectedRange([])
  },[defaultCurrency])

  const minMaxPriceRangeHandler = async () => {
    scrollToTop()
    setFilters(mainContext);
    const nextField = {
      minMaxPrice: [enteredPriceRange.minPrice, enteredPriceRange.maxPrice],
    };
    setSelectedPriceRange(nextField);
  };

  const priceRangeHandler = (event,selectedValue) => {
    scrollToTop()
    setFilters(mainContext);
    const isChecked = event.target.checked;
    selectedRange.includes(selectedValue) ? selectedRange.pop(selectedValue): setSelectedRange([...selectedRange,selectedValue])
    if (
      event.target.name !== "less" &&
      event.target.name !== "greater" &&
      isChecked
    ) {
      const nextField = {
        ...selectedPriceRange,
        [event.target.name]: event.target.value.split(","),
      };
      setSelectedPriceRange(nextField);
    } else if (event.target.name === "less" && isChecked) {
      const nextField = {
        ...lessPrice,
        [event.target.name]: event.target.value.split(","),
      };
      setLessPrice(nextField);
    } else if (event.target.name === "greater" && isChecked) {
      const nextField = {
        ...greaterPrice,
        [event.target.name]: event.target.value.split(","),
      };
      setGreaterPrice(nextField);
    } else {
      setSelectedPriceRange({});
      setGreaterPrice({});
      setLessPrice({});
    }
  };

  const forrmRangeHandler = (event) => {
    setFilters(mainContext);
    const nextField = {
      ...enteredPriceRange,
      [event.target.name]: event.target.value,
    };
    setEnteredPriceRange(nextField);
  };

  localStorage.setItem(
    "selectedPriceRange",
    JSON.stringify(selectedPriceRange)
  );
  localStorage.setItem("lessPrice", JSON.stringify({ lessPrice }));
  localStorage.setItem("greaterPrice", JSON.stringify({ greaterPrice }));
  localStorage.setItem("enteredPriceRange", JSON.stringify(enteredPriceRange));
  localStorage.setItem(
    "selectedCategories",
    JSON.stringify({ selectedCategories })
  );

  mainContext.selectedPriceRange = JSON.parse(
    localStorage.getItem("selectedPriceRange")
  );
  mainContext.lessPrice = JSON.parse(localStorage.getItem("lessPrice"));
  mainContext.greaterPrice = JSON.parse(localStorage.getItem("greaterPrice"));
  mainContext.enteredPriceRange = JSON.parse(
    localStorage.getItem("enteredPriceRange")
  );
  mainContext.selectedCategories = JSON.parse(
    localStorage.getItem("selectedCategories")
  );

  const [selectedColor, setSelectedColor] = useState(searchParams.get("selectedColor")? [searchParams.get("selectedColor")] : []);
  const [selectedSize, setSelectedSize] = useState([]);

  const sizeHandler = (event) => {
    scrollToTop()
    setProductsBySelectedSize([]);
    setCurrentPage(1);
    setFilters(mainContext);
    const isChecked = event.target.checked;
    const index = selectedSize.indexOf(event.target.value);
    if (isChecked) {
      setSelectedSize([...selectedSize, event.target.value]);
      // getPaginationAlongside()
    }
    if (!isChecked && index > -1) {
      // selectedSize.splice(index, 1)
      const newCatList = selectedSize.filter(
        (item) => item !== event.target.value
      );
      setSelectedSize(newCatList);
      // getPaginationAlongside()
    }
  };

  const colorHandler = (event) => {
    scrollToTop()
    setFilters(mainContext);
    setCurrentPage(1);
    setProductsBySelectedColor([]);
    const isChecked = event.target.checked;
    const index = selectedColor.indexOf(event.target.value);
    if (isChecked) {
      setSelectedColor([...selectedColor, event.target.value]);
      // getPaginationAlongside()
    }
    if (!isChecked && index > -1) {
      selectedColor.splice(index, 1);
      const newCatList = selectedColor.filter(
        (item) => item !== event.target.value
      );
      setSelectedColor(newCatList);
      // getPaginationAlongside()
    }
  };

  const [productList, setProductList] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [productsBySelectedSize, setProductsBySelectedSize] = useState([]);
  const [productsBySelectedColor, setProductsBySelectedColor] = useState([]);
  const productsPerPage = 16;
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = productList.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  
  const [filters, setFilters] = useState({});
  const [indexCaraousel, setIndex] = useState(2);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (e, v) => {
    setIndex(v);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getCurrentPage = (newPage, buttonName) => {
    setCurrentPage(newPage);
      scrollToTop()
  };

  const handleFilter = async (sort) => {
    setSortValue(sort);
  };

  useEffect(() => {
    setLoading(true);
    scrollToTop()
    if(categoryList?.length !== 0) {
      getProductsData(sortValue);
    }
    setCurrentPage(1);
  }, [
    selectedCategories,
    selectedColor,
    selectedSize,
    sortValue,
    categoryList,
    selectedPriceRange,
    lessPrice,
    greaterPrice,
    defaultCurrency,
    searchProduct,
    window.location.pathname
  ]);
console.log({sortValue});

  useEffect(() => {
   if(categoryList.length !== 0 && searchParams.get('category')) {
    let searchProducts = searchParams.get("category")?.split(/[\s,]+/).map((item) => {
      return item.trim();
    })
    let newData = categoryList.filter(v => v.productCount > 0 && searchProducts?.includes(v.name)).map(v => v.entityId.toString())
    setSelectedCategories(newData)
   }
  },[categoryList, searchParams])

  const getCurrencyData = async ()=>{
    await axios.get(`${process.env.REACT_APP_API_PATH}getCurrencyData`)
    .then((res)=>{
      setCurrencyData(res?.data)
    })
    .catch((err)=>{
      console.log({err});      
    })
  }

  useEffect(()=>{
    getCurrencyData()
  },[])

  const getProductsData = async (
    sort,
  ) => {
    let couponWiseCategories;
    if(location.state !== null) {
      if(location.state.entity === "categories") {
        couponWiseCategories = location.state.ids
      }
      }
    let searchProducts = searchParams.get("category")?.split(/[\s,]+/).map((item) => {
      return item.trim();
    })
    let newData = categoryList.filter(v => v.productCount > 0 && searchProducts?.includes(v.name)).map(v => v.entityId.toString())
 
    const details = {
      selectedCategories: location.state !== null && location.state.entity === "categories" ? couponWiseCategories : searchParams.get("selectedCategory") ? selectedCategories : newData,
      searchProduct
    };
console.log({details},location.state !== null && location.state.entity === "categories" , couponWiseCategories , searchParams.get("selectedCategory") , {selectedCategories} ,{newData});

    await axios
      .post(
        `${
          process.env.REACT_APP_API_PATH
        }getProductsByFilter`, { details: details, sort:sort, currencyCode: defaultCurrency } , {
          headers: {
            Authorization: 'Bearer ' + BigComToken
          }
        },
      )
      .then((res) => {
        let products = res.data?.data?.site.search.searchProducts.products.edges;
        const { options, variantCount,variantCountColor } = extractOptionsAndCountVariants(products);
        setColorCount(variantCountColor)
        setSizeCount(variantCount)
        const responseToCheck =
          res.data.data.site.search.searchProducts.products;
        const transformData = (response) => {
          return response.edges.map((edge) => {
            const node = edge.node;
            return {
              ...node,
              id: node.entityId,
              name: node.name,
              price: node.prices.price.value,
              pricecurrencyCode: node.prices.price.currencyCode,
              categories: node.categories,
              options: node.productOptions.edges.map((optionEdge) => ({
                ...optionEdge,
                product_id: node.entityId,
                name: optionEdge.node.displayName,
                display_name: optionEdge.node.displayName,
                type:
                  optionEdge.node.displayName === "Color"
                    ? "radio_buttons"
                    : "dropdown",
                option_values: optionEdge.node.values.edges.map(
                  (valueEdge) => ({
                    id: valueEdge.node.entityId,
                    label: valueEdge.node.label,
                    value_data: null,
                    is_default: false,
                  })
                ),
              })),
              reviews_rating_sum: node.reviews.edges.reduce(
                (sum, review) => sum + review.node.rating,
                0
              ),
              reviews_count: node.reviews.edges.length,
              images: node.images.edges.map((image) => ({
                id: image.node.entityId,
                is_thumbnail: image.node.isDefault,
                url_thumbnail: image.node.urlOriginal,
              })),
            };
          });
        };

        const transformedData = transformData(responseToCheck);
        filterBasedOnSizeColor(transformedData);
        setAfterPage(
          responseToCheck.pageInfo.hasNextPage
            ? responseToCheck.pageInfo.endCursor
            : null
        );
        setHasNextPage(responseToCheck.pageInfo.hasNextPage);
        setPrevPage(responseToCheck.pageInfo.hasPreviousPage);
        setBeforePage(
          responseToCheck.pageInfo.hasPreviousPage
            ? responseToCheck.pageInfo.startCursor
            : null
        );

        setAllData(transformedData);
        setAllProducts(transformedData);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);
        console.error(er);
      });
  };

  const filterBasedOnSizeColor = (productList) => {
    let filteredProducts = productList;
    if (selectedSize.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        product.options.some(
          (option) =>
            option.name.startsWith("Size") &&
            option.option_values.some((value) =>
              selectedSize.includes(value.label)
            )
        )
      );
    }
    if (selectedColor.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        product.options.some(
          (option) =>
            option.name.startsWith("Color") &&
            option.option_values.some((value) =>
              selectedColor.includes(value.label)
            )
        )
      );
    }

    const ranges = Object.values(selectedPriceRange);
    const lessRanges = Object.values(lessPrice);
    const greaterRanges = Object.values(greaterPrice);

    if (
      ranges.length > 0 ||
      lessRanges.length > 0 ||
      greaterRanges.length > 0
    ) {
      filteredProducts = filteredProducts.filter((product) => {
        const productPrice = product.price;
        return (
          ranges.some((range) => {
            return productPrice >= range[0] && productPrice <= range[1];
          }) ||
          lessRanges.some((range) => {
            return productPrice < range[0];
          }) ||
          greaterRanges.some((range) => {
            return productPrice > range[0];
          })
        );
      });
    }
    if(location.state && location.state.entity !== "categories"){
        let filteredProductsFromCoupon = filteredProducts.filter(v=>location?.state?.ids.includes(v.entityId))
        console.log(filteredProductsFromCoupon)
        setProductList(!location.state ? filteredProducts : filteredProductsFromCoupon);
        setTotalPages(!location.state ? Math.ceil(filteredProducts.length / productsPerPage) : Math.ceil(filteredProductsFromCoupon.length / productsPerPage));
    } else {
        setProductList(filteredProducts);
        setTotalPages(Math.ceil(filteredProducts.length / productsPerPage));
    }

  };

  const addWishlistHandler = async (id, data) => {
    
    let variant_id = data.edges?.[0]?.node?.entityId;
    
    setLoading(true)
    await axios
      .post(`${process.env.REACT_APP_API_PATH}wishlist`, {
        customer_id: customerId,
        product_id: id,
        variant_id: variant_id
      })
      .then(() => {
        fetchWishlistProductData();
        setLoading(false)
        // showSuccessToast("Product Added to Wishlist!");
      })
      .catch((err) => {
         setLoading(false)
         console.log(err?.response?.data?.error)});
  };

  const fetchWishlistProductData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PATH}wishlist?id=${customerId}`)
      .then((res) => {
        let ids = res.data?.map((ele) => ele.items[0].product_id);
        setWishlistIds(ids);
        updateWishListIds(ids)
        updateCount(ids?.length)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isNaN(customerId)) {
      fetchWishlistProductData();
    }
  }, []);

  const breadcrumbData = [
    {
      breadcrumbsText: "Home",
      breadcrumbsURL: HomePath,
    },
    {
      breadcrumbsText: "Clothing",
      breadcrumbsURL: ProductsPath,
    },
  ];

  ///////// price filter/////////////////

  const priceFilter = () => {
    setPriceBelow500(allProducts?.filter((item) => item?.price < 500 * currency_exchange_rate ).length);
    setPrice500to1000(
      allProducts?.filter((item) => item?.price >= 500 * currency_exchange_rate && item?.price <= 1000 * currency_exchange_rate)
        .length
    );
    setPrice1001to1500(
      allProducts?.filter((item) => item?.price >= 1001 * currency_exchange_rate && item?.price <= 1500 * currency_exchange_rate)
        .length
    );
    setPrice1501to2000(
      allProducts?.filter((item) => item?.price >= 1501 * currency_exchange_rate && item?.price <= 2000 * currency_exchange_rate)
        .length
    );
    setPrice2001to2500(
      allProducts?.filter((item) => item?.price >= 2001 * currency_exchange_rate && item?.price <= 2500 * currency_exchange_rate)
        .length
    );
    setPriceAbove500(allProducts?.filter((item) => item?.price > 500 * currency_exchange_rate).length);
  };
  useEffect(() => {
    priceFilter();
  }, [allProducts]);

  //====================Price ranges==============================
  const priceRanges = [
    {
      rangeText: `Below ${defaultCurrency === "INR" ? "Rs" : "$"}.${Math.round(500 * currency_exchange_rate)} [${priceBelow500}]`,
      range: [500  * currency_exchange_rate],
      less: true,
      greater: false,
      selectedValue:1
    },
    {
      rangeText: `${defaultCurrency === "INR" ? "Rs" : "$"}.${Math.round(500 * currency_exchange_rate)}-${Math.round(1000 * currency_exchange_rate)} [${price500to1000}]`,
      range: [500 * currency_exchange_rate, 1000 * currency_exchange_rate],
      less: false,
      greater: false,
      selectedValue:2
    },
    {
      rangeText: `${defaultCurrency === "INR" ? "Rs" : "$"}.${Math.round(1001 * currency_exchange_rate)}-${Math.round(1500 * currency_exchange_rate)} [${price1001to1500}]`,
      range: [1001 * currency_exchange_rate, 1500 * currency_exchange_rate],
      less: false,
      greater: false,
      selectedValue:3
    },
    {
      rangeText: `${defaultCurrency === "INR" ? "Rs" : "$"}.${Math.round(1501 * currency_exchange_rate)}-${Math.round(2000 * currency_exchange_rate)} [${price1501to2000}]`,
      range: [1501 * currency_exchange_rate, 2000 * currency_exchange_rate],
      less: false,
      greater: false,
      selectedValue:4
    },
    {
      rangeText: `${defaultCurrency === "INR" ? "Rs" : "$"}.${Math.round(2001 * currency_exchange_rate)}-${Math.round(2500 * currency_exchange_rate)} [${price2001to2500}]`,
      range: [2001 * currency_exchange_rate, 2500 * currency_exchange_rate],
      less: false,
      greater: false,
      selectedValue:5
    },
    {
      rangeText: `Above ${defaultCurrency === "INR" ? "Rs" : "$"}.${Math.round(500 * currency_exchange_rate)} [${priceAbove500}]`,
      range: [500 * currency_exchange_rate],
      less: false,
      greater: true,
      selectedValue:6
    },
  ];

  return (
    <div style={{minHeight:"70vh"}}>
      {window.location.pathname === '/new_arrivals' ? <Helmet>
        <title>New Arrivals - Shop for Women’s clothes, dresses and accessories - Upvan Fashion</title>
        <meta name="description" content="New Arrivals - Shop for Women’s clothes, dresses and accessories" />
      </Helmet> : window.location.pathname === '/best_selling' ? <Helmet>
        <title>Best Selling - Shop for Women’s clothes, dresses and accessories - Upvan Fashion</title>
        <meta name="description" content="New Arrivals - Shop for Women’s clothes, dresses and accessories" />
      </Helmet> : <Helmet>
        <title>{metatitle.length > 0 ? `Online shopping for Women - Shop for ${metatitle} - Upvan Fashion` : "Online shopping for Women - Shop for Women’s clothes, dresses and accessories"}</title>
        <meta name="description" content={metatitle.length > 0 ? `Online shopping for Women - Shop for ${metatitle} - Upvan Fashion` : "Online shopping for Women - Shop for Women’s clothes, dresses and accessories"} />
      </Helmet>}
     
      <BreadCrumb breadcrumbData={breadcrumbData} productItem = { productList.length}/>
      
      <div>
        {loading ? 
        <Loader />
       : 
      <>
      <Container style={{width:"90%",maxWidth:"unset"}}>
          {window.location.pathname === '/products' && <Row>
            <div className={classes.breadcrumbsAndFilter}>
              <div className={classes.storefrontSorting}>
                <span
                  className={classes.heading}
                  style={{
                    fontSize: "16px",
                    margin: "0px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color:"#333"
                  }}
                >
                  Filters
                </span>
                <select
                  onChange={(e) => handleFilter(e.target.value)}
                  className={classes.selectSorting}
                  name="sorting"
                  value={sortValue}
                  id="sorting"
                >
                  <option value="BEST_SELLING">Sort by popularity</option>
                  <option value="BEST_REVIEWED">Sort by average rating</option>
                  <option value="NEWEST">Sort by newness</option>
                  <option value="LOWEST_PRICE">
                    Sort by price: low to high
                  </option>
                  <option value="HIGHEST_PRICE">
                    Sort by price: high to low
                  </option>
                </select>
              </div>
              <hr />
            </div>
          </Row>}
          <Row className={`${classes.productListWrapper} productList`}>
            <div className={`col-lg-2 col-md-3 col-sm-2 col-xs-12  ${classes.productlistCategoriesContainer}`}>
              <form className={classes.filterForm}>
                <h5 className={classes.filterTitle}>Categories</h5>
                {categoryList.map((item, index) => {
                return(
                  item.productCount>0 &&
                    <div key={index}>
                    <label className={classes.categoryLabel}>
                      {item.name + ` [${item.productCount}]`}
                      <input
                        type="checkbox"
                        name={item.name}
                        value={Number(item.entityId)}
                        defaultChecked={selectedCategories.includes(JSON.stringify(item.entityId)) && "true"}
                        onChange={categoryHandler}
                      />
                      <span className={classes.checkmark}></span>
                    </label>
                  </div>
                  )
              } 
              )}
              <label className={`${classes.categoryLabel} ${classes.clearText}`} 
                onClick={()=>{
                  navigate(ProductsPath)
                  setSelectedCategories([])
                }}>Clear</label>
                  <div className={classes.devider}></div>

                  <h5 className={classes.filterTitle}>Price</h5>
                  <div>
                    {priceRanges.map((item, index) => {
                      return (
                        <label className={classes.categoryLabel} key={index}>
                          {" "}
                          {item.rangeText}
                          <input
                            name={
                              item.less
                                ? "less"
                                : item.greater
                                ? "greater"
                                : `range${index++}`
                            }
                            type="checkbox"
                            value={item.range}
                            defaultChecked={selectedRange.includes(item.selectedValue) && true}
                            onChange={(e)=>priceRangeHandler(e,item.selectedValue)}
                          />
                          <span className={classes.checkmark}></span>
                        </label>
                      );
                    })}
                  </div>
                  <hr></hr>
                  <div>
                    <div className={classes.input_price_filter}>
                      <div className={classes.price_range_title}>
                        <span className={classes.text}>Enter Price Range </span>
                        <span
                          onClick={() => {
                            setSelectedPriceRange({});
                            setEnteredPriceRange({
                              minPrice: "",
                              maxPrice: "",
                            });
                          }}
                          className={classes.clear_all}
                          aria-label="Clear"
                          role="button"
                          tabIndex="0"
                        >
                        Clear
                        </span>
                      </div>
                      <div className={classes.facet_min_price_filter}>
                        <input
                          name="minPrice"
                          value={enteredPriceRange.minPrice}
                          type="text"
                          autoComplete="off"
                          id="minPrice"
                          placeholder="Min"
                          maxLength="6"
                          aria-label="Min"
                          onChange={forrmRangeHandler}
                        />
                        <p className="m-0">—</p>
                        <input
                          name="maxPrice"
                          value={enteredPriceRange.maxPrice}
                          type="text"
                          autoComplete="off"
                          placeholder="Max"
                          id="maxPrice"
                          maxLength="6"
                          aria-label="Max"
                          onChange={forrmRangeHandler}
                        />
                        <button
                          type="button"
                          onClick={minMaxPriceRangeHandler}
                          className={`${classes.rangeButton} faa-parent animated-hover`}
                        >
                          <MdArrowRightAlt className="faa-passing" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.devider}></div>
                <h5 className={classes.filterTitle}>Sizes</h5>
                {Object.entries(sizeCount)?.map(([key, value],index) => (
                  <div key={index}>
                    <label className={classes.categoryLabel}>
                      {key} [{value}]
                      <input
                        type="checkbox"
                        name={key}
                        value={key}
                        onChange={sizeHandler}
                        defaultChecked={selectedSize.includes(key) && "true"}
                      />
                      <span className={classes.checkmark}></span>
                    </label>
                  </div>
                ))}

                  <div className={classes.devider}></div>

                <h5 className={classes.filterTitle}>Colors</h5>
                {Object.entries(colorCount)?.map(([key, value],index) => (
                  <div key={index}>
                    <label className={classes.categoryLabel}>
                      {key} [{value}]
                      <input
                        type="checkbox"
                        name={key}
                        value={key}
                        onChange={colorHandler}
                        defaultChecked={selectedColor.includes(key) && "true"}
                      />
                      <span className={classes.checkmark}></span>
                    </label>
                  </div>
                ))}
                <div className={classes.devider}></div>
                {window.location.pathname == '/products' && <div className={`${classes.box} sidebar-banner`}>
                  <figure className={classes.imageEffect}>
                    <Link onClick={() => scrollToTop()} to={NewArrivals}>
                      <img
                        src="../../images/sidebar-banner.jpg"
                        alt="img-new-arrival"
                      />
                    </Link>
                  </figure>
                </div>}
              </form>
            </div>
           <div className={`col-lg-10 col-md-9 col-sm-10 col-xs-12 ${classes.productlistContainer}`}>
          {productList.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}><p>No Products Found!</p></div>:<Fragment>
           <Row>
                {currentProducts.map((item, index) => {
                  // =================product sizes ===============================
                const inStockVariants = item?.variants?.edges?.map((item)=>item.node).filter((item)=>item.inventory.isInStock===true)
                let newVarients = inStockVariants?.map((item)=> {
                   return item?.options?.edges.filter((item)=>item.node.displayName==='Size')[0].node.values.edges[0].node.label 
                })
                let sizes=Array.from(new Set(newVarients))
                
                // =================================================================
                
                  let reviewsRating = item.reviews_rating_sum / item.reviews_count.toFixed(1)
                  let reviewHalfRating = 0
                _.isNaN(parseInt(reviewsRating))
                  ? reviewsRating=0
                  : reviewsRating=reviewsRating;
                let halfRating = !_.isNaN(parseInt(reviewsRating)) && !_.isInteger(reviewsRating);
                halfRating ? reviewHalfRating=1 : reviewHalfRating=0;
                
                return (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 pb-5"
                    key={index}
                  >
                    <div className={classes.product}>
                      <div className={classes.productContainer}>
                        <Link to={`/product_details/${item.id}`}
                          className={classes.productLink}
                        >
                          <Carousel
                            interval={
                              index === indexCaraousel && isHovered
                                ? 2000
                                : null
                            }
                            controls={false}
                            indicators={false}
                            pause={false}
                            onMouseEnter={(e) => handleMouseEnter(e, index)}
                            onMouseLeave={handleMouseLeave}
                          >
                            {item.images.map((image, idx) => (
                              <Carousel.Item key={idx}>
                                <img
                                  className="d-block w-100 h-100"
                                  src={image.url_thumbnail}
                                  alt={`Slide ${idx}`}
                                />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </Link>
                        <div className={classes.addTowishList}>
                          <button style={{
                            backgroundColor: wishListIds?.includes(item.id) ? "#eb2675" : "#fff",
                            color: wishListIds?.includes(item.id) ? "#fff" : "#333"
                          }}
                          onClick={() => {
                            if (isNaN(customerId)) {
                              navigate(LoginPath,{state:{from: location.pathname + location.search}});
                              localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
                              // showErrorToast("You must login first!")
                            } else {
                              // wishListIds?.includes(item.id)
                              //   ? showErrorToast("Alredy exist")
                              //   :                               
                              !wishListIds?.includes(item.id) && addWishlistHandler(item.id, item?.variants);
                            }
                          }}>
                            {wishListIds?.includes(item.id) ? <FaHeart/> : <FaRegHeart />} {wishListIds?.includes(item.id) ? "WISHLISTED"  :"WISHLIST" }
                          </button>
                          <Link to={`/product_details/${item.id}`}>
                            <p>
                              <b>Size</b>:{" "}
                              {sizes.map((item, index) => {
                                return <span key={index}>{item}{index===sizes.length-1?'':', '}</span>;
                              })}
                            </p>
                          </Link>
                        </div>
                      </div>
                      <Link to={`/product_details/${item.id}`}>
                        <h5>{item.name}</h5>
                        <div className={classes.price}>
                          <div>
                            <Link>
                              {item.prices.basePrice.currencyCode==="INR"?"₹":"$"}{item.prices.basePrice.value}
                            </Link>
                            <Link>
                              {item.pricecurrencyCode==="INR" ? "₹" : "$"}{item.price}
                            </Link>
                          </div>
                          <div className={classes.stars}>
                            <span className={classes.startRating}>
                                {Array.from(
                                  { length: parseInt(reviewsRating) },
                                  (_, index) => (
                                    <TiStarFullOutline className={classes.starIcon}
                                      fill="#EB2675"
                                      key={index}
                                    />
                                  )
                                )}
                                {Array.from({ length: reviewHalfRating }, (_, index) => (
                                  <TiStarHalfOutline className={classes.starIcon}
                                    fill="#EB2675"
                                    key={index}
                                  />
                                ))}
                                {Array.from(
                                  {
                                    length: 5 - reviewHalfRating - parseInt(reviewsRating),
                                  },
                                  (_, index) => (
                                    <TiStarOutline className={classes.starIcon} fill="#EB2675"  key={index} />
                                  )
                                )}
                            </span>
                          </div>
                        </div>
                      </Link>
                      <div></div>
                    </div>
                  </div>
                );
              })}
            </Row>
            <ProductPagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={getCurrentPage}
            />
           </Fragment>}
          </div>
        </Row>
      </Container>
      </>
      }
    </div>
    </div>
  );
}

export default Products;
