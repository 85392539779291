import React from "react";

function VisaCardIcon() {
  return (
    <svg
      width="101"
      height="33"
      viewBox="0 0 101 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.2176 10.3236C52.1606 14.8218 56.2264 17.3322 59.2893 18.8246C62.4362 20.356 63.4932 21.3379 63.4812 22.7072C63.4572 24.8032 60.9708 25.7281 58.6437 25.7641C54.5838 25.8272 52.2236 24.6681 50.3469 23.7912L48.8845 30.6347C50.7673 31.5025 54.2535 32.2592 57.8689 32.2922C66.3549 32.2922 71.9071 28.1033 71.9372 21.6082C71.9702 13.3654 60.5354 12.909 60.6135 9.22455C60.6405 8.1075 61.7065 6.91538 64.0427 6.6121C65.1988 6.45895 68.3908 6.34184 72.0092 8.00841L73.4296 1.38719C71.4837 0.67852 68.9824 -0.000117779 65.8684 -0.000117779C57.8809 -0.000117779 52.2627 4.24587 52.2176 10.3236ZM87.0774 0.570418C85.5279 0.570418 84.2217 1.47427 83.6392 2.86157L71.5168 31.8058H79.9967L81.6843 27.1424H92.0471L93.026 31.8058H100.5L93.9779 0.570418H87.0774ZM88.2635 9.00835L90.7108 20.7374H84.0085L88.2635 9.00835ZM41.936 0.570418L35.2517 31.8058H43.3323L50.0135 0.570418H41.936ZM29.9817 0.570418L21.5708 21.8304L18.1686 3.75341C17.7692 1.73551 16.1928 0.570418 14.4421 0.570418H0.692181L0.5 1.47727C3.32265 2.08985 6.52967 3.07778 8.47249 4.13477C9.66161 4.78038 10.0009 5.34491 10.3913 6.87935L16.8354 31.8058H25.3754L38.4677 0.570418H29.9817Z"
        fill="url(#paint0_linear_91_560)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_91_560"
          x1="4597.92"
          y1="97.0763"
          x2="4691.39"
          y2="-3219.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#222357" />
          <stop offset="1" stop-color="#254AA5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default VisaCardIcon;
