import React, { useEffect, useState } from "react";
import axios from "axios";
import { getUserId } from "../../../../utils/getUserId";
import { userDetailsStore } from "../../../../store/GlobalUserData";
import JsPDF from "jspdf";
import { dateFormat } from "../../../../utils/dateFormat";
import { numberToWords } from "../../../../utils/numberToWords";
import Loader from "../../../Loader/Loader";
import { Modal } from "react-bootstrap";
import classes from "../../AddressesTab/AddAddressModal/AddAddressModal.module.css";
import styles from "./Bill.module.css";
import html2canvas from "html2canvas";
import { useMobileScreen } from "../../../../utils/isMobileScreen";

const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

function Bill({ open, handleClose, orderId }) {
  const customer_id = getUserId();
  const { userDetails } = userDetailsStore();
  const [productDetails, setProductDetails] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    products: [],
    shipping_address: [],
  });
  const [currency_code, setCurrency_code] = useState("₹");
  const [currency_name, setCurrency_name] = useState("INR");
  const [isIGST, setIsIGST] = useState(false);
  const [word, setWord] = useState("");
  const [loading, setLoading] = useState(false);
  const smallScreen = useMobileScreen();
  let showProduct = smallScreen ? 2 : 3;
  const chunks = chunkArray(productDetails, showProduct);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [soldDetails, setSoldDetails] = useState({});
  const [couponData, setCouponData] = useState({});

  const getSoldDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_PATH}soldDetails`)
      .then((res) => {
        setSoldDetails(res?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getOrderById = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}getOrderById?email=${userDetails.email}`,
        {
          order_id: orderId,
          customer_id: customer_id,
        }
      )
      .then((response) => {
        let currency = response.data?.currency_code == "INR" ? "₹" : "$";
        setCurrency_code(currency);
        setCurrency_name(response.data?.currency_code);
        let currentState =
          response.data?.shipping_address[0]?.state == "Gujarat" ? false : true;
        setIsIGST(currentState);
        let word = parseFloat(response.data?.total_inc_tax);
        setWord(numberToWords(word));
        setOrderDetails(response.data);
        setProductDetails(response.data.products);
        setDownloadPdf(true);
        let coupons = response.data?.couponsData;
        if (coupons.length > 0) {
          setCouponData(coupons[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  useEffect(() => {
    getSoldDetails();
  }, []);

  useEffect(() => {
    if (downloadPdf) {
      generatePDF();
      setDownloadPdf(false);
    }
  }, [downloadPdf]);

  useEffect(() => {
    getOrderById();
  }, [orderId]);

  const generatePDF = async () => {
    const input = document.getElementById("report");
    const pdf = new JsPDF("portrait", "pt", "a4");

    const chunks = Array.from(document.querySelectorAll(".page-chunk"));

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];

      const canvas = await html2canvas(chunk, {
        scale: 2,
        scrollX: 0,
        scrollY: -window.scrollY,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      if (i < chunks.length - 1) {
        pdf.addPage();
      }
    }

    pdf.save(`invoice_for_${orderDetails.id}.pdf`);
    handleClose();
  };

  return (
    <div className={styles.billWrapper}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {downloadPdf && <Loader />}
          <Modal
            show={open}
            className={`${classes.modal} editprofileModal ${styles.billModal}`}
            id="newsletter"
            tabIndex="-1"
            onHide={handleClose}
            centered
            backdrop={false}
            fade={false}
          >
            <div className={classes.modalContent}>
              <div id="report">
                {chunks?.map((ele, i) => (
                  <div className={`${styles.billContainer} page-chunk`} key={i}>
                    {/* Logo and Title */}
                    <div className={styles.HeaderWrapper}>
                      <img
                        src="../../images/upvan-icon.png"
                        alt="Company Logo"
                      />
                    </div>
                    <div>
                      <p className={styles.subHeader}>
                        <strong>
                          {soldDetails?.name} invoice for order #
                          {orderDetails?.id}
                        </strong>
                      </p>
                      <p className={styles.addresspera}
                      >
                        <strong>{soldDetails?.address}</strong>
                      </p>
                    </div>

                    {/* Sold By and Billing Address */}
                    <div className={styles.addressWrapper}>
                      <div style={{ width: "45%" }}>
                        <p className={styles.billingHeader}>
                          <strong> Billing Details: </strong>
                        </p>
                        <p
                          className={styles.addresspera}
                        >
                          {orderDetails?.billing_address?.first_name}{" "}
                          {orderDetails?.billing_address?.last_name}
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.street_1}
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.street_2}{" "}
                        </p>{" "}
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.city},{" "}
                          {orderDetails?.billing_address?.state}{" "}
                          {orderDetails?.billing_address?.zip}
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.country}{" "}
                        </p>
                        <p className={styles.addresspera}>
                          Phone: {orderDetails?.billing_address?.phone}
                        </p>
                        <p className={styles.addresspera}>
                          Email: {orderDetails?.billing_address?.email}
                        </p>
                        <div style={{ marginTop: "12px", display: "flex" }}>
                          <div>
                            <p className={styles.addresspera}>
                              <strong>Order: </strong>
                            </p>
                            <p className={styles.addresspera}>
                              <strong>Payment Method:</strong>{" "}
                            </p>
                          </div>
                          <div>
                            <p className={styles.addresspera}>
                              {" "}
                              #{orderDetails?.id}
                            </p>
                            <p className={styles.addresspera}>
                              N/A ({currency_code}
                              {parseFloat(orderDetails?.total_inc_tax).toFixed(
                                2
                              )}
                              )
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Shipping Address */}
                      <div style={{ width: "45%" }}>
                        <p className={styles.billingHeader}>
                          <strong>Shipping Address:</strong>
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.shipping_address[0]?.first_name}{" "}
                          {orderDetails?.shipping_address[0]?.last_name}
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.street_1}
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.street_2}{" "}
                        </p>{" "}
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.city},{" "}
                          {orderDetails?.billing_address?.state}{" "}
                          {orderDetails?.billing_address?.zip}
                        </p>
                        <p className={styles.addresspera}>
                          {orderDetails?.billing_address?.country}{" "}
                        </p>
                        <p className={styles.addresspera}>
                          Phone: {orderDetails?.billing_address?.phone}
                        </p>
                        <p className={styles.addresspera}>
                          Email: {orderDetails?.billing_address?.email}
                        </p>
                        <div style={{ marginTop: "12px", display: "flex" }}>
                          <div>
                            <p className={styles.addresspera}>
                              <strong>Order Date:</strong>{" "}
                            </p>
                            <p className={styles.addresspera}>
                              <strong>Shipping Method: </strong>
                            </p>
                          </div>
                          <div>
                            <p className={styles.addresspera}>
                              {dateFormat(orderDetails?.date_created)}
                            </p>
                            <p className={styles.addresspera}>{orderDetails?.shipping_address[0]?.shipping_method}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.divider}></div>
                    <p className={styles.billingHeader}>
                      <strong>Order Items</strong>
                    </p>
                    {/* Product Details */}
                    <table
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Qty</th>
                          <th>Code/SKU</th>
                          <th>Product Name</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ele?.map((item, index) => {
                          let sizeData = item.product_options?.find(
                            (item) => item.display_name == "Size"
                          );
                          let colorData = item.product_options?.find(
                            (item) => item.display_name == "Color"
                          );
                          return (
                            <tr key={index}>
                              <td>{item.quantity}</td>
                              <td>{item.sku}</td>
                              <td>
                                <p className={styles.itemName}>{item.name}</p>
                                <div style={{ display: "flex", gap: "8px" }}>
                                  <div>
                                    <p>Color:</p>
                                    <p>Size:</p>
                                  </div>
                                  <div>
                                    <p>{colorData?.display_value}</p>
                                    <p>{sizeData?.display_value}</p>
                                  </div>
                                </div>
                              </td>
                              {/* {isIGST ? (
                                <td>
                                  <p>18%</p>
                                </td>
                              ) : (
                                <td>
                                  <p>9%</p>
                                  <p>9%</p>
                                </td>
                              )}
                              {isIGST ? (
                                <td>
                                  <p>IGST</p>
                                </td>
                              ) : (
                                <td>
                                  <p>CGST</p>
                                  <p>SGST</p>
                                </td>
                              )}
                              {isIGST ? (
                                <td>
                                  <p>
                                    {currency_code}
                                    {parseFloat(item.price_tax).toFixed(2)}
                                  </p>
                                </td>
                              ) : (
                                <td>
                                  <p>
                                    {currency_code}
                                    {parseFloat(item.price_tax).toFixed(2) / 2}
                                  </p>
                                  <p>
                                    {currency_code}
                                    {parseFloat(item.price_tax).toFixed(2) / 2}
                                  </p>
                                </td>
                              )} */}
                              <td>
                                {currency_code}
                                {parseFloat(item.price_inc_tax).toFixed(2)}{" "}
                                {currency_name}
                              </td>
                              <td>
                                {currency_code}
                                {parseFloat(item.price_inc_tax).toFixed(2)}{" "}
                                {currency_name}
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr>
                          <td>
                            <strong>Total:</strong>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            {currency_code}
                            {parseFloat(orderDetails?.total_tax).toFixed(2)}
                          </td>
                          <td>
                            {currency_code}
                            {parseFloat(orderDetails?.total_inc_tax).toFixed(2)}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    <div className={styles.divider}></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "20px",
                      }}
                    >
                      <div className={styles.totalDiv}>
                        <p>Subtotal:</p>
                        {couponData?.code && (
                          <p>Coupon code ({couponData?.code}):</p>
                        )}
                        <p>Shipping:</p>
                        <p>
                          <strong>Grand total:</strong>
                        </p>
                        {isIGST ? (
                          <p>IGST Included in total:</p>
                        ) : (
                          <>
                            <p>CGST Included in total:</p>
                            <p>SGST Included in total:</p>
                          </>
                        )}
                      </div>
                      <div className={styles.totalDiv}>
                        <p>
                          {currency_code}
                          {parseFloat(orderDetails?.subtotal_inc_tax).toFixed(
                            2
                          )}{" "}
                          {currency_name}
                        </p>
                        {couponData?.code && (
                          <p>
                            -{currency_code}
                            {parseFloat(couponData.discount).toFixed(
                              2
                            )}{" "}
                            {currency_name}
                          </p>
                        )}
                        <p>
                          {currency_code}
                          {parseFloat(
                            orderDetails?.shipping_cost_inc_tax
                          ).toFixed(2)}{" "}
                          {currency_name}
                        </p>
                        <p>
                          <strong>
                            {currency_code}
                            {parseFloat(orderDetails?.total_inc_tax).toFixed(
                              2
                            )}{" "}
                            {currency_name}
                          </strong>
                        </p>
                        {isIGST ? (
                          <p>
                            {" "}
                            {currency_code}
                            {parseFloat(orderDetails?.total_tax).toFixed(
                              2
                            )}{" "}
                            {currency_name}
                          </p>
                        ) : (
                          <>
                            <p>
                              {" "}
                              {currency_code}
                              {parseFloat(orderDetails?.total_tax/2).toFixed(2)}{" "}
                              {currency_name}
                            </p>
                            <p>
                              {" "}
                              {currency_code}
                              {parseFloat(orderDetails?.total_tax/2).toFixed(2)}{" "}
                              {currency_name}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    {/* Amount in Words */}
                    {/* <div
                      style={{
                        border: "1px solid #000",
                        borderTop: "unset",
                        padding: "8px",
                      }}
                    >
                      <p style={{ marginBottom: "5px" }}>
                        <strong>Amount in Words:</strong>
                      </p>
                      <p>{word} only</p>
                    </div>
                    <div
                      style={{
                        border: "1px solid #000",
                        borderTop: "unset",
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        padding: "8px",
                      }}
                    >
                      <p style={{ marginBottom: "5px" }}>
                        <strong>For Appario Retail Private Ltd:</strong>
                      </p>
                      <p>Authorized Signatory</p>
                    </div> */}
                    {/* Footer */}
                    {/* <div style={{ paddingTop: "5px" }}>
                      <p className={styles.footerText}>
                        <strong>
                          Whether tax is payable under reverse charge:
                        </strong>{" "}
                        No
                      </p>
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Bill;
