import CardPayIcon from "../../../Svgs/CardPayIcon";
import CashOnDeliveryIcon from "../../../Svgs/CashOnDeliveryIcon";
import EMIIcon from "../../../Svgs/EMIIcon";
import NetBankingPayIcon from "../../../Svgs/NetBankingPayIcon";
import PayLaterIcon from "../../../Svgs/PayLaterIcon";
import RecommendedIcon from "../../../Svgs/RecommendedIcon";
import UPIPayIcon from "../../../Svgs/UPIPayIcon";
import WalletsIcon from "../../../Svgs/WalletsIcon";
import classes from "./Payments.module.css";

export const buttonData = [
        {
          id: 1,
          title: (
            <a
              className={`${classes.navLink} `}
              id="v-pills-recommended-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-recommended"
              aria-selected="true"
            >
              <RecommendedIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                Recommended
              </span>
            </a>
          ),
        },
        {
          id: 2,
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-cod-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-cod"
              aria-selected="false"
            >
              <CashOnDeliveryIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                Cash on delivery
              </span>
            </a>
          ),
        },
        {
          id: 3,
          paymentMethod:"upi",
          paymentTitle:"Pay via UPI",
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-upi-app-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-upi-app"
              aria-selected="false"
            >
              <UPIPayIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                UPI App
              </span>
            </a>
          )
        },
        {
          id: 4,
          paymentMethod:"card",
          paymentTitle:"Pay via Credit/Debit Card",
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-credit-debit-card-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-credit-debit-card"
              aria-selected="false"
            >
              <CardPayIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                Credit/Debit Card
              </span>
            </a>
          )
        },
        {
          id: 5,
          paymentMethod:"wallet",
          paymentTitle:"Pay via Wallet",
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-walltes-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-walltes"
              aria-selected="false"
            >
              <WalletsIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                Wallets
              </span>
            </a>
          )
        },
        {
          id: 6,
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-pay-later-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-pay-later"
              aria-selected="false"
            >
              <PayLaterIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                Pay Later
              </span>
            </a>
          )
        },
        {
          id: 7,
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-emi-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-emi"
              aria-selected="false"
            >
              <EMIIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                EMI
              </span>
            </a>
          )
        },
        {
          id: 8,
          paymentMethod:"netbanking",
          paymentTitle:"Pay via Net Banking",
          title: (
            <a
              className={`${classes.navLink} ${classes.shadow}`}
              id="v-pills-net-banking-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-net-banking"
              aria-selected="false"
            >
              <NetBankingPayIcon/>
              <span
                className={`${classes.fontWeightBold} ${classes.small} ${classes.textUppercase}`}
              >
                Net Banking
              </span>
            </a>
          )
        }
]

export const commonComingSoon = () => {
    return  <div
    className={`fade rounded bg-white active in show`}
    id="v-pills-recommended"
    role="tabpanel"
    aria-labelledby="v-pills-recommended-tab"
  >
    <div className={classes.codtab}>
      <p className={classes.heading}>
        
      </p>
      <ul className={classes.listing}>
        <li>
          <div className={classes.listingInnerDiv}>
            <div style={{width: '20vw'}} className={classes.extraCashForCod}>
              <p>
                Coming Soon!
              </p>
            </div>
            <div className={classes.inputCode}>
              <label className="" htmlFor="code">
              </label>
              <div className={classes.codHelptext}>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  }