import React from "react";

function AddressIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_62_83"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_62_83)">
        <path
          d="M24 33.1C27.465 30.3047 30.0812 27.6142 31.8487 25.0285C33.6162 22.4428 34.5 20.0333 34.5 17.8C34.5 15.8333 34.1417 14.1667 33.425 12.8C32.7083 11.4333 31.8249 10.3187 30.7746 9.456C29.7242 8.5933 28.5864 7.9683 27.361 7.581C26.1356 7.19367 25.0153 7 24 7C22.9847 7 21.8644 7.19367 20.639 7.581C19.4136 7.9683 18.2758 8.5933 17.2255 9.456C16.1752 10.3187 15.2917 11.4333 14.575 12.8C13.8583 14.1667 13.5 15.8333 13.5 17.8C13.5 20.0333 14.3837 22.4428 16.1512 25.0285C17.9187 27.6142 20.535 30.3047 24 33.1ZM24 36.9C19.4333 33.4667 16.0417 30.18 13.825 27.0401C11.6083 23.9001 10.5 20.8242 10.5 17.8124C10.5 15.5375 10.9083 13.5417 11.725 11.825C12.5417 10.1083 13.6 8.66667 14.9 7.5C16.2 6.33333 17.6497 5.45833 19.249 4.875C20.8483 4.29167 22.4317 4 23.999 4C25.5663 4 27.15 4.29167 28.75 4.875C30.35 5.45833 31.8 6.33333 33.1 7.5C34.4 8.66667 35.4583 10.1089 36.275 11.8267C37.0917 13.5444 37.5 15.5431 37.5 17.8228C37.5 20.8409 36.3917 23.9167 34.175 27.05C31.9583 30.1833 28.5667 33.4667 24 36.9ZM24.0044 21C24.9681 21 25.7917 20.6569 26.475 19.9706C27.1583 19.2843 27.5 18.4593 27.5 17.4956C27.5 16.5319 27.1569 15.7083 26.4706 15.025C25.7843 14.3417 24.9593 14 23.9956 14C23.0319 14 22.2083 14.3431 21.525 15.0294C20.8417 15.7157 20.5 16.5407 20.5 17.5044C20.5 18.4681 20.8431 19.2917 21.5294 19.975C22.2157 20.6583 23.0407 21 24.0044 21ZM10.5 44V41H37.5V44H10.5Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default AddressIcon;
