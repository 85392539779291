import React from 'react'
import Blogs from '../components/blogs/Blogs'
import BlogsHeader from '../components/blogs/BlogsHeader'


function AllBlogs() {
  return (
    <>
    <BlogsHeader/>
    <Blogs/>
    </>
  )
}

export default AllBlogs