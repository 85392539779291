import React from "react";
import classes from "./ProductPagination.module.css";
import ReactPaginate from "react-paginate";
import NextBtn from "../../Svgs/NextBtn";

function ProductPagination({ currentPage, totalPages, setCurrentPage }) {
  return (
    <div className={classes.paginationContainer}>
      <p className={classes.pageInfo}>
        Page {currentPage} of {totalPages}
      </p>
      <div className={classes.divider} />
      <div className={classes.buttonPanel}>
        <ReactPaginate
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          activeClassName={"activePage"}
          onPageChange={(event) => setCurrentPage(event.selected + 1)}
          pageCount={totalPages}
          breakLabel="..."
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          previousLabel={
            <div className={classes.prev}>
              <div style={{ transform: " rotate(180deg)" }}>
                <NextBtn />
              </div>
              Back
            </div>
          }
          nextLabel={
            <div className={classes.next}>
              Next <NextBtn />
            </div>
          }
        />
      </div>
    </div>
  );
}

export default ProductPagination;
