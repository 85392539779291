import React from "react";

function RecommendedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_36_109"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_36_109)">
        <path
          d="M15.3419 12.9589L21.2 5.35001C21.5667 4.88335 21.9931 4.53335 22.4794 4.30001C22.9656 4.06668 23.4739 3.95001 24.0044 3.95001C24.5348 3.95001 25.0417 4.06668 25.525 4.30001C26.0083 4.53335 26.4333 4.88335 26.8 5.35001L32.6582 12.9589L41.55 15.95C42.3167 16.2167 42.9167 16.6685 43.35 17.3056C43.7833 17.9426 44 18.6463 44 19.4167C44 19.7722 43.9495 20.1272 43.8484 20.4815C43.7472 20.8357 43.5811 21.1752 43.35 21.5L37.65 29.65L37.85 38.3C37.85 39.3333 37.5 40.2083 36.8 40.925C36.1 41.6417 35.2637 42 34.2912 42C34.2304 42 33.9167 41.95 33.35 41.85L24 39.25L14.6609 41.8462C14.487 41.9154 14.3082 41.9583 14.1245 41.975C13.9408 41.9917 13.7725 42 13.6194 42C12.6398 42 11.8083 41.6386 11.125 40.9157C10.4417 40.1928 10.1167 39.3042 10.15 38.25L10.35 29.5875L4.65 21.45C4.4189 21.1223 4.25278 20.7798 4.15165 20.4224C4.05055 20.0649 4 19.7074 4 19.35C4 18.5833 4.2153 17.8899 4.6459 17.2697C5.0765 16.6495 5.67787 16.2096 6.45 15.95L15.3419 12.9589ZM17.15 15.55L6.7 19.05L13.4 28.75L13.15 39.1L24 36.1L34.85 39.15L34.6 28.75L41.3 19.15L30.85 15.55L24 6.65001L17.15 15.55Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default RecommendedIcon;
