import { create } from "zustand";
import { persist } from "zustand/middleware";

export const addToCartCountStore = create(
    (set) => ({
        addToCartCount: 0,
        itemIdsinCart:[],
        setAddToCartCount: (payload) => set(() => ({ addToCartCount: payload })),
        setItemIdsinCart: (payload) => set(() => ({ itemIdsinCart: payload })),
    }),
    {
      name: "addToCartCount-storage", 
      getStorage: () => localStorage,
    }
);
