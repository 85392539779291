import React, { useState } from 'react'
import classes from './BlogDetails.module.css'
import { Container, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import BlogSlider from './BlogSlider';
import { Helmet } from 'react-helmet';

function BlogDetails() {
    const[blogDetails,setBlogDetails]=useState({});
    const {blog_id} = useParams()
    const [blogTags,setBlogTags] = useState([]);
    const [relatedBlogs,setRelatedBlogs] = useState([])

    const getBlogDetails = async(product_id)=>{
        await(axios.get(`${process.env.REACT_APP_API_PATH}blogDetails?blog_id=${blog_id}`)
            .then(res=>{
                setBlogDetails(res.data)
                setBlogTags(res.data.tags)

                axios.get(`${process.env.REACT_APP_API_PATH}relatedBlogs?tag=${res.data.tags[0]}`)
                .then(res=>{
                    setRelatedBlogs(res.data)
                })

            })
        )
    }
    useState(()=>{
        getBlogDetails()
    },[])

    var date = new Date(blogDetails.published_date_iso8601);
    var options = { year: 'numeric', month: 'long', day: '2-digit' };
    var formattedDate = date.toLocaleDateString('en-US', options);
  return (
    <div className='pt-4'>
      <Helmet>
        <title>{blogDetails?.title !== undefined && `Blog - ${blogDetails?.title} - Upvan Fashion`}</title>
        <meta name="description" content={blogDetails?.title !== undefined && `Blog - ${blogDetails?.title} - Upvan Fashion`} />
      </Helmet>
        <Container>
        <Row>
            <div className={classes.blog_inner_page_content_section}>
                <div className={classes.blog_detail_banner_section}>
                    <img src={`https://store-cv6fgg2tx1.mybigcommerce.com${blogDetails.thumbnail_path}`} alt='blog header'/>
                    <div className={classes.blogdetail_header_section}>
                        <h1>{blogDetails.title}</h1>
                        <div className={classes.title_and_date}>
                            <p className={classes.title}>Spotlight</p>
                            <div className={classes.card_date}>
                                <p>{formattedDate}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.blogdetail_content}>
                    <p>{blogDetails.summary}</p>
                </div>
                <div className={classes.blogdetail_tag_section}>
                    {
                        blogTags.map((item,index)=>{
                            return(
                                <Link key={index} href="#"><p>{item}</p></Link>
                            )
                        })
                    }
                </div>
            </div>
            <h3 className='mb-4'>Related Blogs</h3>
            <BlogSlider
                relatedBlogs = {relatedBlogs}
            />
        </Row>
    </Container>
    </div>
  )
}

export default BlogDetails