import React, { Fragment, useEffect } from 'react'
import classes from './Cart.module.css'
import ShoppingBag from '../../components/cart/CartSteps/Cart'
import { Helmet } from 'react-helmet';
import { getUserId } from '../../utils/getUserId';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginPath } from '../../constant/constant';

function Cart() {
 const userId = getUserId()
 const location = useLocation()
 const navigate = useNavigate()
  
  useEffect(()=>{
    if(isNaN(userId)){
      navigate(LoginPath,{state:{from: location.pathname + location.search}});
      localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
    }
 },[userId])

  return (
    <Fragment>
      <Helmet>
        <title>Shopping cart - Upvan Fashion</title>
        <meta name="description" content="Shopping cart - Upvan Fashion" />
      </Helmet>
      <div className={classes.container}>
        <div className={classes.cartWrapper}>
          <ShoppingBag  />
        </div>
      </div>
    </Fragment>
  );
}

export default Cart;
