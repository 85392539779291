import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

function HelpFaq({ data }) {
  const [activeHelpFaqTab, setActiveHelpFaqTab] = useState(1);
  return (
    <Accordion
      defaultActiveKey={JSON.stringify(activeHelpFaqTab)}
      activeKey={JSON.stringify(activeHelpFaqTab)}
      className="helpFaqAccordion"
    >
      {data &&
        data?.map((item, index) => (
          <Accordion.Item eventKey={JSON.stringify(index + 1)} key={index}>
            <Accordion.Header onClick={(e) => setActiveHelpFaqTab(index + 1)}>
              {item.title}
            </Accordion.Header>
            <Accordion.Body>{item.description}</Accordion.Body>
          </Accordion.Item>
        ))}
    </Accordion>
  );
}

export default HelpFaq;
