import React from "react";
import classes from "./SearchModal.module.css";
import { Modal } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";

function SearchModal({
  handleClose,
  setSearch,
  search,
  debounceSearch,
}) {
  return (
    <Modal
      show={true}
      className={`${classes.modal} searchModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={() => {
        if (window.location.pathname === "/products" && search == "") {
          debounceSearch(search, "products-page");
        }
        handleClose();
      }}
    >
      <div className={classes.searchModalWrapper}>
        <div className={classes.searchInputWrapper}>
          <span className={classes.iconBtn}>
            <CiSearch />
          </span>
          <input
            type="text"
            value={search}
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button
          className={classes.searchBtn}
          onClick={() => {
            window.location.pathname !== "/products"
              ? debounceSearch(search, "any-page")
              : debounceSearch(search, "products-page");
            handleClose();
          }}
        >
          Search
        </button>
        <button
          className={classes.closeBtn}
          onClick={() => {
            if (window.location.pathname === "/products" && search == "") {
              debounceSearch(search, "products-page");
            }
            handleClose();
          }}
          tabIndex={0}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </Modal>
  );
}

export default SearchModal;
