import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import classes2 from "./CouponsPage.module.css";
import classes from "../../profile/ProfileDetailsTab/EditProfileModal/EditProfileModal.module.css";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import axios from "axios";

function ApplyCouponModal({ setCloseCoupon, couponCodeList, cartDetails, setCartDetails, setLoading, getCartDetails, setAppliedCoupon }) {
const [couponCode, setCouponCode] = useState('')
const [savings, setSavings] = useState('0')
  const handleClose = () => {
    setCloseCoupon();
  };

  const handleChange = (e) => {
    console.log(e.target.value, e.target.checked)
    let fetchCoupon = couponCodeList.find(v => v.code == e.target.value)
    setCouponCode(e.target.value)
    setSavings(Math.round(fetchCoupon.amount))
  }

  const handleSubmit = (values) => {
    setLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_PATH}applyCoupon`, {
          coupon_code: couponCode,
          checkoutId: cartDetails.id,
        })
        .then((res) => {
        if(res.data.status === 400){
          showErrorToast(res.data.title)
          setLoading(false)
         } else {
          showSuccessToast('Coupon Applied Successfully!' )
          getCartDetails()
         setLoading(false)
         setAppliedCoupon(true)
         handleClose()
         }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        });
  };

  return (
    <Modal
      show={true}
      backdrop={false}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div style={{height: '99vh', overflow: 'auto' }} className={classes.modalContent}>
        <div>
          <div style={{ position: "relative" }}>
            <h5 className={classes.modalHeader}>Apply Coupon Code</h5>
            <button
              className={classes.closeBtn}
              onClick={handleClose}
              tabIndex={0}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            style={{ marginBottom: "1rem" }}
            className={classes2.couponCodeList}
          >
            {couponCodeList.map((value, index) => (
              <div className={classes2.couponcodesection}>
                <input style={{ accentColor: "#eb2675"}} id={index} value={value.code} checked={value.code === couponCode} onChange={handleChange} type="radio" />
                <div classNme={classes2.copucodetextsection}>
                  <p className={classes2.copuncodetextwithborder}>
                    {value.code}
                  </p>
                  <p className={classes2.saveMoneyText}>
                    <b>Save {value.type === "percentage_discount" ? ` ${Math.floor(value.amount)} %`:`₹${Math.floor(value.amount)}`}</b>
                  </p>
                  <p>
                    {value.type === "per_item_discount"
                      ? `Rs.${Math.floor(
                          value.amount
                        )} off on selected Product on minimum purchase of Rs.${Math.floor(
                          value.min_purchase
                        )}.`
                      : value.type === "per_total_discount"
                      ? `Rs.${Math.floor(
                          value.amount
                        )} off on Total.`
                      : value.type === "percentage_discount"
                      ? `${Math.floor(
                          value.amount
                        )}% off on Total.` : ''}
                  </p>
                  <p>
                    Expires on: {new Date(value.expires).toLocaleDateString()} |{" "}
                    {new Date(value.expires).toLocaleTimeString()}.
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.modalFooter}>
            <div className={classes2.maximumSavingContent}>
              <p>Maximum savings:</p>
              <p>
                <b>₹{savings}</b>
              </p>
            </div>
            <button className={classes.primaryBtn} onClick={handleSubmit}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ApplyCouponModal;
