import React, { useEffect, useState } from "react";
import classes from "./AddAddressModal.module.css";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik, Form, Field } from "formik";
import { addressValidationSchema } from "../../../CommonComponents/ValidationSchema";
import ErrorMessage from "../../../errorMessage/ErrorMessage";
import axios from "axios";
import _, { set, values } from "lodash";
import { getUserId } from "../../../../utils/getUserId";
import { showErrorToast, showSuccessToast } from "../../../../utils/toastUtils";
import { Country, State, City }  from 'country-state-city';
import Select from 'react-select';

const addressTypes = ["residential", "commercial"];

const countryList = [
  { values: "IN", label: "India" },
  { values: "US", label: "United States" },
];

function AddAddressModal({ handleClose, address }) {
  const userId = getUserId();
  const [loading, setLoading] = useState(false);
  const [selectedCountry,setSelectedCountry]=useState(countryList.find((item=> address?.country == item.label)) ?? countryList[0])
  const [states,setStates] = useState([])
  const [selectedState, setSelectedState] = useState(states.find((item)=>address?.state_or_province == item.label) ?? null);

  const initialValues = {
    first_name: address?.first_name ?? "",
    last_name: address?.last_name ?? "",
    city: address?.city ?? "",
    country_code: address?.country_code ?? "",
    address1: address?.address1 ?? "",
    address2: address?.address2 ?? "",
    state_or_province: address?.state_or_province ?? "",
    postal_code: address?.postal_code ?? "",
    address_type: address?.address_type ?? "residential",
    phone: address?.phone ?? "",
    country:selectedCountry ?? ""
  };
  useEffect(()=>{
    let data = State.getStatesOfCountry(selectedCountry?.values)
    let newData= data.map((item)=>({value:item.countryCode,label:item.name}))
    setStates(newData)
    setSelectedState(newData[0])
  },[selectedCountry])
  
  useEffect(()=>{
    const selectedOption = states.find((state) => state.label === address?.state_or_province);
    let data =selectedOption !== undefined ? selectedOption : states[0] 
    setSelectedState(data);
  },[address,states])


  const handleSubmit = async (values, setSubmitting) => {
    setLoading(true);
    values.postal_code = values.postal_code.toString();
    values.country_code = selectedCountry.values;
    values.state_or_province = values.state_or_province.label
    values.country = selectedCountry.label
    if (_.isEmpty(address)) {
      await axios
        .post(`${process.env.REACT_APP_API_PATH}address`, {
          ...values,
          customer_id: userId,
        })
        .then((res) => {
          setLoading(false);
          // showSuccessToast("Address Added Successfully!");
          handleClose();
        })
        .catch((err) => {
          console.log({ err });
          setLoading(false);
          showErrorToast("Something wet wrong!");
        });
    } else {
      await axios
        .post(`${process.env.REACT_APP_API_PATH}updateAddress`, {
          ...values,
          id: address.id,
        })
        .then((res) => {
          console.log({ res });
          setLoading(false);
          // showSuccessToast("Address Added Successfully!");
          handleClose();
        })
        .catch((err) => {
          setLoading(false);
          showErrorToast("Something went wrong!");
          console.log({ err });
        });
    }
    setSubmitting(false);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border:"1px solid #9d9a9b",
      borderRadius:"unset",
      backgroundColor:"#f4f5f6 !important",
      color:"#7a8081 !important"
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      color:"#7a8081 !important"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: '#7a8081',
    }),
  };
  return (
    <Modal
      show={true}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div className={classes.modalContent}>
        <Formik
          initialValues={initialValues}
          validationSchema={addressValidationSchema}
          onSubmit={async (values, { setSubmitting }) =>{
            handleSubmit(values, setSubmitting)
          }}
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form>
                <div className={classes.modalHeaderWrapper}>
                  <h5 className={classes.modalHeader}>
                    {_.isEmpty(address) ? "Add new Address" : "Edit Address"}
                  </h5>
                  <button
                    className={classes.closeBtn}
                    onClick={handleClose}
                    tabIndex={0}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className={classes.addressFormUI}>
                  <div className={classes.addressFormUIDiv}>
                    <div className={classes.addressFormUIHeader}>
                      CONTACT DETAILS
                    </div>
                    <div className={classes.addressList}>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>
                          First Name*
                        </label>
                        <Field
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                        />
                        {errors.first_name && touched.first_name && (
                          <ErrorMessage message={errors.first_name} />
                        )}
                      </div>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>Last Name*</label>
                        <Field
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                        />
                        {errors.last_name && touched.last_name && (
                          <ErrorMessage message={errors.last_name} />
                        )}
                      </div>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>Mobile No*</label>
                        <PhoneInput
                          name="phone"
                          value={values.phone}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          country={"in"}
                          onChange={(phone, data) => {
                            setFieldValue("phone", phone);
                            setFieldValue("country_code", data.countryCode);
                          }}
                        />
                        {errors.phone && touched.phone && (
                          <ErrorMessage message={errors.phone} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.addressFormUIDiv}>
                    <div className={classes.addressFormUIHeader}>ADDRESS</div>
                    <div className={classes.addressListremoveMargin}>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>Country</label>
                        <Select
                          value={selectedCountry}
                          onChange={(option) => {
                            setSelectedCountry(option);
                            setFieldValue("country",option)
                            setFieldValue("postal_code", "");
                            setFieldValue("state_or_province", {});
                          }}
                          options={countryList}
                          styles={customStyles}
                          defaultValue={selectedCountry}
                        />
                      </div>
                      <div className={classes.inputWrapper}>
                        <label className={classes.inputLabel}>
                          Address (House No, Building, Street, Area)*
                        </label>
                        <Field
                          type="text"
                          placeholder="address"
                          name="address1"
                        />
                        {errors.address1 && touched.address1 && (
                          <ErrorMessage message={errors.address1} />
                        )}
                      </div>
                      <div className={classes.pincodeAndLocality}>
                        <div className={classes.addressFormHalfDiv}>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>State*</label>
                            <Select
                              value={selectedState}
                              onChange={(option) => {
                                setSelectedState(option);
                                setFieldValue("state_or_province", option);
                              }}
                              options={states}
                              styles={customStyles}
                              defaultValue={selectedState}
                            />
                            {errors.state_or_province &&
                              touched.state_or_province && (
                                <ErrorMessage
                                  message={errors.state_or_province}
                                />
                              )}
                          </div>
                        </div>
                        <div className={classes.addressFormHalfDiv}>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>
                              City / District*
                            </label>
                            <Field type="text" placeholder="City" name="city" />
                            {errors.city && touched.city && (
                              <ErrorMessage message={errors.city} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.pincodeAndLocality}>
                        <div className={classes.addressFormHalfDiv}>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>
                              Pin Code*
                            </label>
                            <Field
                              type="text"
                              placeholder="000000"
                              name="postal_code"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  selectedCountry.values == "IN"
                                    ? /^\d{0,6}$/.test(inputValue)
                                    : /^\d{0,5}$/.test(inputValue)
                                ) {
                                  setFieldValue("postal_code", inputValue);
                                }
                              }}
                            />
                            {errors.postal_code && touched.postal_code && (
                              <ErrorMessage message={errors.postal_code} />
                            )}
                          </div>
                        </div>
                        <div className={classes.addressFormHalfDiv}>
                          <div className={classes.inputWrapper}>
                            <label className={classes.inputLabel}>
                              Locality / Town*
                            </label>
                            <Field
                              type="text"
                              placeholder="Locality"
                              name="address2"
                            />
                            {errors.address2 && touched.address2 && (
                              <ErrorMessage message={errors.address2} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.addressFormUIDiv}>
                    <div className={classes.addressFormUIHeader}>
                      SAVE ADDRESS AS
                    </div>
                    <div className={classes.addressListremoveMargin}>
                      <div className={classes.addressTypesWrapper}>
                        {addressTypes.map((item, index) => (
                          <div
                            className={classes.addressTypeIcon}
                            style={{
                              border:
                                values.address_type == item
                                  ? "1px solid #ff3f6c"
                                  : "1px solid #282c3f",
                              color:
                                values.address_type == item
                                  ? "#ff3f6c"
                                  : "#333",
                              fontWeight:
                                values.address_type == item ? 700 : 400,
                            }}
                            key={index}
                            onClick={() => setFieldValue("address_type", item)}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <button
                    className={classes.secondaryBtn}
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    disabled={loading}
                    type="submit"
                    className={classes.primaryBtn}
                  >
                    Save changes
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

export default AddAddressModal;
