import React from "react";

function DeleteAccountIcon() {
  return (
    <svg
      width="180"
      height="180"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.2976 1.59546V46.9148C49.638 46.9148 58.8296 37.7232 58.8296 26.3828V22.1275C58.8296 10.7892 49.6382 1.59546 38.2976 1.59546Z"
        fill="#FFC1A6"
      />
      <path
        d="M38.2977 1.59546C46.1125 1.59546 52.4467 10.789 52.4467 22.1275V26.3828C52.4467 37.7232 46.1127 46.9148 38.2977 46.9148C26.9572 46.9148 17.7656 37.7232 17.7656 26.3828V22.1275C17.7658 10.7892 26.9572 1.59546 38.2977 1.59546Z"
        fill="#FFDBCC"
      />
      <path
        d="M38.6614 53.1936L66.4891 87.7661H72.8721C72.8723 68.7915 57.5891 53.3893 38.6614 53.1936Z"
        fill="#5A5254"
      />
      <path
        d="M38.6615 53.1936C54.0637 53.434 66.4893 68.8191 66.4893 87.766H3.72327C3.72327 68.6703 19.202 53.1914 38.2979 53.1914C38.419 53.1914 38.5403 53.1914 38.6615 53.1936Z"
        fill="#877E80"
      />
      <path
        d="M42.5531 53.4509L38.2979 65.4978C44.1723 65.4978 48.934 60.7382 48.9361 54.8617C46.8848 54.1978 44.751 53.721 42.5531 53.4509Z"
        fill="#FFC1A6"
      />
      <path
        d="M42.5531 53.451V54.8596C42.5531 60.7361 40.6488 65.4979 38.2978 65.4979C32.4234 65.4979 27.6595 60.7361 27.6595 54.8596C31.0105 53.7766 34.5851 53.1914 38.2978 53.1914C39.7381 53.1914 41.1572 53.2787 42.5531 53.451Z"
        fill="white"
      />
      <path
        d="M75 55.851V98.4043C86.7512 98.4043 96.2766 88.8787 96.2766 77.1277C96.2766 65.3767 86.7508 55.851 75 55.851Z"
        fill="#F379AA"
      />
      <path
        d="M75 55.851C83.2255 55.851 89.8935 65.3765 89.8935 77.1275C89.8935 88.8785 83.2255 98.4043 75 98.4043C63.2488 98.4043 53.7234 88.8787 53.7234 77.1277C53.7234 65.3767 63.2488 55.851 75 55.851Z"
        fill="#EB2675"
      />
      <path
        d="M84.779 82.3933L79.5131 77.1275L84.779 71.8617C86.0254 70.6152 86.0254 68.5947 84.779 67.3482C83.5325 66.1017 81.512 66.1017 80.2655 67.3482L75 72.6142L69.7342 67.3484C68.4877 66.1021 66.4672 66.1021 65.2208 67.3484C63.9743 68.5949 63.9743 70.6154 65.2208 71.8619L70.4866 77.1277L65.2208 82.3935C63.9743 83.64 63.9743 85.6605 65.2208 86.907C66.4672 88.1535 68.4877 88.1535 69.7342 86.907L75 81.641L80.2659 86.9068C81.5124 88.1533 83.5329 88.1533 84.7793 86.9068C86.0256 85.6603 86.0252 83.6398 84.779 82.3933Z"
        fill="white"
      />
      <path
        d="M46.1702 25.6775C47.0514 25.6775 47.7659 24.963 47.7659 24.0818V20.8904C47.7659 20.0091 47.0514 19.2947 46.1702 19.2947C45.2889 19.2947 44.5745 20.0091 44.5745 20.8904V24.0818C44.5745 24.963 45.2887 25.6775 46.1702 25.6775Z"
        fill="black"
      />
      <path
        d="M30.4254 25.6775C31.3067 25.6775 32.0211 24.963 32.0211 24.0818V20.8904C32.0211 20.0091 31.3067 19.2947 30.4254 19.2947C29.5442 19.2947 28.8297 20.0091 28.8297 20.8904V24.0818C28.8297 24.963 29.544 25.6775 30.4254 25.6775Z"
        fill="black"
      />
      <path
        d="M34.7062 35.5485C35.5596 35.7694 36.4303 35.2565 36.651 34.4036C36.8459 33.6504 37.523 33.1245 38.2977 33.1245C39.0723 33.1245 39.7494 33.6504 39.9443 34.4036C40.1305 35.1227 40.7781 35.5999 41.4879 35.5999C41.6203 35.5999 41.7547 35.5833 41.8889 35.5485C42.742 35.3276 43.2547 34.4571 43.0338 33.6038C42.4744 31.4424 40.5268 29.9329 38.2975 29.9329C36.0682 29.9329 34.1205 31.4424 33.5611 33.6038C33.3404 34.4569 33.8531 35.3278 34.7062 35.5485Z"
        fill="black"
      />
      <path
        d="M49.4689 86.1699H5.35698C5.99136 72.9185 14.4847 61.6941 26.2658 57.0613C27.306 62.7595 32.3035 67.0943 38.2978 67.0943C44.2925 67.0943 49.2904 62.7589 50.33 57.0597C51.7837 57.6285 53.1992 58.2974 54.5607 59.0707C55.3269 59.506 56.3011 59.2374 56.7365 58.4712C57.1718 57.705 56.9035 56.7308 56.1371 56.2955C50.7238 53.2207 44.555 51.5955 38.298 51.5955C18.3537 51.5955 2.12769 67.8214 2.12769 87.7658C2.12769 88.647 2.84214 89.3615 3.72339 89.3615H49.4693C50.3505 89.3615 51.065 88.647 51.065 87.7658C51.065 86.8845 50.3503 86.1699 49.4689 86.1699ZM47.2621 56.0302C46.6861 60.4652 42.8869 63.9025 38.2978 63.9025C33.7074 63.9025 29.9076 60.4634 29.3332 56.0267C32.1849 55.2201 35.1916 54.7867 38.2978 54.7867C41.34 54.7869 44.3582 55.2123 47.2621 56.0302Z"
        fill="black"
      />
      <path
        d="M38.2977 48.5107C50.4991 48.5107 60.4254 38.5844 60.4254 26.383V22.1277C60.4254 9.92637 50.4989 0 38.2977 0C26.0965 0 16.1702 9.92637 16.1702 22.1277V26.383C16.1702 38.5844 26.0965 48.5107 38.2977 48.5107ZM19.3616 22.1277C19.3616 11.6861 27.8563 3.1916 38.2977 3.1916C48.7391 3.1916 57.2338 11.6863 57.2338 22.1277V26.383C57.2338 36.8246 48.7391 45.3191 38.2977 45.3191C27.8563 45.3191 19.3616 36.8244 19.3616 26.383V22.1277Z"
        fill="black"
      />
      <path
        d="M75 54.2552C62.3881 54.2552 52.1276 64.5156 52.1276 77.1277C52.1276 89.7398 62.3881 100 75 100C87.6119 100 97.8725 89.7396 97.8725 77.1275C97.8725 64.5154 87.6117 54.2552 75 54.2552ZM75 96.8086C64.1479 96.8086 55.3192 87.9799 55.3192 77.1277C55.3192 66.2756 64.1479 57.4467 75 57.4467C85.8522 57.4467 94.6809 66.2754 94.6809 77.1275C94.6809 87.9797 85.852 96.8086 75 96.8086Z"
        fill="black"
      />
      <path
        d="M87.3095 69.6053C87.3095 68.3266 86.8117 67.1244 85.9074 66.2201C85.0031 65.3158 83.8009 64.818 82.5222 64.818C81.2435 64.818 80.0414 65.3158 79.1371 66.2201L75 70.3574L70.8626 66.2201C69.9583 65.3158 68.7562 64.818 67.4775 64.818C66.1988 64.818 64.9964 65.3158 64.0923 66.2201C63.188 67.1244 62.6902 68.3266 62.6902 69.6053C62.6902 70.884 63.1882 72.0863 64.0923 72.9905L68.2296 77.1279L64.0923 81.2653C62.2257 83.1319 62.2257 86.169 64.0923 88.0356C64.9966 88.9399 66.1988 89.4377 67.4775 89.4377C68.7562 89.4377 69.9583 88.9399 70.8626 88.0356L75 83.8981L79.1373 88.0354C80.0416 88.9397 81.2437 89.4375 82.5224 89.4375C83.8011 89.4375 85.0035 88.9397 85.9076 88.0354C87.7742 86.1688 87.7742 83.1317 85.9076 81.2651L81.7703 77.1278L85.9076 72.9905C86.8115 72.0862 87.3095 70.884 87.3095 69.6053ZM83.6505 70.7336L78.3847 75.9994C77.7615 76.6227 77.7615 77.633 78.3847 78.2561L83.6505 83.5219C84.2726 84.144 84.2726 85.1567 83.6505 85.7787C83.349 86.0803 82.9484 86.2461 82.522 86.2461C82.0958 86.2461 81.6951 86.0801 81.3937 85.7787L76.1279 80.5129C75.8287 80.2135 75.4228 80.0455 74.9996 80.0455C74.5763 80.0455 74.1705 80.2137 73.8712 80.5129L68.6054 85.7787C68.3039 86.0803 67.9033 86.2461 67.4771 86.2461C67.0509 86.2461 66.6501 86.0801 66.3486 85.7787C65.7265 85.1567 65.7265 84.144 66.3486 83.5219L71.6144 78.2561C72.2376 77.6328 72.2376 76.6225 71.6144 75.9994L66.3486 70.7334C66.047 70.4321 65.8812 70.0315 65.8812 69.6051C65.8812 69.1789 66.0472 68.7781 66.3486 68.4768C66.6501 68.1754 67.0507 68.0094 67.4771 68.0094C67.9033 68.0094 68.3041 68.1754 68.6054 68.4768L73.8712 73.7426C74.4945 74.3658 75.5048 74.3658 76.1279 73.7426L81.3937 68.4768C81.6953 68.1752 82.0958 68.0094 82.522 68.0094C82.9482 68.0094 83.349 68.1754 83.6505 68.4768C83.9521 68.7781 84.1179 69.1789 84.1179 69.6051C84.1179 70.0315 83.9521 70.4321 83.6505 70.7336Z"
        fill="black"
      />
    </svg>
  );
}

export default DeleteAccountIcon;
