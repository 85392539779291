import "./App.css";
import { Route, Routes } from "react-router-dom";
import NavbarMenu from "./components/navbar/NavbarMenu";
import Footer from "./components/footer/Footer";
import MainContext from "./contexts/MainContext";
import { RouteConstant } from "./constant/constant";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { scrollToTop } from "./components/CommonComponents/CommonHelperFunctions";
import { IoChevronUp } from "react-icons/io5";
import { useEffect, useState } from "react";

function App() {
  const contextValue = {};
  const [showScrollBtn, setShowScrollBtn] = useState(false)

  const checkScrollPosition = () => {
    const scrollY = window.scrollY;
    if (scrollY === 0) {
      setShowScrollBtn(false)
    } else {
      setShowScrollBtn(true)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <MainContext.Provider value={contextValue}>
        <NavbarMenu />
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <Routes>
          {RouteConstant.map((item, index) => (
            <Route path={item.path} element={item.component} key={index} />
          ))}
        </Routes>
        <Footer />
        {showScrollBtn && (
          <div className="scrollUpBtn" onClick={() => scrollToTop()}>
            <IoChevronUp />
          </div>
        )}
      </MainContext.Provider>
    </div>
  );
}

export default App;
