import React from "react";
import "./VTlist.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
function VTlist(props) {
  const Clicked = () => {
    props.onClick(props.tab);
  };

  return (
    <li key={props.index} style={{ listStyle: "none", textAlign: "left" }}>
      {props.activeTabId === props.tab ? (
        <div className="productDescriptionTabsWrapper">
          <button
            className="Tabs Tabs-Active"
            onClick={Clicked}
            style={
              props.activeTabId === props.tab
                ? { color: "#fff", background: "#eb267 !impotant" }
                : { color: "#8892b0" }
            }
          >
            {props.tab}
          </button>
          <div className="productDescriptionArrow">
            <MdOutlineKeyboardArrowRight />
          </div>
        </div>
      ) : (
        <button
          className="Tabs"
          onClick={Clicked}
          style={
            props.activeTabId === props.index
              ? { color: "#fff", background: "#eb267 !impotant" }
              : { color: "#8892b0" }
          }
        >
          {props.tab}
        </button>
      )}
    </li>
  );
}

export default VTlist;
