import React from "react";
import { Container, Row } from "react-bootstrap";
import classes from "./BreadCrumb.module.css";
import { Link, useNavigate } from "react-router-dom";
function BreadCrumb({ breadcrumbData, productItem }) {
  const navigate = useNavigate();
  return (
    <div className={`${classes.breadcrumbBg} breadcrumbWrapper`}>
      <Container className={classes.rowWrapper}>
        <Row >
          <div className={classes.breadcrumbsAndTittle}>
            <div className={classes.rowBase}>
              <div className={classes.breadcrumbsBase}>
                <div className={classes.woocommerceBreadcrumbWrapper}>
                  <div className={classes.woocommerceBreadcrumb}>
                    {breadcrumbData?.map((item, index) => (
                      <Link to={item.breadcrumbsURL && item.breadcrumbsURL}
                        className={classes.breadcrumbsText}
                        key={index}
                        style={{
                          color: item.breadcrumbsURL == "" && "#000",
                          fontWeight: item.breadcrumbsURL == "" && 600,
                        }}
                      >
                        {item.breadcrumbsText}
                      </Link>
                    ))}
                  </div>
                  {productItem !== undefined && productItem !== 0 && (
                    <p className={classes.productItem}>
                      ( {productItem} items )
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default BreadCrumb;
