import React, { Component, useEffect, useState } from "react";
import { userDetailsStore } from "../../../store/GlobalUserData";
import axios from "axios";
import classes from "./CouponsPage.module.css";
import { CouponIcon } from '../../../Svgs/CouponIcon'
import { ProductsPath } from "../../../constant/constant";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";

export default function CouponsPage() {
  const [loading, setLoading] = useState(false)
  const { userDetails } = userDetailsStore();
  const [couponList, setCouponsList] = useState([]);
  const navigate = useNavigate()

  const getCouponsData = async () => {
    setLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}getAllCoupons?checkoutId=${userDetails?.notes}`
      )
      .then((res) => {
        setCouponsList(res.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  };

  useEffect(() => {
    getCouponsData();
  }, []);
  console.log(couponList);
  return (
    <div className={classes.tabcontent}>
      {loading && <Loader/>}
    <div className={`${classes.orderList} ${classes.couponList}`}>
      <div className={classes.copunListSection}>
        {couponList.map((value, i) => {
          return (
            <div className={classes.list}>
              <div className={classes.couponContentSection}>
                {/* <img
                  src={"./assets/images/fashion/001.png"}
                  className={classes.couponContentImage}
                /> */}
                <div className={classes.couponContent}>
                  <CouponIcon />
                  <p className={classes.flatDiscountText}>
                    <b>{value.name}</b>
                  </p>
                  <p className={classes.couponCodeText}>
                    Coupon: <b>{value.code}</b>
                    <i style={{cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(value.code)} className="pe-7s-copy-file"></i>
                  </p>
                  <p className={classes.expiryText}>Expiry: {value.expires.length === 0 ? "Never" : new Date(value.expires).toLocaleString()}</p>
                  <a onClick={() => navigate(ProductsPath, {state:value.applies_to})}>
                    View Products <i className="pe-7s-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
}
