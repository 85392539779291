import axios from "axios";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };

    document.body.appendChild(script);
  });
};

export const createRazorpayOrder = (
  amount,
  currency = "INR",
  paymentMethod,
  paymentTitle,
  userDetails,
  address,
  cartDetails,
  line_items,
  setUserDetails,
  setAddToCartCount,
  setLoading,
  setActiveTab
) => {
  let data = JSON.stringify({
    amount: Math.round(amount) * 100,
    currency: currency,
  });
 console.log({amount});
 
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_PATH}createRazorpayOrder`,
    headers: {
      "Content-type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      razorpayPopUp(
        response.data.amount,
        response.data.order_id,
        currency,
        paymentMethod,
        paymentTitle,
        userDetails,
        address,
        cartDetails,
        line_items,
        setUserDetails,
        setAddToCartCount,
        setLoading,
        setActiveTab
      );
    })
    .catch((error) => console.log(error));
};

const razorpayPopUp = async (
  amount,
  order_id,
  currency,
  paymentMethod,
  paymentTitle,
  userDetails,
  address,
  cartDetails,
  line_items,
  setUserDetails,
  setAddToCartCount,
  setLoading,
  setActiveTab
) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  if (!res) {
    alert("reazorpay load error");
    return;
  }
  
  setLoading(false)
  const options = {
    key: "rzp_test_saAViII8ACKaaE", // Replace with your Razorpay key_id
    amount: Math.round(amount), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: currency,
    name: "Upvan",
    description: "Test Transaction",
    order_id: order_id, // This is the order_id created in the backend
    callback_url: "http://localhost:3000/", // Your success URL
    image: "https://u02.perigeon.com/images/upvan-icon.png",
    handler: function (response) {
      let redirect_url;
      if (
        typeof response.razorpay_payment_id == "undefined" ||
        response.razorpay_payment_id < 1
      ) {
        console.log("payment failed");
      } else {
       setLoading(true)
       axios
        .post(`${process.env.REACT_APP_API_PATH}createConsignment`, {
          address: address,
          line_items: line_items,
          cartId: cartDetails?.id,
        })
        .then((res) => {
          setUserDetails(res?.data[0])
          setAddToCartCount(0)
          redirect_url = "/profile?activeTab=2"
          window.location.href = redirect_url;
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        });
        // axios
        //   .post(`${process.env.REACT_APP_API_PATH}createOrder`, {
        //     address,
        //     products,
        //     selectedCartProduct,
        //     addToCartCount,
        //   })
        //   .then((res) => {
        //     if (
        //       Array.isArray(res?.data[0]?.data) &&
        //       res.data[0].data[0] !== undefined
        //     ) {
        //       setUserDetails(res.data[0].data[0]);
        //     }
        //     const lastCartCount = res.data[res.data.length - 1]?.cartCount;
        //     setAddToCartCount(lastCartCount || 0);
        //     redirect_url = "/profile?activeTab=2"
        //     window.location.href = redirect_url;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
    },
    prefill: {
      name: userDetails?.first_name + userDetails?.last_name,
      email: userDetails?.email,
      contact: userDetails?.phone,
    },
    theme: {
      color: "#eb2675",
    },
    modal: {
      ondismiss: function () {
        console.log("Payment cancelled");
        document.body.style.overflow = "auto";
        setActiveTab(2)
      },
    },
    // config: {
    //   display: {
    //     blocks: {
    //       banks: {
    //         name: paymentTitle,
    //         instruments: [
    //           {
    //             method: paymentMethod,
    //           },
    //         ],
    //       },
    //     },
    //     sequence: ["block.banks"],
    //     preferences: {
    //       show_default_blocks: false,
    //     },
    //   },
    // },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
