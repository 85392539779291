import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useMobileScreen } from "../../utils/isMobileScreen";
import './ImageSlider.css'
import ZoomableImage from "./ZoomableImage";

function ImageSlider({ images }) {
    let screen = useMobileScreen()

    const renderItem = (item) => (
      <ZoomableImage original={item.original} />
    );
    
    
  return (
    <ImageGallery
      items={images}
      renderItem={renderItem} 
      lazyLoad={true}
      showFullscreenButton={false}
      showPlayButton={false}
      showThumbnails={screen ? false :true}
      showNav={true}
    />
  );
}

export default ImageSlider;
