import React, { Fragment } from "react";
import { Container, Row, Col, Figure, Button, Modal } from "react-bootstrap";
import classes from "./aboutus.module.css";
import leader1 from "./leader1.jpg";
import leader2 from "./leader2.jpg";
import leader3 from "./leader3.jpg";
import founder from "./founder-img.jpg";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <Fragment>
       <Helmet>
        <title>About - Upvan Fashion</title>
        <meta name="description" content="About - Upvan Fashion" />
      </Helmet>
      <div className={`${classes.mainBanner} ${classes.bannerScroll}`}>
        <div className={`${classes.fixedBanner} ${classes.aboutBanner}`}>
          <div className={`${classes.bannerContent}`}>
            <div
              style={{ marginBottom: "0px" }}
              className={`${classes.contentWrap} ${classes.bannerOverlay}`}
            >
              <div className={`${classes.inner}`}>
                <Container>
                  <Row></Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className={`main ${classes.primaryPadding} ${classes.aboutPage}`}>
        <section
          className={`${classes.aboutTop} ${classes.pPb}`}
          style={{ marginTop: "5px" }}
        >
          <Container>
            <Row>
              <Col md={6} sm={6} xs={12} className={`${classes.leftBlock}`}>
                <h2 className={`text-uppercase ${classes.fontWt300}`}>
                  about the company
                </h2>
                <p>
                  We are a fashion ecommerce company. That’s likely
                  over-simplified, lorem ipsum is a dummy text and we use in all
                  themes. We sell trendy fashion items online globally, a
                  collaborative explosion of creativity fueled by a community of
                  amazing developers, and designers. We use the word “fun” a lot
                  in these company bios, so come be our friend and see if we It
                  was popularised in the 1960s.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic.
                </p>
              </Col>

              <Col md={6} sm={6} xs={12} className={`${classes.rightBlock}`}>
                <div
                  className={`${classes.mission}`}
                  style={{ marginBottom: "80px" }}
                >
                  <h4 className={`text-uppercase ${classes.fontWt300}`}>
                    our mission
                  </h4>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source. Lorem Ipsum comes from sections
                    1.10.32 and 1.10.33 of "de.
                  </p>
                </div>

                <div className={`${classes.vision}`}>
                  <h4 className={`text-uppercase ${classes.fontWt300}`}>
                    our vision
                  </h4>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source. Lorem Ipsum comes from sections
                    1.10.32 and 1.10.33 of "de.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${classes.founderBlock} ${classes.pPb}`}>
          <div style={{ background: "#f5f5f5" }}>
            <Container>
              <Row>
                <Col md={6} sm={6} xs={12}>
                  <Figure>
                    <Figure.Image src={founder} alt={``} />
                  </Figure>
                </Col>

                <Col md={6} sm={6} xs={12} className={`${classes.rightBlock}`}>
                  <div className={`${classes.primaryPadding}`}>
                    <h2 className={`text-uppercase ${classes.fontWt300}`}>
                      the founder story
                    </h2>
                    <div className={`${classes.contentWrap}`}>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages.
                      </p>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make leap.
                      </p>
                    </div>

                    <div className={`${classes.founder} lh-normal`}>
                      With Love
                      <span className={`${classes.fontDafoe}`}>Rosy Gonza</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className={`${classes.story} ${classes.pPb}`}>
          <Container>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <h2
                  style={{ marginBottom: "40px" }}
                  className={`text-center text-uppercase lh-normal ${classes.fontWt300}`}
                >
                  our story
                </h2>
                <div className={`${classes.contentWrap}`}>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source. Lorem Ipsum comes from sections
                    1.10.32 and 1.10.33 of "de.
                  </p>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text.
                  </p>
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source. Lorem Ipsum comes from sections
                    1.10.32 and 1.10.33 of "de.
                  </p>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${classes.quote} ${classes.pPb}`}>
          <div className={`${classes.primaryBg} ${classes.primaryPadding}`}>
            <Container>
              <Row>
                <Col
                  md={10}
                  sm={10}
                  smOffset={1}
                  xs={12}
                  className="text-center"
                >
                  <h4>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className={`${classes.leadership} ${classes.pPb}`}>
          <Container>
            <Row>
              <Col md={12} sm={12} xs={12}>
                <h2
                  style={{ marginBottom: "40px" }}
                  className={`text-center text-uppercase mb-40 lh-normal ${classes.fontWt300}`}
                >
                  meet the leaders
                </h2>
                <Row>
                  <Col md={4} sm={6} xs={12}>
                    <div className={`${classes.leaderBlock}`}>
                      <Figure>
                        <Figure.Image src={leader1} alt="Leader 1" />
                      </Figure>
                      <div className={`${classes.leaderContent}`}>
                        <h4 className={`${classes.fontWt300}`}>Michael</h4>
                        <h6>Founder</h6>
                        <p>
                          Michael is an accomplished leader in the fashion
                          industry with over a decade of experience.
                        </p>
                        <ul className={`${classes.socialIcons}`}>
                          <li>
                            <a href={`#`}>
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaTwitter />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaLinkedinIn />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaInstagram />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={6} xs={12}>
                    <div className={`${classes.leaderBlock}`}>
                      <Figure>
                        <Figure.Image src={leader2} alt="Leader 2" />
                      </Figure>
                      <div className={`${classes.leaderContent}`}>
                        <h4 className={`${classes.fontWt300}`}>Sophia</h4>
                        <h6>Co-Founder</h6>
                        <p>
                          Sophia has a passion for fashion and design, and has
                          been a driving force behind the company's success.
                        </p>
                        <ul className={`${classes.socialIcons}`}>
                          <li>
                            <a href={`#`}>
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaTwitter />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaLinkedinIn />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaInstagram />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} sm={6} xs={12}>
                    <div className={`${classes.leaderBlock}`}>
                      <Figure>
                        <Figure.Image src={leader3} alt="Leader 3" />
                      </Figure>
                      <div className={`${classes.leaderContent}`}>
                        <Figure className="image-effect">
                          <img src="assets/images/leader3.jpg" alt="" />
                        </Figure>
                        <h4>
                          dragosa <span>butsinango</span>
                        </h4>
                        <span>Chief Advisor</span>
                        <ul className={`${classes.socialIcons}`}>
                          <li>
                            <a href={`#`}>
                              <FaFacebookF />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaTwitter />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaLinkedinIn />
                            </a>
                          </li>
                          <li>
                            <a href={`#`}>
                              <FaInstagram />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Fragment>
  );
};

export default AboutUs;
