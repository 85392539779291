import React, { useEffect, useState } from 'react'
import './AdditionalInformation.css'
import axios from 'axios'
import { useParams } from 'react-router-dom'

function AdditionalInformation({additionalInformation}) {
    return (
            <div className="tab-pane panel active" id="add-info">
                <p className="specification-heading">Specifications</p>
                <div className="specifications-list">
                    {additionalInformation?.map((item,index)=>{
                        return(
                            <div className="points" key={index}>
                                <p className="sub-heading">{item.node.name}</p>
                                <p>{item.node.value}</p>
                            </div>
                        )
                    })}
                    
                </div>
            </div>
    )
}

export default AdditionalInformation