import React, { useContext, useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import classes from "./ProductDetails.module.css";
import { Row } from "react-bootstrap";
import { FaArrowRight, FaStar } from "react-icons/fa";
import { IoMdCheckbox } from "react-icons/io";
import { IoCart } from "react-icons/io5";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import ProgressBar from "react-bootstrap/ProgressBar";
import VerticalTab from "../../components/verticalTab/VerticalTab";
import tabButtonList from "./tabButtonList";
import axios from "axios";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import ImageSlider from "../../components/productDetails/ImageSlider";
import RecommendedProduct from "../../components/productDetails/RecommendedProduct/RecommendedProduct";
import {
  TiStarFullOutline,
  TiStarOutline,
  TiStarHalfOutline,
} from "react-icons/ti";
import _ from "lodash";
import { CartPath, HomePath, LoginPath, ProductsPath } from "../../constant/constant";
import { useMobileScreen } from "../../utils/isMobileScreen";
import { getBigComToken, getUserId } from "../../utils/getUserId";
import { FaIndianRupeeSign } from "react-icons/fa6";
import BreadCrumb from "../../components/breadCrumb/BreadCrumb";
import { userDetailsStore } from "../../store/GlobalUserData";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import Loader from "../../components/Loader/Loader";
import { useCountStore, useCurrencyCode } from "../../store/CountStore";
import { scrollToTop } from "../../components/CommonComponents/CommonHelperFunctions";
import { addToCartCountStore } from "../../store/CartCountStore";
import { Helmet } from "react-helmet";



function ProductDetails() {
  const { product_id } = useParams();
  const [productDetails, setProductDetails] = useState({});
  const [images, setImages] = useState([]);
  const [reviewsRating, setReviewsRating] = useState(0);
  const [reviewHalfRating, setReviewHalfRating] = useState(0);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const { itemIdsinCart,setItemIdsinCart } = addToCartCountStore()
  const { setAddToCartCount } = addToCartCountStore();
  const [relatedProductData, setRelatedProductData] = useState([]);
  const [relatedProducsId, setRelatedProductsId] = useState({ productId: "" });
  const [reviewDetails, setReviewDetails] = useState({});
  const [starRaging5, setStarRating5] = useState([]);
  const [starRaging4, setStarRating4] = useState([]);
  const [starRaging3, setStarRating3] = useState([]);
  const [starRaging2, setStarRating2] = useState([]);
  const [starRaging1, setStarRating1] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [productSizes, setProductSizes] = useState([])
  const [selectedVariant, setSelectedVariant] = useState([])
  const [totalNumReviews, setTotalNumReviews] = useState([])
  const [productVariantsIds, setProductVariantsIds] = useState([]);
  const [productVariantIdWithImage, setProductVariantIdWithImage] = useState([]);
  const [imagelength, setImageLength] = useState(0)
  const [cartId, setCartId] = useState()
  const [loading, setLoading] = useState(false)
  let smallScreen = useMobileScreen();
  const BigComToken = getBigComToken()
  const customerId = getUserId();
  const { userDetails, setUserDetails } = userDetailsStore();
  const { WishListIds, updateWishListIds, updateCount, counts } = useCountStore()
  const { defaultCurrency } = useCurrencyCode()
  const navigator = useNavigate()
  const location = useLocation()

  const getproductDetails = async (product_id) => {
    setLoading(true)
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}product_details?product_id=${product_id}&currencyCode=${defaultCurrency}`, {
        headers: {
          Authorization: 'Bearer ' + BigComToken
        }
      }
      )
      .then((res) => {
        setProductDetails(res.data);
        //setRelatedProductsId({productId:res.data.related_products?.join(',')})
        let rating = (
          res.data.reviewSummary.summationOfRatings / res.data.reviewSummary.numberOfReviews
        ).toFixed(1);
        _.isNaN(parseInt(rating))
          ? setReviewsRating(0)
          : setReviewsRating(rating);
        let halfRating = !_.isNaN(parseInt(rating)) && !_.isInteger(rating);
        halfRating ? setReviewHalfRating(1) : setReviewHalfRating(0);
        const sliders = [];
        res.data.images.edges.map((item) =>
          sliders.push({
            original: `${item.node.urlOriginal}`,
            thumbnail: `${item.node.urlOriginal}`,
          })
        );
        setImages(sliders);
        setImageLength(sliders.length)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      })
  };
  const context = useContext(MainContext);
  //context.productDetails = productDetails;
  context.productDescription = productDetails?.description
  context.productAdditionalSpecification = productDetails?.customFields?.edges
  context.productReviews = productDetails?.reviews?.edges.slice().reverse()
  const getreviewDetails = () => {
    setStarRating5(productDetails?.reviews?.edges.filter((item) => item.node.rating === 5)?.length);

    setStarRating4(productDetails?.reviews?.edges.filter((item) => item.node.rating === 4)?.length);

    setStarRating3(productDetails?.reviews?.edges.filter((item) => item.node.rating === 3)?.length);

    setStarRating2(productDetails?.reviews?.edges.filter((item) => item.node.rating === 2)?.length);

    setStarRating1(productDetails?.reviews?.edges.filter((item) => item.node.rating === 1)?.length);
  };

  useEffect(() => {
    if (!_.isEmpty(relatedProducsId.productId)) {
      fetchRelatedProductData()
    }
  }, [relatedProducsId, defaultCurrency])
  const fetchRelatedProductData = async () => {
    await (axios.get(`${process.env.REACT_APP_API_PATH}products?page=1&Details=${JSON.stringify(relatedProducsId)}`)
      .then(res => {
        setRelatedProductData(res.data.data)
      }))
  }

  const fetchVariantProductData = async () => {
    let variant_ids = productVariantsIds.join(",")
    await (axios.get(`${process.env.REACT_APP_API_PATH}productVariantsImage?product_id=${JSON.parse(product_id)}&variant_ids=${variant_ids}&BigComToken=${BigComToken}`)
      .then(res => {
        let list = res?.data?.variants?.edges?.map((item) => {
          return ({ variant_id: item?.node?.entityId, variant_image: item?.node?.defaultImage?.urlOriginal })
        })
        setProductVariantIdWithImage(list)
      }))
  }

  useEffect(() => {
    productVariantsIds?.length > 0 && fetchVariantProductData()
  }, [productVariantsIds])

  useEffect(() => {
    if (imagelength == images.length) {
      let data = productVariantIdWithImage.find((item) => item?.variant_id === selectedVariant)
      if (data?.variant_image !== undefined) {
        images.unshift({ original: data?.variant_image, thumbnail: data?.variant_image })
      }
    }
    else if (imagelength + 1 == images.length) {
      let imageData = [...images]
      let data = productVariantIdWithImage.find((item) => item.variant_id === selectedVariant)
      if (data?.variant_image !== undefined) {
        imageData[0] = { original: data?.variant_image, thumbnail: data?.variant_image }
        setImages(imageData)
      }
    }
  }, [selectedVariant, productVariantIdWithImage])

  useEffect(() => {
    scrollToTop()
    getproductDetails(product_id);

  }, [defaultCurrency]);
  useEffect(() => {
    getreviewDetails();
    setTotalNumReviews(productDetails?.reviewSummary?.numberOfReviews)
  }, [productDetails])
  context.reviewDetails = reviewDetails;

  //============Product Sizes==================

  const sizeAndColorDataHandler = () => {
    const inStockVariants = productDetails?.variants?.edges?.map((item) => item.node).filter((item) => item.inventory.isInStock === true)
    //============Product Colors==================
    //let Variants = inStockVariants?.map((item)=>item.options.edges)
    let newVarients = []
    let ids = []
    inStockVariants?.map((item) => {
      let varientData = {}
      varientData.Color = item?.productOptions?.edges.filter((item) => item.node.displayName === 'Color')[0].node.values.edges[0].node.hexColors ?? item?.options?.edges.filter((item) => item.node.displayName === 'Color')[0].node.values.edges[0].node.label
      varientData.Size = [{
        'sizeType': item?.options?.edges.filter((item) => item.node.displayName === 'Size')[0].node.values.edges[0].node.label,
        'entityId': item?.entityId
      }]
      newVarients.push(varientData)
      ids.push(item?.entityId)
    })
    setProductVariantsIds(ids)
    // Initialize an empty object to store grouped items
    let groupedItems = {};

    // Iterate through each item in the original array
    newVarients.forEach(item => {
      // Check if there is already an entry for this color in groupedItems
      if (!groupedItems[item.Color]) {
        groupedItems[item.Color] = {
          "Color": item.Color,
          "Size": []
        };
      }
      // Add each size from the current item to the corresponding Color entry in groupedItems
      groupedItems[item.Color].Size.push(...item.Size);
    });

    // Convert groupedItems object into an array of values (the grouped items)
    //let productVarient = Object.values(groupedItems);
    setProductVariants(Object.values(groupedItems))
  };
  //============Product quantity control==================
  let [qty, setQty] = useState(1);
  useEffect(() => {
    setSelectedSize(sizes[0]);
    sizeAndColorDataHandler();
  }, [productDetails]);
  const reduceQty = () => {
    setQty(--qty);
  };
  const addQty = () => {
    setQty(++qty);
  };
  const formatRatingsNumber = (number = 0) => {
    if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'l';
    }
    else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k';
    }
    return number.toString();
  }

  const addWishlistHandler = async (id) => {
    if (WishListIds.includes(id)) {
    }
    setLoading(true)
    await (axios.post(`${process.env.REACT_APP_API_PATH}wishlist`, {
      customer_id: customerId,
      product_id: id,
      variant_id: selectedVariant
    })
      .then(() => {
        // showSuccessToast("Product Added to Wishlist!")
        updateWishListIds([...WishListIds, id])
        updateCount(counts + 1)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err.response.data.error)
      }))
  }

  useEffect(() => {
    setProductSizes(productVariants[0]?.Size)
    setSelectedSize(productVariants[0]?.Size[0].sizeType)
    setSelectedColor(productVariants[0]?.Color)
    setSelectedVariant(productVariants[0]?.Size[0].entityId)
  }, [productVariants])

  const breadcrumbData = [
    {
      breadcrumbsText: "Home",
      breadcrumbsURL: HomePath,
    },
    {
      breadcrumbsText: "Clothing",
      breadcrumbsURL: ProductsPath,
    },
    {
      breadcrumbsText: productDetails.name,
      breadcrumbsURL: "",
    },
  ];
  const customer_id = getUserId()
  const addTocartHandler = async () => {
      setLoading(true)
      const payload = {
        cart: {
          "customer_id": customer_id,
          currency:{
            code:defaultCurrency
          },
          "line_items": [
            {
              "quantity": qty,
              "product_id": product_id,
              "currencyCode": productDetails?.prices?.price?.currencyCode,
              "list_price": productDetails?.prices?.price?.value,
              "variant_id": selectedVariant,
              "name": productDetails.name
            }
          ]
        },
        cartId: userDetails?.notes
      }
      await (axios.post(`${process.env.REACT_APP_API_PATH}addToCart`, { payload })
        .then((res) => {
          if(res.data.success===false) {
            showErrorToast(res.data?.message)
          } else {
            showSuccessToast("Product added to cart!")
            setCartId(res.data.data.id)
            setAddToCartCount(res?.data?.data.line_items?.physical_items.length)
            setItemIdsinCart(res?.data?.data?.line_items?.physical_items.map(v=>v.variant_id))
          }
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          showErrorToast(err?.response?.data?.message)
        }))
  }
  const updateCustomer = async () => {
    const payload = {
      id: userDetails.id,
      notes: cartId
    }
    await (axios.post(`${process.env.REACT_APP_API_PATH}updatecustomer`, payload)
      .then((res) => {
        setUserDetails(res?.data[0])
      })
      .catch(err => console.log(err.response.data.error)))
  }
  useEffect(() => {
    cartId !== undefined && updateCustomer()
  }, [cartId])
  return (
    <React.Fragment>
      <Helmet>
      <title>{productDetails.name ? `Buy ${productDetails.name} - Upvan fashion` :`Buy Product - Upvan fashion` }</title>
      <meta name="description" content={productDetails.name ? `Buy ${productDetails.name} - Upvan fashion` :`Buy Product - Upvan fashion` } />
      </Helmet>
      <BreadCrumb breadcrumbData={breadcrumbData} />
      {loading && <Loader />}
      <div className={classes.container}>
        <div className={classes.productDetailsWrapper}>
          <div className={classes.imageSliderContainer}>
            <ImageSlider images={images} />
          </div>
          <div className={classes.productDetailsContainer}>
            <div className={classes.productDetails}>
              <div className={classes.productDetailsTitleWrap}>
                <p className={classes.productDetailsTitle}>
                  {productDetails.name}
                </p>
                {/* <p className={classes.productDetailsSku}>
                  SKU:{productDetails.sku}{" "}
                </p> */}
              </div>
              <ul>
                <li>
                  <span className={classes.calculatedPrice}>
                    <p>{productDetails?.prices?.price?.currencyCode==="INR"?"₹":"$"}{productDetails?.prices?.price?.value} </p>
                    <del className={classes.price}>
                    {productDetails?.prices?.basePrice?.currencyCode==="INR"?"₹":"$"}{productDetails?.prices?.basePrice?.value}
                    </del>
                  </span>
                  <span className={classes.startRating}>
                    {Array.from(
                      { length: parseInt(reviewsRating) },
                      (_, index) => (
                        <TiStarFullOutline className={classes.starIcon}
                          fill="#EB2675"
                          key={index}
                        />
                      )
                    )}
                    {Array.from({ length: reviewHalfRating }, (_, index) => (
                      <TiStarHalfOutline className={classes.starIcon}
                        fill="#EB2675"
                        key={index}
                      />
                    ))}
                    {Array.from(
                      {
                        length: 5 - reviewHalfRating - parseInt(reviewsRating),
                      },
                      (_, index) => (
                        <TiStarOutline className={classes.starIcon} fill="#EB2675" key={index} />
                      )
                    )}
                  </span>
                </li>
                <li>
                  {productDetails?.inventory?.isInStock ? (
                    <div className={classes.instock}>
                      <IoMdCheckbox />
                      <p>Available</p>
                    </div>
                  ) : (
                    <span className={classes.outOfStock}>
                      {/* <IoIosCheckbox />  */}
                      <p>Not Available</p>
                    </span>
                  )}
                </li>
                <li>
                  <div
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: productDetails.plainTextDescription
                    }}
                  ></div>
                </li>
                {productDetails?.inventory?.isInStock &&
                  <li className={classes.color}>
                    <h5>Colors</h5>
                    {/* <select
                    className={classes.colorSelect}
                    onChange={(e) => setProductSizes(JSON.parse(e.target.value))}
                  >
                    {productVariants.map((item) => (
                      <option value={JSON.stringify(item.Size)}>{item.Color}</option>
                    ))}
                  </select> */}
                    <ul>
                      {productVariants.map((item) => {
                        return (
                          <li className={classes.swatches} style={{
                            cursor: 'pointer', background: `${item.Color}`,
                            border: selectedColor === item.Color ? '2px solid #000' : '1px solid #000'
                          }} onClick={() => {
                            setProductSizes(item.Size)
                            setSelectedColor(item.Color)
                            setSelectedSize(item?.Size[0].sizeType)
                            setSelectedVariant(item?.Size[0].entityId)
                          }}></li>
                        )
                      })}
                    </ul>
                  </li>
                }
                {productDetails?.inventory?.isInStock &&
                  <li className={classes.size}>
                    <p>Size</p>
                    <div className={classes.sizeDataWrapper}>
                      {productSizes?.map((item, index) => {
                        return (
                          <p
                            key={index}
                            className={classes.sizeBox}
                            style={{
                              cursor: 'pointer',
                              backgroundColor:
                                selectedSize === item.sizeType ? "#eb2676" : "#fff",
                              color: selectedSize === item.sizeType ? "#fff" : "#929292",
                              border:
                                selectedSize === item.sizeType
                                  ? "1px solid #eb2676"
                                  : " 1px solid #9d9a9b",
                            }}
                            onClick={() => {
                              setSelectedSize(item)
                              setSelectedSize(item?.sizeType)
                              setSelectedVariant(item?.entityId)
                            }}
                          >
                            {item.sizeType}
                          </p>
                        );
                      })}
                    </div>
                  </li>
                }
                {productDetails?.inventory?.isInStock &&
                  <li className={classes.Qty}>
                    <h5>Qty</h5>
                    <div className={classes.QtyBtnWrapper}>
                      {qty === 1 ? (
                        <button disabled onClick={reduceQty}>
                          -
                        </button>
                      ) : (
                        <button onClick={reduceQty}>-</button>
                      )}
                      <p>{qty}</p>
                      <button onClick={addQty}>+</button>
                    </div>
                  </li>
                }
                <li className={classes.addCartWrapper}>
                  { productDetails?.inventory?.isInStock ?
                      <button onClick={() => {
                        if (isNaN(customerId)) {
                          // showErrorToast("You must login first!")
                          navigator(LoginPath,{state:{from: location.pathname + location.search}});
                          localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
                        } else {
                          addTocartHandler()
                        }
                      }} className={classes.addCart}>
                        <IoCart /> ADD TO CART
                      </button> :
                      <button style={{ background: '#ccc', padding: '0.95em 1.4em' }} disabled className={classes.addCart}>
                        <IoCart /> OUT OF STOCK
                      </button>
                  }
                  {WishListIds?.includes(productDetails.entityId)
                    ? <button className={`${classes.wishList} ${classes.wishlistDisabled}`} disabled>
                      <FaHeart />
                      WISHLISTED
                    </button>
                    : <button className={classes.wishList} onClick={() => {
                      if (isNaN(customerId)) {
                        navigator(LoginPath,{state:{from: location.pathname + location.search}});
                        localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
                        // showErrorToast("You must login first!")
                      } else {
                        addWishlistHandler(productDetails.entityId);
                      }
                    }}>
                      <CiHeart />
                      ADD TO WISHLIST
                    </button>}

                </li>

              </ul>
            </div>
            <div className={classes.delivery_options}>
              <div className={classes.delivery_option_text_and_icon}>
                <div className={classes.label}>
                  <p>Delivery Options</p>
                </div>
              </div>

              <form className={classes.delivery_form}>
                <input
                  type="text"
                  placeholder="Enter pincode"
                  className={classes.pincode_code}
                  name="pincode"
                />
                <input
                  type="text"
                  className={classes.pincode_button}
                  value="Check"
                />
              </form>
              <p className={classes.pincode_subpara}>
                Please enter PIN code to check delivery time &amp; Pay on
                Delivery Availability
              </p>
            </div>
            {/* <div className={classes.delivery_sub_text}>
              <p>100% Original Products</p>
              <p>Pay on delivery might be available</p>
              <p>Easy 14 days returns and exchanges</p>
            </div> */}
            <Row>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className={classes.ratingbarWrapper}>
                  <p className={classes.ratingbarTitle}>Ratings</p>
                  <div className={classes.ratingbarContainer}>
                    <div className={classes.ratings}>
                      <h1>
                        {reviewsRating}
                        <span>
                          <FaStar />
                        </span>
                      </h1>
                      <p>{formatRatingsNumber(totalNumReviews)} Verified Buyers</p>
                    </div>
                    {!smallScreen && <div className={classes.middleLine}></div>}
                    <div className={classes.ratingBars}>
                      <div className={classes.barsStars}>
                        <p>
                          5 <FaStar />
                        </p>
                        <ProgressBar
                          className={`${classes.bar} successStarBar`}
                          variant="success"
                          now={(starRaging5 / productDetails?.reviewSummary?.numberOfReviews) * 100}
                        />
                        <p>{starRaging5}</p>
                      </div>
                      <div className={classes.barsStars}>
                        <p>
                          4 <FaStar />
                        </p>
                        <ProgressBar
                          className={`${classes.bar} successStarBar`}
                          variant="success"
                          now={(starRaging4 / productDetails?.reviewSummary?.numberOfReviews) * 100}
                        />
                        <p>{starRaging4}</p>
                      </div>
                      <div className={classes.barsStars}>
                        <p>
                          3 <FaStar />
                        </p>
                        <ProgressBar
                          className={`${classes.bar} averageStarBar`}
                          variant="success"
                          now={(starRaging3 / productDetails?.reviewSummary?.numberOfReviews) * 100}
                        />
                        <p>{starRaging3}</p>
                      </div>
                      <div className={classes.barsStars}>
                        <p>
                          2 <FaStar />
                        </p>
                        <ProgressBar
                          className={`${classes.bar} twoStarBar`}
                          variant="danger"
                          now={(starRaging2 / productDetails?.reviewSummary?.numberOfReviews) * 100}
                        />
                        <p>{starRaging2}</p>
                      </div>
                      <div className={classes.barsStars}>
                        <p>
                          1 <FaStar />
                        </p>
                        <ProgressBar
                          className={`${classes.bar} oneStarBar`}
                          variant="danger"
                          now={(starRaging1 / productDetails?.reviewSummary?.numberOfReviews) * 100}
                        />
                        <p>{starRaging1}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.what_customer_said}>
                  {/* <div className="mb-3">
                    <p>What Customers Said</p>
                  </div>

                  <div>
                    <label>Fit</label>
                    <div className={classes.customerSaidBarContainer}>
                      <ProgressBar
                        className={classes.bar}
                        variant="success"
                        now={70}
                      />
                      <p>Just Right (70%)</p>
                    </div>
                  </div>
                  <div>
                    <label>Length</label>
                    <div className={classes.customerSaidBarContainer}>
                      <ProgressBar
                        className={classes.bar}
                        variant="primary"
                        now={60}
                      />
                      <p>Just Right (60%)</p>
                    </div>
                  </div>
                  <div>
                    <label>Transparency</label>
                    <div className={classes.customerSaidBarContainer}>
                      <ProgressBar
                        className={classes.bar}
                        variant="danger"
                        now={30}
                      />
                      <p>Just Right (30%)</p>
                    </div>
                  </div> */}
                  <div className={classes.productShareSocialMediaWrapper}>
                    {/* <ul className={classes.productShareSocialMedia}>
                      <li>
                        <button>
                          <FaFacebookF />
                        </button>
                      </li>
                      <li>
                        <button>
                          <FaTwitter />
                        </button>
                      </li>
                      <li>
                        <button>
                          <FaLinkedinIn />
                        </button>
                      </li>
                      <li>
                        <button>
                          <FaInstagram />
                        </button>
                      </li>
                    </ul> */}
                    <p>
                      Category: {productDetails?.categories?.edges?.map((item) => {
                        return (
                          <>
                            <Link to={`${ProductsPath}?selectedCategory=${item.node.entityId}`}>{item.node.name}</Link>&nbsp;
                          </>
                        )
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <Row className={classes.row}>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <VerticalTab Tabs={tabButtonList} />
          </div>
        </Row>
      </div>
      <div className={classes.container}>
        {/* <RecommendedProduct
          title={"Recommended Product"}
          data={recommendedProductData}
        /> */}
      </div>
      {relatedProductData.length > 0 &&
        <div className={classes.container}>
          <RecommendedProduct
            title={"Related for you"}
            data={relatedProductData}
          />
        </div>}
    </React.Fragment>
  );
}

export default ProductDetails;
