import React, { useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import classes from "../AddressesTab/AddAddressModal/AddAddressModal.module.css";
import newClasses from "./ReviewModal/ReviewModal.module.css";
import axios from "axios";
import { userDetailsStore } from "../../../store/GlobalUserData";
function OrderListFilterModal({ handleClose, openModal, handleOrdersFilter }) {
  const { userDetails } = userDetailsStore();
  const [text, setText] = useState("");

  const handleSubmitFilter = () => {
    // handleOrdersFilter();
  };
  return (
    <Modal
      show={openModal}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div className={classes.modalContent}>
        <div className={classes.modalHeaderWrapper}>
          <h5 className={classes.modalHeader}>Filter Orders</h5>
          <button
            className={classes.closeBtn}
            onClick={handleClose}
            tabIndex={0}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <Container key={`inline`} className="m-2">
          <b>
            <h6>Status</h6>
          </b>
          <Form.Check label="All" name="All" type="radio" id={`1`} />
          <Form.Check
            label="On the Way"
            name="On the Way"
            type="radio"
            id={`On the Way`}
          />
          <Form.Check label="Delivered" type="radio" id={`2`} />
          <Form.Check label="Cancelled" type="radio" id={`3`} />
          <Form.Check label="Returned" type="radio" id={`4`} />
        </Container>
        <hr />
        <Container key={`inline`} className="m-2">
          <b>
            <h6>Time</h6>
          </b>
          <Form.Check label="Anytime" name="Anytime" type="radio" id={`5`} />
          <Form.Check
            label="Last 30 Days"
            name="Last 30 Days"
            type="radio"
            id={`6`}
          />
          <Form.Check label="Last Month" type="radio" id={`7`} />
          <Form.Check label="Last  Year" type="radio" id={`8`} />
        </Container>
        <div className={classes.modalFooter}>
          <button className={classes.secondaryBtn} onClick={handleSubmitFilter}>
            Clear Filter
          </button>
          <button className={classes.primaryBtn} onClick={handleSubmitFilter}>
            Apply
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default OrderListFilterModal;
