import React, { useEffect, useRef, useState } from "react";
import LoginSignupWrapper from "../../components/loginSignupWrapper/loginSignupWrapper";
import { Field, Form, Formik } from "formik";
import classes from "./ResetPassword.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import { HomePath, LoginPath } from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import { resetPasswordValidationSchema } from "../../components/CommonComponents/ValidationSchema";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import axios from "axios";
import OTPComponent from "../../components/otpComponent/OTPComponent";
import Loader from "../../components/Loader/Loader";
import { userDetailsStore } from "../../store/GlobalUserData";
import { showErrorToast } from "../../utils/toastUtils";
import { Helmet } from "react-helmet";

function ResetPassword() {
  const [isMobileNumberInput, setIsMobileNumberInput] = useState(true);
  const [isOTPScreen, setIsOTPScreen] = useState(false);
  const { setUserDetails } = userDetailsStore();
  const [isPasswordScreen, setIsPasswordScreen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [userId, setUserId] = useState();
  const navigate = useNavigate();
  const formikRef = useRef();
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  let now = new Date();
  let time = now.getTime();
  let expireTime = time + 86400 * 1000 * 7;

  const resetPasswordnHandler = async (data) => {
    setLoading(true);
    let isRedirct = false;
    let error = false
    if (isMobileNumberInput) {
      await axios
        .get(
          `${process.env.REACT_APP_API_PATH}isCustomerNumberExist?phone=${data.phone}&email=${data.email}&loginPage=true&resetPage=true`
        )
        .then((res) => {
          if (!res.data.success) {
            showErrorToast(res.data.message);
            setLoading(false);
          }
          else if (res.data.redirct) {
            isRedirct = true;
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          showErrorToast(err?.response?.data?.message)
          error = true
        });
    }
    if (isMobileNumberInput && isRedirct && !error) {
      setIsMobileNumberInput(false);
      setIsOTPScreen(true);
      await axios
        .post(`${process.env.REACT_APP_API_PATH}sendSMS`, {
          mobile_Number: data.phone,
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => console.log({ err }));
    } else if (isOTPScreen) {
      if (resendOTP) {
        await axios
          .post(`${process.env.REACT_APP_API_PATH}sendSMS`, {
            mobile_Number: data.phone,
          })
          .then(() => {
            setResendOTP(false);
            setLoading(false);
          })
          .catch((err) => console.log({ err }));
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_PATH}verify_otp`, {
            mobile_Number: data.phone,
            otp: data.otp.join(""),
          })
          .then(async (res) => {
            if (!res.data.success) {
              showErrorToast(res.data.message);
              setLoading(false);
            }
            else{
              if (res?.data?.data?.length === 0) {
                setIsOTPScreen(false);
              } else {
                await axios
                  .get(
                    `${process.env.REACT_APP_API_PATH}getCustomer?phone=${data.phone}&resetPassword=true`
                  )
                  .then((res) => {
                    setUserId(res?.data?.data);
                    setIsOTPScreen(false);
                    setIsPasswordScreen(true);
                    setLoading(false);
                  });
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            showErrorToast(err?.response?.data?.message)
          });
      }
    } else if (isPasswordScreen) {
      await axios
        .post(
          `${process.env.REACT_APP_API_PATH}updatecustomer`,
          { id: userId, password: data.password , resetPassword: true}
        )
        .then((res) => {
          now.setTime(expireTime);
          document.cookie =
            `userId=${res?.data[0]?.id};expires=` +
            now.toUTCString() +
            ";path=/";
          setUserDetails(res?.data[0]);
          setLoading(false);
          navigate(HomePath);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('shouldRedirect', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    const shouldRedirect = sessionStorage.getItem('shouldRedirect');
    if (shouldRedirect) {
      sessionStorage.removeItem('shouldRedirect');
      navigate(LoginPath, { replace: true });
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);
  return (
    <LoginSignupWrapper title={isOTPScreen ? "" : "Reset Password"}>
       <Helmet>
        <title>Upvan Fashion - Reset Password</title>
        <meta name="description" content="Upvan Fashion - Reset Password" />
      </Helmet>
      {loading && <Loader />}
      {!isOTPScreen && (
        <p className={classes.subTitle}>
          Enter your mobile number and we'll send a OTP on your mobile number to
          reset your password.
        </p>
      )}
      <Formik
        initialValues={{
          phone: "",
          otp: Array(6).fill(""),
        }}
        validationSchema={resetPasswordValidationSchema(
          isMobileNumberInput,
          isPasswordScreen
        )}
        innerRef={formikRef}
        onSubmit={async (values) => {
          resetPasswordnHandler(values);
        }}
      >
        {({ setFieldValue, errors, values }) => {
          return (
            <Form className={classes.formWrapper}>
              {isMobileNumberInput && (
                <div className={classes.inputGroup}>
                  <label>Phone number</label>
                  <PhoneInput
                    name="phone"
                    value={values.phone}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    country={"in"}
                    onChange={(phone) => {
                      setFieldValue("phone", phone);
                    }}
                  />
                  {errors.phone && isMobileNumberInput && (
                    <ErrorMessage message={errors.phone} />
                  )}
                </div>
              )}
              {isOTPScreen && (
                <OTPComponent
                  values={values}
                  setFieldValue={setFieldValue}
                  formikRef={formikRef}
                  phone={values.phone}
                  setResendOTP={setResendOTP}
                />
              )}
              {isPasswordScreen && (
                <>
                  <div className={classes.inputGroup}>
                    <label>New password*</label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                      />
                      <div
                        className={classes.isPasswordVisible}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                      </div>
                    </div>
                    {errors.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Confirm password*</label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter confirm  password"
                        name="confirm_password"
                      />
                      <div
                        className={classes.isPasswordVisible}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                      </div>
                    </div>
                    {errors.confirm_password && (
                      <ErrorMessage message={errors.confirm_password} />
                    )}
                  </div>
                </>
              )}
              {!isOTPScreen && (
                <div className={classes.inputGroup}>
                  <button className={classes.submitButton} type="submit">
                    {isOTPScreen
                      ? "Verify OTP"
                      : isPasswordScreen
                        ? "Change Password"
                        : "Send OTP"}
                  </button>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
      {!isOTPScreen && (
        <div className={classes.inputGroup}>
          <p className={classes.privacyText}>
            Have a Password?{" "}
            <span onClick={() => navigate(LoginPath)}>Login</span>
          </p>
        </div>
      )}
    </LoginSignupWrapper>
  );
}

export default ResetPassword;
