import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import classes2 from "./CouponsPage.module.css";
import classes from "../../profile/ProfileDetailsTab/EditProfileModal/EditProfileModal.module.css";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import axios from "axios";

function ApplyGiftModal({ setCloseCoupon, giftsBelongToYou, cartDetails, setCartDetails }) {
  console.log(giftsBelongToYou,'giftsBelongToYou')
const [couponCode, setGiftCode] = useState('')
  const handleClose = () => {
    setCloseCoupon();
  };

  const handleChange = (e) => {
    console.log(e.target.value, e.target.checked)
    setGiftCode(e.target.value)
  }

  const handleSubmit = (values) => {
    axios
      .post(`${process.env.REACT_APP_API_PATH}addGiftToCheckout`, {
        giftCertificateCode: couponCode,
        checkoutId: cartDetails.id,
      })
      .then((res) => {
        console.log(res.data);
        if(res.data.status === 400) {
         showErrorToast(res.data.title) 
        }else {
          showSuccessToast("Gift Applied Successfully!");
        }
      
        setCartDetails(res?.data.data.cart);
      })
      .catch((err) => {
        console.log(err)
      });
  };

  return (
    <Modal
      show={true}
      backdrop={false}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div style={{height: '99vh', overflow: 'auto' }} className={classes.modalContent}>
        <div>
          <div style={{ position: "relative" }}>
            <h5 className={classes.modalHeader}>Apply Gift to Checkout</h5>
            <button
              className={classes.closeBtn}
              onClick={handleClose}
              tabIndex={0}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            style={{ marginBottom: "1rem" }}
            className={classes2.couponCodeList}
          >
            {giftsBelongToYou.map((value, index) => (
              <div className={classes2.couponcodesection}>
                <input style={{ accentColor: "#eb2675"}} id={index} value={value.code} onChange={handleChange} checked={value.code === couponCode} type="checkbox" />
                <div className={classes2.copucodetextsection}>
                  <p className={classes2.copuncodetextwithborder}>
                    {value.code}
                  </p>
                  <p className={classes2.saveMoneyText}>
                    <b>Save ₹{Math.floor(value.amount)}</b>
                  </p>
                  <p className={classes2.saveMoneyText}>
                    <b>Balance ₹{Math.floor(value.balance)}</b>
                  </p>
                  <p>
                    {value.type === "per_item_discount"
                      ? `Rs.${Math.floor(
                          value.amount
                        )} off on selected Product on minimum purchase of Rs.${Math.floor(
                          value.min_purchase
                        )}.`
                      : value.type === "per_total_discount"
                      ? `Rs.${Math.floor(
                          value.amount
                        )} off on minimum purchase of Rs.${Math.floor(
                          value.min_purchase
                        )}.`
                      : ""}
                  </p>
                  <p>
                    Sent from: {value.from_email}.
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.modalFooter}>
            <button className={classes.primaryBtn} onClick={handleSubmit}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ApplyGiftModal;
