import React, { useEffect, useState } from 'react'
import ReactFlagsSelect from "react-flags-select";
import {Container,Row} from 'react-bootstrap'
import classes from './Register.module.css'
import axios from 'axios';

function Register() {
  const [selected, setSelected] = useState("");
  const [error]=useState({})
  const[userDetails, setUserDetails] = useState({
    First_Name: "",
    Last_Name: "",
    Country_Code: selected,
    Mobile_Number:"",
    Email_Id: "",
    Password: ""
  });

  const inputHandler=(e)=>{
    const nextField = {...userDetails,
      [e.target.name] : e.target.value
    };
    setUserDetails(nextField);
  };

  // const formValidation = (userDetails, errors)=>{
  //   if(userDetails.Email_Id.includes("@") &&  userDetails.Email_Id.includes(".com")){
  //     error.Email_Id = "Please provide correct Email Address";
  //   }
  //   if(userDetails.First_Name ===""){
  //     error.First_Name="Please provide your first name"
  //   }
  //   if(userDetails.Last_Name ===""){
  //     error.Last_Name="Please provide your last name"
  //   }
  //   if(userDetails.Country_Code ===""){
  //     error.Country_Code="Please provide country code"
  //   }
  //   if(userDetails.Mobile_Number===""){
  //     error.Country_Code="Please mobile number"
  //   }
  //   if(userDetails.Password<6){
  //     error.Password="Password is too sort"
  //   }
  //   return(error)
  // }

  const userFormSubmitHandler=async(event)=>{
    event.preventDefault();
    
    await(axios.post(`${process.env.REACT_APP_API_PATH}userRegistration`,userDetails)
    .then(res=>console.log(res))
    .catch(err=>console.log(err)))
    
  }
const loginHandler = async ()=>{
  await axios.get(`${process.env.REACT_APP_API_PATH}login`)
  .then((res)=>{
    console.log({res})
  })
  .catch((err)=>console.log({err}))
}
useEffect(()=>{
  loginHandler()
},[])

return (
    <section className={classes.section}>
        <Container>
            <Row className="justify-content-md-center justify-content-lg-center justify-content-sm-center">
                <div className='col-lg-6 col-md-6 col-sm-8 col-xs-12'>
                    <div className={classes.formContainer}>
                      <h4 className='mb-4'>Create Account</h4>
                      <form onSubmit={userFormSubmitHandler}>
                        <div className={classes.inputGroup}>
                          <label>First Name</label>
                          <input name="First_Name"  type="text" placeholder='Enter First Name' onChange={inputHandler} />
                          {error.First_Name && <p>{error.First_Name}</p> }
                        </div>
                        <div className={classes.inputGroup}>
                          <label>Last Name</label>
                          <input name="Last_Name"   type="text" placeholder='Enter Last Name' onChange={inputHandler}/>
                        </div>
                        <div className={classes.inputGroup}>
                          <label>Mobile Number</label>
                          <div className={classes.Mobile}>
                          <ReactFlagsSelect className={classes.countrySelector}
                            selected={selected}
                            onSelect={(code) => setSelected(code)}
                          />
                          {/* <Select options={options} value={value} onChange={changeHandler} /> */}
                          <input name="Mobile_Number"  type="number" min="1000000000" max="9999999999"  placeholder='Mobile number' onChange={inputHandler}/>
                          </div>
                        </div>
                        <div className={classes.inputGroup}>
                          <label>Email Id</label>
                          <input name="Email_Id" type="text" placeholder='Enter Your Email ID' onChange={inputHandler}/>
                        </div>
                        <div className={classes.inputGroup}>
                          <label>Password</label>
                          <input name="Password"  type="password" placeholder='At least 6 character' onChange={inputHandler}/>
                        </div>
                        <div className={classes.inputGroup}>
                          <p>To verify your number, we will send you a text message with a temporary code. Message and data rates may apply.</p>
                        </div>
                        <div className={classes.inputGroup}>
                          <button type="submit" className={classes.submitButton}>Verify mobile number</button>
                        </div>
                      </form>
                    </div>
                </div>
            </Row>
        </Container>
    </section>
  )
}

export default Register