import React, { useState } from "react";
import classes from "./AddressCard.module.css";

function AddressCard({
  addressesData,
  deleteAddress,
  setAddresses,
  setIsAddAddressesModal,
}) {
  const [selectedAddress, setSelectedAddress] = useState(1);

  return (
    <div className={classes.addressWrapper}>
      <div className={classes.titleContainer}>
        <div className={classes.addressTitle}>Select Delivery Address</div>
        <button
          className={classes.addNewAddressBtn}
          onClick={() => {
            setIsAddAddressesModal(true)
            setAddresses({})
          }}
          type="button"
        >
          + ADD NEW ADDRESS
        </button>
      </div>
      <div className={classes.addressListWrapper}>
        {addressesData?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "ce",
            }}
          >
            <p>No Records Found!</p>
          </div>
        ) : (
          addressesData?.map((item, index) => (
            <div
              className={classes.addressListInnerWrapper}
              key={index}
              onClick={() => {
                setSelectedAddress(index + 1);
                setAddresses(item);
              }}
            >
              <input
                type="radio"
                name="selectedAddress"
                value={index + 1}
                checked={index + 1 == selectedAddress}
                defaultChecked={selectedAddress}
              />
              <div className={classes.addressDetails}>
                <div className={classes.addressDetailsHeader}>
                  <div className={classes.addressDetailsName}>
                    {item.first_name} &nbsp;{item.last_name}
                  </div>
                  <div className={classes.addressDetailsaddressType}>
                    {item.address_type}
                  </div>
                  {index === 0 && (
                    <div className={classes.defaultAddress}>
                      Default Address
                    </div>
                  )}
                </div>
                <div className={classes.addressDetailsAddress}>
                  {item.address1}, {item.address2}, {item.city},{" "}
                  {item.state_or_province}, {item.postal_code}
                </div>
                <div className={classes.addressDetailsMobile}>
                  <span>Mobile:</span>
                  <span>{item.phone}</span>
                </div>
                <div className={classes.deliveryOptions}>
                  <ul>
                    <li>Cash on Delivery available</li>
                  </ul>
                </div>
                <div className={classes.addressDetailsBtns}>
                  <button
                    type="button"
                    onClick={() => deleteAddress(item.id)}
                    className={classes.addressDetailsBtn}
                  >
                    Remove
                  </button>
                  <button
                    type="button"
                    className={classes.addressDetailsBtn}
                    onClick={() => {
                      setIsAddAddressesModal(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default AddressCard;
