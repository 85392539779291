import React from "react";
import classes from "./PrivacyPolicy.module.css";
import { privacyPolicyData, termsData } from "../../constant/constant";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div className={classes.container}>
       <Helmet>
        <title>Privacy Policy - Upvan Fashion</title>
        <meta name="description" content="Privacy Policy - Upvan Fashion" />
      </Helmet>
      <div className={classes.wrapper}>
        <p className={classes.heading}>Privacy Policy</p>
        <div className={classes.pageContent}>
          {privacyPolicyData.map((item, index) => (
            <div className={classes.content} key={index}>
              <span>
                {index + 1}. {item.title}
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
