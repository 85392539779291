import React, { Fragment } from 'react'
import classes from './Cart.module.css'
import CheckoutPageLayout from '../../components/cart/CartSteps/CheckoutPage'
import { Helmet } from 'react-helmet'

function Cart() {
  return (
    <Fragment>
    <Helmet>
      <title>Checkout - Upvan Fashion</title>
      <meta name="description" content="Checkout - Upvan Fashion" />
    </Helmet>
    <div className={classes.container}>
      <div className={classes.cartWrapper}>
        <CheckoutPageLayout />
      </div>
    </div>
    </Fragment>
  )
}

export default Cart
