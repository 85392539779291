import React, { useContext } from "react";
import classes from "./ProductDescription.module.css";
import MainContext from "../../contexts/MainContext";
import { useMobileScreen } from "../../utils/isMobileScreen";
function ProductDescription() {
  const context = useContext(MainContext);
  let smallScreen = useMobileScreen();
  return (
    <div className={classes.productDescriptionWrapper}>
      {!smallScreen && <p className={classes.title}>Product description</p>}
      <div className={classes.description}
        dangerouslySetInnerHTML={{
          __html: context?.productDescription,
        }}
      ></div>
    </div>
  );
}

export default ProductDescription;
