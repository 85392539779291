import React, { useEffect, useRef, useState } from "react";
import classes from "./CustomersReview.module.css";
import StarIcon from "../../../Svgs/StarIcon";
import VerifiedIcon from "../../../Svgs/verifiedIcon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderPreIcon from "../../../Svgs/SliderPreIcon";
import SliderNextIcon from "../../../Svgs/SliderNextIcon";
import { Container } from "react-bootstrap";

function CustomersReview() {
  let sliderRef = useRef(null);
  const [isTab, setIsTab] = useState(false);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  let settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  const reviewData = [
    {
      name: "Krupal B.",
      comment: `"I'm blown away by the quality and style of the clothes I received
            from Shop.co. From casual wear to elegant dresses, every piece I've
            bought has exceeded my expectations.”`,
      ratingIcons: "4",
    },
    {
      name: "Vivek J.",
      comment: `"I'm blown away by the quality and style of the clothes I received
            from Shop.co. From casual wear to elegant dresses, every piece I've
            bought has exceeded my expectations.”`,
      ratingIcons: "3",
    },
    {
      name: "Vivek J.",
      comment: `"I'm blown away by the quality and style of the clothes I received
            from Shop.co. From casual wear to elegant dresses, every piece I've
            bought has exceeded my expectations.”`,
      ratingIcons: "3",
    },
    {
      name: "Vivek J.",
      comment: `"I'm blown away by the quality and style of the clothes I received
            from Shop.co. From casual wear to elegant dresses, every piece I've
            bought has exceeded my expectations.”`,
      ratingIcons: "3",
    },
    {
      name: "Vivek J.",
      comment: `"I'm blown away by the quality and style of the clothes I received
            from Shop.co. From casual wear to elegant dresses, every piece I've
            bought has exceeded my expectations.”`,
      ratingIcons: "3",
    },
    {
      name: "Vivek J.",
      comment: `"I'm blown away by the quality and style of the clothes I received
            from Shop.co. From casual wear to elegant dresses, every piece I've
            bought has exceeded my expectations.”`,
      ratingIcons: "3",
    },
  ];
  const handleResize = () => {
    const innerWidth = window.innerWidth < 768 ? true : false;
    setIsTab(innerWidth);
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={`reviewSlider ${classes.wrapper}`}>
      <Container className={classes.titleIconWrapper}>
        <h2 className={classes.wrapperTitle}>Our happy customers</h2>
        {!isTab && (
          <div className={classes.iconsWrapper}>
            <div onClick={previous} className={classes.icon}>
              <SliderPreIcon />
            </div>
            <div onClick={next} className={classes.icon}>
              <SliderNextIcon />
            </div>
          </div>
        )}
      </Container>
      <Slider
        {...settings}
        ref={(slider) => {
          sliderRef = slider;
        }}
      >
        {reviewData.map((item, index) => (
          <div className={classes.container} key={index}>
            <div className={classes.ratingIcons}>
              {Array.from({ length: item.ratingIcons }, (_, index) => (
                <StarIcon fill="#EB2675" key={index} />
              ))}
              {Array.from({ length: 5 - item.ratingIcons }, (_, index) => (
                <StarIcon fill="white" key={index} />
              ))}
            </div>
            <div className={classes.nameWrapper}>
              <p className={classes.name}>{item.name}</p>
              <VerifiedIcon />
            </div>
            <p className={classes.comment}>{item.comment}</p>
          </div>
        ))}
      </Slider>
        {isTab && (
          <div className={classes.iconsWrapper}>
            <div onClick={previous}>
              <SliderPreIcon />
            </div>
            <div onClick={next}>
              <SliderNextIcon />
            </div>
          </div>
        )}
    </div>
  );
}

export default CustomersReview;
