import React from 'react'
import classes from './EmptyCard.module.css'
import { useNavigate } from 'react-router-dom'
import { ProductsPath } from '../../constant/constant';
function EmptyCard(props) {
const navigate = useNavigate();

    return (
        <div className={classes.wishListNoDataWapper}>
            <p className={classes.wishListNoDataTitle}>
                your {props.pageName} is empty
            </p>
            <p className={classes.wishListNoDataSubTitle}>
                Add items that you like to your wishlist. Review them anytime
                and easily move to the bag
            </p>
            <button
                className={classes.wishListNoDataBtn}
                onClick={() => navigate(ProductsPath)}
            >
                Continue shopping
            </button>
        </div>
    )
}

export default EmptyCard
