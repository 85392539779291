import React, { Fragment } from "react";
import classes from "./contactus.module.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <Fragment>
       <Helmet>
        <title>Contact - Upvan Fashion</title>
        <meta name="description" content="Contact - Upvan Fashion" />
      </Helmet>
      <main className={`${classes.main} ${classes.primaryPadding}`}>
        <Container className={classes.contactPage}>
          <Row className={`top ${classes.mb90}`}>
            <Col md={4} sm={4} xs={12} className="text-center">
              <div className="box text-center">
                <h4 className={classes.contactTitle}>
                  <i className="pe-7s-map-marker"></i>
                  Address
                </h4>
                <p>Local street address here, State, Zip code, Country</p>
              </div>
            </Col>

            <Col md={4} sm={4} xs={12} className="text-center">
              <div className="box">
                <h4 className={classes.contactTitle}>
                  <i className="pe-7s-call"></i>
                  Phone & Email
                </h4>
                <p>
                  1-098-3456-7890
                  <span>
                    <a href="#">info@yourdomain.com</a>
                  </span>
                </p>
              </div>
            </Col>

            <Col md={4} sm={4} xs={12} className="text-center">
              <div className="box">
                <h4 className={classes.contactTitle}>
                  <i className="pe-7s-share"></i>
                  Social Media
                </h4>
                <ul className={classes.socialIcons}>
                  <li>
                    <a href="#">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row className={`${classes.mb60}`}>
            <Col md={10} sm={10} className="offset-md-1 offset-sm-1 col-xs-12">
              <hr />
            </Col>
          </Row>

          <Row className={`form-wrap ${classes.mb50}`}>
            <Col md={12} sm={12} xs={12} style={{ marginottom: "25px" }}>
              <h4 className={`${classes.contactTitle} text-center`}>
                <i className="pe-7s-mail"></i>
                Send us an email
              </h4>
            </Col>

            <Col md={10} sm={10} className="offset-md-1 offset-sm-1 col-xs-12">
              <Form action="#">
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>
                        First Name <span>*</span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Your First Name" />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Last Name <span>*</span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Your Last Name" />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>
                        Email <span>*</span>
                      </Form.Label>
                      <Form.Control type="email" placeholder="Your Email" />
                    </Form.Group>
                  </Col>

                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" placeholder="Your Message" />
                    </Form.Group>
                  </Col>

                  <Form.Group as={Col} sm={12} style={{ marginBottom: "0px" }}>
                    <Button type="submit">Send Message</Button>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row className={classes.mapWrap}>
            <Col md={10} sm={10} className="offset-md-1 offset-sm-1 col-xs-12">
              <div className={`${classes.mapWrap} overlay`}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13027.97135015198!2d149.1204445793029!3d-35.28130433462935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164d69b05c9021%3A0x500ea6ea7695660!2sCanberra+ACT+2601!5e0!3m2!1sen!2sau!4v1486457460600"
                  width="600"
                  height="580"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </Fragment>
  );
};

export default ContactUs;
