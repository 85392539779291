import React, { useContext } from "react";
import classes from "./Reviews.module.css";
import { useParams } from "react-router-dom";
import {  useState } from "react";
import MainContext from "../../contexts/MainContext";
import { dateFormat } from "../../utils/dateFormat";
import { TiStarFullOutline,  TiStarOutline} from "react-icons/ti";

function Reviews() {
  const { product_id } = useParams();
  const context = useContext(MainContext);
  const reviewDetails = context.productReviews;
  const [viewMore, setViewMore] = useState(false);
  return (
    <div className={classes.review}>
      {reviewDetails?.length > 0 && reviewDetails?.slice(0,viewMore? reviewDetails.length : 3 ).map((item, index) => {
        let formattedDate = dateFormat(item.node.createdAt.utc)
        return (
          <div className={classes.reviewDetailsWtapper} key={index}>
            <ul>
              <li>
                {Array.from({ length: parseInt(item.node.rating) }, (_, index) => (
                  <TiStarFullOutline fill="#EB2675" width="14" key={index} />
                ))}
                {Array.from(
                  { length: 5 - parseInt(item.node.rating) },
                  (_, index) => (
                    <TiStarOutline fill="#EB2675" width="14" key={index} />
                  )
                )}
              </li>
              <li>{formattedDate}</li>
            </ul>

            <p>{item.node.text}</p>
            <p className={classes.name}>
              <b>{item.node.author.name}</b>
            </p>
          </div>
        );
      })}
      {reviewDetails?.length > 3 && <p className={classes.viewBtn} onClick={() => setViewMore(!viewMore)}>
        {viewMore ? "Read less" : "View all other reviews"}
      </p>}
      {reviewDetails?.length === 0 && 
      <p className={classes.title}>No review available</p>}
    </div>
  );
}

export default Reviews;
