export const getUserId = () => {
  const cookieData = document.cookie;
  const splitData = cookieData.split(";");
  const userData = splitData.find((item) => item.includes("userId"));
  const userId = userData?.split("=")[1];
  return parseInt(userId);
};

export const getRefreshToken = () => {
  const cookieData = document.cookie;
  const splitData = cookieData.split(";");
  const tokenData = splitData.find((item) => item.includes("RefreshToken"));
  const token = tokenData?.split("=")[1];
  return token
};

export const getBigComToken = () => {
  const cookieData = document.cookie;
  const splitData = cookieData.split(";");
  const tokenData = splitData.find((item) => item.includes("BigComToken"));
  const token = tokenData?.split("=")[1];
  return token
};