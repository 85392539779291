import React from "react";

function OrderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_62_51"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_62_51)">
        <path
          d="M8.19998 40V14.15L4.09998 5.35L6.89998 4L11.4 14.15H36.65L41.15 4L43.95 5.35L39.85 14.15V40H8.19998ZM19.8 25.15H28.3C28.725 25.15 29.0812 25.0054 29.3687 24.7163C29.6562 24.4271 29.8 24.0687 29.8 23.6413C29.8 23.2138 29.6562 22.8583 29.3687 22.575C29.0812 22.2917 28.725 22.15 28.3 22.15H19.8C19.375 22.15 19.0187 22.2946 18.7312 22.5837C18.4437 22.8729 18.3 23.2313 18.3 23.6588C18.3 24.0863 18.4437 24.4417 18.7312 24.725C19.0187 25.0083 19.375 25.15 19.8 25.15ZM11.2 37H36.85V17.15H11.2V37Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default OrderIcon;
