import React from "react";

function MasterCardIcon() {
  return (
    <svg
      width="101"
      height="60"
      viewBox="0 0 101 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.8441 30.1667C99.8488 46.4787 86.6287 59.7047 70.3167 59.709C54.0044 59.713 40.7787 46.4937 40.7744 30.1817C40.7744 30.1764 40.7744 30.172 40.7744 30.1667C40.7701 13.855 53.9901 0.628358 70.3014 0.624025C86.6138 0.619691 99.8398 13.8397 99.8441 30.1517C99.8441 30.1567 99.8441 30.1614 99.8441 30.1667Z"
        fill="#FF9900"
      />
      <path
        d="M30.5004 0.63174C14.2851 0.73474 1.15576 13.9271 1.15576 30.1667C1.15576 46.4697 14.3874 59.7014 30.6908 59.7014C38.3424 59.7014 45.3171 56.7854 50.5654 52.0061C50.5654 52.0061 50.5644 52.0054 50.5631 52.0047H50.5694C51.6441 51.0254 52.6464 49.9687 53.5678 48.8421H47.5168C46.7088 47.8661 45.9744 46.8491 45.3148 45.7997H55.7508C56.3854 44.7841 56.9601 43.7294 57.4701 42.6371H43.5918C43.1188 41.6231 42.7081 40.5861 42.3574 39.5341H58.7058C59.6911 36.5901 60.2258 33.4404 60.2258 30.1664C60.2258 27.9957 59.9904 25.8794 59.5451 23.8417H41.4904C41.7141 22.7931 41.9964 21.7571 42.3331 20.7391H58.6874C58.3218 19.6557 57.8971 18.6001 57.4141 17.5767H43.5901C44.0888 16.5114 44.6538 15.4751 45.2848 14.4744H55.7131C55.0191 13.3687 54.2511 12.3124 53.4208 11.3117H47.6064C48.5054 10.2537 49.4951 9.25741 50.5694 8.32841C45.3208 3.54841 38.3441 0.63174 30.6908 0.63174C30.6271 0.63174 30.5641 0.631407 30.5004 0.63174Z"
        fill="#CC0000"
      />
      <path
        d="M96.881 45.6067C97.0416 45.6067 97.198 45.6484 97.3506 45.7304C97.504 45.8124 97.622 45.9307 97.7073 46.0844C97.7926 46.2364 97.8353 46.3967 97.8353 46.5627C97.8353 46.7267 97.793 46.8854 97.709 47.0374C97.6253 47.189 97.5073 47.3074 97.3553 47.3917C97.205 47.4747 97.046 47.5167 96.8806 47.5167C96.7153 47.5167 96.5566 47.4747 96.4053 47.3917C96.2536 47.307 96.1363 47.1894 96.051 47.0374C95.967 46.8854 95.9253 46.7267 95.9253 46.5627C95.9253 46.3967 95.9676 46.2364 96.0533 46.0844C96.1393 45.9307 96.258 45.8134 96.4103 45.7304C96.5643 45.6484 96.7206 45.6067 96.881 45.6067ZM96.881 45.765C96.7473 45.765 96.6166 45.7997 96.489 45.8687C96.3623 45.9377 96.2633 46.0354 96.192 46.1647C96.1196 46.292 96.0836 46.424 96.0836 46.5627C96.0836 46.7 96.119 46.8327 96.1886 46.9587C96.26 47.0844 96.3586 47.183 96.4846 47.2537C96.6116 47.324 96.7433 47.3587 96.8806 47.3587C97.0186 47.3587 97.1506 47.324 97.277 47.2537C97.403 47.183 97.5013 47.0844 97.5716 46.9587C97.6413 46.8327 97.676 46.7004 97.676 46.5627C97.676 46.424 97.6406 46.2917 97.569 46.1647C97.498 46.0354 97.3986 45.9377 97.271 45.8687C97.1446 45.7994 97.0146 45.765 96.881 45.765ZM96.4623 47.09V46.0627H96.8163C96.9363 46.0627 97.0236 46.072 97.0776 46.092C97.1316 46.111 97.1746 46.1434 97.207 46.191C97.2386 46.238 97.255 46.288 97.255 46.3414C97.255 46.4167 97.2283 46.482 97.1743 46.5374C97.1216 46.5927 97.05 46.6244 96.9613 46.631C96.9976 46.646 97.0266 46.665 97.0493 46.6857C97.091 46.7257 97.141 46.7934 97.201 46.889L97.326 47.09H97.124L97.0333 46.9284C96.9616 46.801 96.904 46.7217 96.8596 46.689C96.8293 46.666 96.785 46.654 96.7273 46.654H96.6296V47.091H96.463M96.6283 46.5117H96.8296C96.9256 46.5117 96.9906 46.497 97.0256 46.4687C97.061 46.4394 97.0786 46.402 97.0786 46.3547C97.0786 46.324 97.0706 46.2967 97.0536 46.2734C97.0363 46.249 97.013 46.2317 96.9826 46.2194C96.953 46.2077 96.8976 46.201 96.817 46.201H96.629V46.5117"
        fill="#FCB340"
      />
      <path
        d="M40.4917 38.6399L40.8851 35.9666C40.6701 35.9666 40.3541 36.0596 40.0747 36.0596C38.9801 36.0596 38.8434 35.4746 38.9294 35.0473L40.0081 29.6706H41.6721L42.0151 26.6363H40.4467L40.7661 24.7976H37.4854C37.4161 24.8669 35.6294 35.1383 35.6294 36.3896C35.6294 38.2413 36.6687 39.0653 38.1347 39.0523C39.2821 39.0426 40.1764 38.7249 40.4917 38.6399Z"
        fill="#000066"
      />
      <path
        d="M41.4873 33.544C41.4873 37.988 44.4203 39.0437 46.9196 39.0437C49.2266 39.0437 50.4363 38.509 50.4363 38.509L50.9903 35.4757C50.9903 35.4757 49.041 36.2684 47.4566 36.2684C44.0796 36.2684 44.6716 33.7504 44.6716 33.7504L51.1593 33.77C51.1593 33.77 51.5723 31.733 51.5723 30.9027C51.5723 28.8304 50.4433 26.2864 46.9906 26.2864C43.8286 26.287 41.4873 29.694 41.4873 33.544ZM47.0026 29.1024C48.7773 29.1024 48.45 31.097 48.45 31.2587H44.9586C44.9586 31.052 45.2883 29.1024 47.0026 29.1024Z"
        fill="#000066"
      />
      <path
        d="M66.9154 38.6389L67.4784 35.2109C67.4784 35.2109 65.9344 35.9846 64.876 35.9846C62.645 35.9846 61.75 34.2813 61.75 32.4509C61.75 28.7386 63.6694 26.6959 65.806 26.6959C67.4087 26.6959 68.6944 27.5956 68.6944 27.5956L69.2077 24.2646C69.2077 24.2646 67.6897 23.1683 66.0557 23.1619C61.1407 23.1426 58.3284 26.5646 58.3284 32.4803C58.3284 36.4013 60.411 39.0696 64.1637 39.0696C65.2247 39.0696 66.9154 38.6389 66.9154 38.6389Z"
        fill="#000066"
      />
      <path
        d="M23.1953 26.3352C21.039 26.3352 19.3863 27.0282 19.3863 27.0282L18.9297 29.7372C18.9297 29.7372 20.294 29.1829 22.3567 29.1829C23.5277 29.1829 24.3843 29.3145 24.3843 30.2662C24.3843 30.8442 24.2797 31.0575 24.2797 31.0575C24.2797 31.0575 23.3557 30.9805 22.9277 30.9805C19.8577 30.9805 17.3513 32.1412 17.3513 35.6405C17.3513 38.3982 19.2257 39.0305 20.3873 39.0305C22.6063 39.0305 23.4847 37.6295 23.5353 37.6255L23.5097 38.7882H26.2787L27.514 30.1282C27.514 26.4532 24.3087 26.3352 23.1953 26.3352ZM23.6747 33.3672C23.735 33.8959 23.538 36.3959 21.644 36.3959C20.6673 36.3959 20.4137 35.6492 20.4137 35.2085C20.4137 34.3472 20.8813 33.3142 23.1853 33.3142C23.722 33.3142 23.5843 33.3529 23.6747 33.3672Z"
        fill="#000066"
      />
      <path
        d="M30.457 38.9665C31.1653 38.9665 35.2143 39.1468 35.2143 34.9685C35.2143 31.0615 31.4663 31.8338 31.4663 30.2638C31.4663 29.4832 32.0773 29.2372 33.1943 29.2372C33.6373 29.2372 35.3433 29.3782 35.3433 29.3782L35.7396 26.6015C35.7396 26.6015 34.6356 26.3545 32.8383 26.3545C30.512 26.3545 28.1506 27.2832 28.1506 30.4605C28.1506 34.0612 32.088 33.6995 32.088 35.2162C32.088 36.2285 30.988 36.3118 30.14 36.3118C28.673 36.3118 27.3523 35.8082 27.3476 35.8325L26.928 38.5808C27.0043 38.6042 27.8193 38.9665 30.457 38.9665Z"
        fill="#000066"
      />
      <path
        d="M92.8542 23.8162L92.2859 28.0525C92.2859 28.0525 91.1016 26.4175 89.2486 26.4175C85.7622 26.4175 83.9656 29.8918 83.9656 33.8828C83.9656 36.4595 85.2469 38.9852 87.8652 38.9852C89.7489 38.9852 90.7932 37.6715 90.7932 37.6715L90.6549 38.7932H93.7142L96.1166 23.8392L92.8542 23.8162ZM91.5036 32.0498C91.5036 33.7108 90.6809 35.9298 88.9766 35.9298C87.8446 35.9298 87.3139 34.9795 87.3139 33.4885C87.3139 31.0502 88.4089 29.4418 89.7912 29.4418C90.9226 29.4418 91.5036 30.2185 91.5036 32.0498Z"
        fill="#000066"
      />
      <path
        d="M6.16831 38.806L8.08264 27.516L8.36397 38.806H10.5303L14.572 27.516L12.7816 38.806H16.001L18.48 23.832L13.366 23.793L10.324 32.961L10.2406 23.832H5.55397L3.03931 38.806H6.16831Z"
        fill="#000066"
      />
      <path
        d="M54.6191 38.8269C55.5344 33.6219 55.8604 29.5112 58.5297 30.3625C58.9131 28.3479 59.8267 26.5959 60.2441 25.7432C60.2441 25.7432 60.1121 25.5469 59.2871 25.5469C57.8787 25.5469 55.9984 28.4049 55.9984 28.4049L56.2794 26.6379H53.3507L51.3894 38.8265L54.6191 38.8269Z"
        fill="#000066"
      />
      <path
        d="M73.5021 26.3352C71.3447 26.3352 69.6921 27.0282 69.6921 27.0282L69.2357 29.7372C69.2357 29.7372 70.6007 29.1829 72.6624 29.1829C73.8337 29.1829 74.69 29.3145 74.69 30.2662C74.69 30.8442 74.5857 31.0575 74.5857 31.0575C74.5857 31.0575 73.662 30.9805 73.234 30.9805C70.1637 30.9805 67.6577 32.1412 67.6577 35.6405C67.6577 38.3982 69.5317 39.0305 70.6934 39.0305C72.9117 39.0305 73.7907 37.6295 73.841 37.6255L73.815 38.7882H76.5847L77.8197 30.1282C77.8201 26.4532 74.6147 26.3352 73.5021 26.3352ZM73.9807 33.3672C74.0407 33.8959 73.8437 36.3959 71.9501 36.3959C70.9727 36.3959 70.7194 35.6492 70.7194 35.2085C70.7194 34.3472 71.1867 33.3142 73.491 33.3142C74.028 33.3142 73.8907 33.3529 73.9807 33.3672Z"
        fill="#000066"
      />
      <path
        d="M81.0072 38.827C81.5099 34.9977 82.4402 29.6217 84.9175 30.3627C85.3005 28.348 84.9312 28.3534 84.1065 28.3534C82.6972 28.3534 82.3852 28.4047 82.3852 28.4047L82.6665 26.6377H79.7382L77.7769 38.8267L81.0072 38.827Z"
        fill="#000066"
      />
      <path
        d="M41.3112 37.6864L41.7049 35.0134C41.4899 35.0134 41.1736 35.1054 40.8946 35.1054C39.7999 35.1054 39.6792 34.5234 39.7489 34.0931L40.6332 28.6391H42.2969L42.6986 25.6824H41.1299L41.4492 23.8438H38.3046C38.2352 23.9131 36.4482 34.1848 36.4482 35.4354C36.4482 37.2871 37.4879 38.1118 38.9539 38.0984C40.1019 38.0888 40.9959 37.7711 41.3112 37.6864Z"
        fill="white"
      />
      <path
        d="M42.3076 32.59C42.3076 37.034 45.241 38.09 47.74 38.09C50.0466 38.09 51.0616 37.5744 51.0616 37.5744L51.6156 34.5414C51.6156 34.5414 49.8609 35.3144 48.2763 35.3144C44.8996 35.3144 45.491 32.7967 45.491 32.7967H51.8816C51.8816 32.7967 52.2943 30.759 52.2943 29.9287C52.2943 27.8567 51.263 25.332 47.8103 25.332C44.6483 25.3327 42.3076 28.7397 42.3076 32.59ZM47.8223 28.1484C49.5969 28.1484 49.2696 30.1427 49.2696 30.304H45.7783C45.7783 30.098 46.1083 28.1484 47.8223 28.1484Z"
        fill="white"
      />
      <path
        d="M67.7356 37.685L68.2983 34.2566C68.2983 34.2566 66.7553 35.0303 65.6963 35.0303C63.4656 35.0303 62.5709 33.327 62.5709 31.497C62.5709 27.7846 64.4903 25.7423 66.6269 25.7423C68.2293 25.7423 69.5153 26.642 69.5153 26.642L70.0286 23.311C70.0286 23.311 68.1216 22.5393 66.4869 22.5393C62.8566 22.5393 59.325 25.6886 59.325 31.6033C59.325 35.5253 61.2319 38.1156 64.9846 38.1156C66.0456 38.116 67.7356 37.685 67.7356 37.685Z"
        fill="white"
      />
      <path
        d="M24.0155 25.3809C21.8591 25.3809 20.2061 26.0739 20.2061 26.0739L19.7498 28.7829C19.7498 28.7829 21.1141 28.2285 23.1765 28.2285C24.3475 28.2285 25.2041 28.3602 25.2041 29.3119C25.2041 29.8899 25.0991 30.1032 25.0991 30.1032C25.0991 30.1032 24.1755 30.0259 23.7478 30.0259C21.0281 30.0259 18.1711 31.1865 18.1711 34.6859C18.1711 37.4432 20.0455 38.0759 21.2071 38.0759C23.4258 38.0759 24.3821 36.6362 24.4331 36.6319L24.3295 37.8335H27.0985L28.3338 29.1732C28.3338 25.4992 25.1288 25.3809 24.0155 25.3809ZM24.6891 32.4322C24.7491 32.9612 24.3575 35.4409 22.4641 35.4409C21.4875 35.4409 21.2335 34.6949 21.2335 34.2535C21.2335 33.3929 21.7011 32.3595 24.0055 32.3595C24.5415 32.3602 24.5988 32.4175 24.6891 32.4322Z"
        fill="white"
      />
      <path
        d="M31.277 38.0127C31.9853 38.0127 36.0346 38.1927 36.0346 34.0144C36.0346 30.1081 32.2863 30.8797 32.2863 29.3104C32.2863 28.5291 32.8973 28.2831 34.014 28.2831C34.4573 28.2831 36.163 28.4241 36.163 28.4241L36.5596 25.6474C36.5596 25.6474 35.4556 25.4004 33.6583 25.4004C31.332 25.4004 28.9706 26.3291 28.9706 29.5064C28.9706 33.1067 32.908 32.7454 32.908 34.2621C32.908 35.2744 31.808 35.3567 30.9603 35.3567C29.4933 35.3567 28.1723 34.8534 28.1676 34.8774L27.7483 37.6261C27.8243 37.6494 28.639 38.0127 31.277 38.0127Z"
        fill="white"
      />
      <path
        d="M93.7839 22.8893L93.1056 27.0983C93.1056 27.0983 91.9219 25.4633 90.0689 25.4633C87.1876 25.4633 84.7859 28.9373 84.7859 32.9289C84.7859 35.5056 86.0669 38.0303 88.6856 38.0303C90.5692 38.0303 91.6136 36.7169 91.6136 36.7169L91.4752 37.8386H94.5339L96.9362 22.8843L93.7839 22.8893ZM92.3236 31.0959C92.3236 32.7569 91.5012 34.9756 89.7962 34.9756C88.6646 34.9756 88.1342 34.0256 88.1342 32.5346C88.1342 30.0966 89.2292 28.4879 90.6109 28.4879C91.7429 28.4876 92.3236 29.2653 92.3236 31.0959Z"
        fill="white"
      />
      <path
        d="M6.98854 37.8515L8.90288 26.5615L9.18388 37.8515H11.3505L15.3922 26.5615L13.6019 37.8515H16.8212L19.3005 22.8772H14.3222L11.2219 32.0649L11.0605 22.8772H6.47154L3.95654 37.8515H6.98854Z"
        fill="white"
      />
      <path
        d="M55.4393 37.8724C56.3546 32.667 56.5243 28.4404 58.7086 29.214C59.091 27.1994 59.46 26.42 59.8773 25.5674C59.8773 25.5674 59.6816 25.5264 59.271 25.5264C57.8626 25.5264 56.8193 27.4504 56.8193 27.4504L57.0996 25.6834H54.1716L52.21 37.8724H55.4393Z"
        fill="white"
      />
      <path
        d="M74.9917 25.3809C72.8354 25.3809 71.1824 26.0739 71.1824 26.0739L70.7264 28.7829C70.7264 28.7829 72.0907 28.2285 74.153 28.2285C75.324 28.2285 76.18 28.3602 76.18 29.3119C76.18 29.8899 76.0757 30.1032 76.0757 30.1032C76.0757 30.1032 75.152 30.0259 74.724 30.0259C72.0047 30.0259 69.1477 31.1865 69.1477 34.6859C69.1477 37.4432 71.0217 38.0759 72.1834 38.0759C74.402 38.0759 75.3584 36.6362 75.409 36.6319L75.306 37.8335C75.306 37.8335 78.074 37.8335 78.075 37.8335L79.31 29.1732C79.3104 25.4992 76.105 25.3809 74.9917 25.3809ZM75.6664 32.4322C75.7264 32.9612 75.3344 35.4409 73.4404 35.4409C72.4637 35.4409 72.2104 34.6949 72.2104 34.2535C72.2104 33.3929 72.678 32.3595 74.982 32.3595C75.518 32.3602 75.575 32.4175 75.6664 32.4322Z"
        fill="white"
      />
      <path
        d="M81.8408 37.8724C82.7565 32.667 82.9262 28.4404 85.1098 29.214C85.4928 27.1994 85.8621 26.42 86.2791 25.5674C86.2791 25.5674 86.0835 25.5264 85.6728 25.5264C84.2645 25.5264 83.2212 27.4504 83.2212 27.4504L83.5015 25.6834H80.5735L78.6118 37.8724H81.8408Z"
        fill="white"
      />
      <path
        d="M96.8682 35.9917C97.0279 35.9917 97.1852 36.0327 97.3369 36.116C97.4899 36.1967 97.6089 36.3154 97.6942 36.469C97.7799 36.6217 97.8219 36.7807 97.8219 36.947C97.8219 37.1114 97.7799 37.27 97.6955 37.4217C97.6119 37.5734 97.4942 37.6924 97.3425 37.776C97.1912 37.8594 97.0325 37.9014 96.8679 37.9014C96.7019 37.9014 96.5432 37.8594 96.3915 37.776C96.2402 37.6917 96.1229 37.5734 96.0389 37.4217C95.9535 37.27 95.9119 37.1114 95.9119 36.947C95.9119 36.7807 95.9542 36.6217 96.0399 36.469C96.1259 36.3154 96.2452 36.1974 96.3975 36.116C96.5512 36.0327 96.7085 35.9917 96.8682 35.9917ZM96.8682 36.149C96.7345 36.149 96.6039 36.1837 96.4762 36.2534C96.3489 36.3214 96.2499 36.4197 96.1782 36.5484C96.1069 36.6754 96.0709 36.8084 96.0709 36.9464C96.0709 37.0844 96.1055 37.2164 96.1752 37.3424C96.2462 37.468 96.3449 37.5667 96.4722 37.6377C96.5982 37.707 96.7299 37.742 96.8682 37.742C97.0055 37.742 97.1382 37.707 97.2642 37.6377C97.3902 37.5667 97.4889 37.468 97.5589 37.3424C97.6292 37.2154 97.6635 37.0844 97.6635 36.9464C97.6635 36.8084 97.6279 36.6754 97.5565 36.5484C97.4855 36.4197 97.3865 36.3214 97.2585 36.2534C97.1319 36.184 97.0022 36.149 96.8682 36.149ZM96.4499 37.4744V36.4467H96.8035C96.9239 36.4467 97.0119 36.4564 97.0652 36.476C97.1192 36.4944 97.1615 36.5274 97.1945 36.575C97.2269 36.6224 97.2432 36.672 97.2432 36.7254C97.2432 36.8004 97.2159 36.866 97.1619 36.9214C97.1092 36.9767 97.0382 37.0087 96.9495 37.0147C96.9849 37.03 97.0142 37.0484 97.0369 37.069C97.0779 37.1097 97.1285 37.1777 97.1885 37.2727L97.3142 37.474H97.1112L97.0209 37.3124C96.9489 37.1847 96.8912 37.1054 96.8472 37.073C96.8169 37.0494 96.7725 37.0377 96.7142 37.0377H96.6172V37.4747L96.4499 37.4744ZM96.6159 36.896H96.8172C96.9135 36.896 96.9785 36.8817 97.0132 36.853C97.0482 36.825 97.0665 36.7867 97.0665 36.739C97.0665 36.7087 97.0582 36.6814 97.0415 36.6584C97.0245 36.6337 97.0009 36.616 96.9705 36.6037C96.9402 36.592 96.8859 36.586 96.8045 36.586H96.6162V36.896"
        fill="white"
      />
    </svg>
  );
}

export default MasterCardIcon;
