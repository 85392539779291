import clsx from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FaChevronCircleUp, FaChevronCircleDown, FaStar } from "react-icons/fa";
import classes from "./OrderTab.module.css";
import { IoIosArrowDropup } from "react-icons/io";
import ReviewModal from "./ReviewModal/ReviewModal";

function ContextAwareToggle({
  eventKey,
  callback,
  value,
  expandedItems,
  setExpandedItems,
}) {
  const isCurrentEventKey = expandedItems.includes(eventKey);

  const decoratedOnClick = () => {
    const newExpandedItems = isCurrentEventKey
      ? expandedItems.filter((key) => key !== eventKey)
      : [...expandedItems, eventKey];

    setExpandedItems(newExpandedItems);
    if (callback) {
      callback(eventKey);
    }
  };

  return (
    <div onClick={decoratedOnClick} className={classes.otherOrderList}>
      <div className={classes.otherOrdersDetailsAndArrowIcon}>
        <div className={classes.itemImageAndDetails}>
          <img
            src={
              value.details.images.find((v) => v.is_thumbnail === true)
                .url_thumbnail
            }
            className={classes.otherOrderProductImage}
          />
          <div className={classes.details}>
            <p className={classes.productTitle}>{value.name}</p>
            <p className={classes.productSize}>
              {
                value.product_options.find((v) => v.display_name === "Size")
                  .display_value
              }
            </p>
          </div>
        </div>
        {isCurrentEventKey ? (
          <FaChevronCircleUp style={{ color: "#eb2675" }} />
        ) : (
          <FaChevronCircleDown style={{ color: "#eb2675" }} />
        )}
      </div>
    </div>
  );
}

function OtherItemsAccordion({
  v,
  i,
  orderDetails,
  setHovered,
  orderIndex,
  hovered,
  setReRender
}) {
  const [expandedItems, setExpandedItems] = useState([]);
  const itemRefs = useRef("");
  const [rating, setRating] = useState(0);
  const [isReviewModalOpen,setIsReviewModalOpen] = useState(false)
  const [currentProduct,setCurrentProduct]=useState()

  const scrollToItem = (itemId) => {
    const itemRef = itemRefs.current;
    if (itemRef) {
      itemRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  useEffect(() => {
    setExpandedItems([orderIndex - 1]);
    scrollToItem(orderIndex - 1);
  }, [orderIndex]);

  useEffect(()=>{
    v?.reviewDetails[0]?.rating && setRating(v?.reviewDetails[0]?.rating ?? 0)
  },[v])

  const handleReviewModalClose = ()=>{
    setIsReviewModalOpen(false)
  }
  console.log(v)
  return (
    <>
    {isReviewModalOpen && <ReviewModal setReRender={setReRender} handleClose={handleReviewModalClose} rating={rating} productDetails={currentProduct}/>}
        <div style={{ marginBottom: "10px" }}>
          <ContextAwareToggle
            eventKey={i}
            value={v}
            expandedItems={expandedItems}
            setExpandedItems={setExpandedItems}
          />

          <Accordion.Collapse eventKey={i} in={expandedItems.includes(i)}>
            <div className={`${classes.list} ${classes.orderDetails}`}>
              <div className={classes.orderIdAndDeliverStatus}>
                <p className={classes.orderId}>
                  <b>Order ID</b> #{orderDetails.id}
                </p>
              </div>
              <div className={classes.itemImageAndDetails}>
                <img
                  src={
                    v.details.images.find((v) => v.is_thumbnail === true)
                      .url_thumbnail
                  }
                />
                <div className={classes.orderDetailsListContent}>
                  <div className={classes.details}>
                    <p className={classes.productTitle}>{v.name}</p>
                    <p className={classes.productSize}>
                      {
                        v.product_options.find((v) => v.display_name === "Size")
                          .display_value
                      }
                    </p>
                    <p className={classes.productSize}>
                      QTY: <span>{v.quantity}</span>
                    </p>
                  </div>
                  <div
                    className={clsx(classes.details, classes.addressContent)}
                  >
                    <p className={classes.heading}>
                      <b>Delivery Address</b>
                    </p>
                    <div className={classes.nameAndPhonenumber}>
                      <p className={classes.name}>
                        <b>
                          {orderDetails.shipping_address[0].first_name}{" "}
                          {orderDetails.shipping_address[0].last_name}
                        </b>
                      </p>
                      <p className={classes.middlePipe}></p>
                      <p className={classes.phoneNumber}>
                        <b>{orderDetails.shipping_address[0].phone}</b>
                      </p>
                    </div>
                    <p className={classes.addressText}>
                      {`${orderDetails.shipping_address[0].street_1},
                      ${orderDetails.shipping_address[0].city},
                      ${orderDetails.shipping_address[0].state},
                      ${orderDetails.shipping_address[0].zip}`}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`${classes.ratingAndReviwOrderDetails} ${classes.orderIdAndDeliverStatus}`}
              >
                <div className={classes.rateProduct}>
                  <p className={classes.heading}>Rate this product</p>
                  <div className={classes.ratingStars}>
                    {[...Array(5)].map((v, i) => {
                      const starValue = i + 1;
                      return (
                        <FaStar
                          key={i}
                          onMouseEnter={() => setHovered(starValue)}
                          onMouseLeave={() => setHovered(0)}
                          onClick={() => setRating(starValue)}
                          color={
                            starValue <= (hovered || rating)
                              ? "#eb2675"
                              : "#e4e5e9"
                          }
                          style={{ cursor: "pointer" }}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className={classes.writeReview}>
                <p onClick={()=>{
                  setIsReviewModalOpen(true)
                  setCurrentProduct(v)
                  }}>{v?.reviewDetails.length !== 0 ? "Update a Review" : "Write a Review"}</p>
                </div>
              </div>
              <div
                className={`${classes.trackingSection} ${classes.orderIdAndDeliverStatus}`}
              >
                <div className={classes.deliveryDates}>
                  <p>Nov 20, 2024</p>
                  <p>Nov 21, 2024</p>
                  <p>Nov 22, 2024</p>
                  <p>Nov 24, 2024</p>
                </div>
                <div className={classes.deliveryLine}></div>
                <div className={classes.deliveryTrack}>
                  <p>Order Confirmed</p>
                  <p>Shipped</p>
                  <p>Out for delivery</p>
                  <p>Delivered</p>
                </div>
              </div>
              <div className={classes.orderIdAndDeliverStatus}>
                <div className={classes.totalPriceSection}>
                  <p className={classes.heading}>
                    Total Item Price
                    <br />
                    {v.applied_discounts.length === 0
                          ? <span className={classes.savedPrice}>No Discounts applied!</span>
                          : <span className={classes.savedPrice}>
                          You saved{" "}
                          <span className={classes.greenColor}>
                          {orderDetails.currency_code==='INR'?`₹`:`$`}
                            { parseFloat(v.applied_discounts[0].amount).toFixed(
                                  2
                                )}
                          </span>{" "}
                          on this order
                        </span>}
                  </p>
                  <div className={classes.priceAndPayementMethod}>
                    <p className={classes.priceText}>
                    {orderDetails.currency_code==='INR'?`₹`:`$`}{parseFloat(v.total_inc_tax).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Collapse>
        </div>
    </>
  );
}

export default OtherItemsAccordion;
