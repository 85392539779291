import React from "react";

function AddressTabIcon() {
  return (
    <svg
      width="180"
      height="180"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_81_283)">
        <path
          d="M0 95.0023H31.5674L36.2549 75.6298L10.3149 78.7548L0 95.0023ZM67.5049 64.6923L80.9326 63.1298L76.8799 56.245H65.0024L67.5049 64.6923ZM84.6924 70.0023L51.8799 73.7499L52.5024 95.0023H100L84.6924 70.0023Z"
          fill="#5A5254"
        />
        <path
          d="M64.6851 13.7524C58.7524 7.80762 51.5625 5 43.4326 5C35.3027 5 28.125 7.80762 22.1802 13.7524C16.2476 19.6851 13.4277 26.5698 13.4277 34.6875C13.4277 42.1826 16.8701 49.3726 23.4253 56.8799L23.7427 57.1973L32.8003 68.1348C34.3628 70.6372 35.9253 72.8223 37.1704 75.3247C39.6728 80.3296 41.8579 86.8848 43.7378 95.3198C45.6177 86.8848 47.8027 80.3174 50.3052 75.3247C52.8076 70.3198 56.5552 65.0098 61.5601 59.3823L63.4399 56.8799C70.0073 49.3848 73.4375 42.1948 73.4375 34.6875C73.4375 26.5576 70.6299 19.3799 64.6851 13.7524ZM43.1274 49.9951C34.375 49.9951 27.1851 42.8052 27.1851 34.0527C27.1851 25.3003 34.375 18.1104 43.1274 18.1104C51.8799 18.1104 59.0698 25.3003 59.0698 34.0527C59.0698 42.8052 51.8799 49.9951 43.1274 49.9951Z"
          fill="#EB2675"
        />
        <path
          d="M15.6251 70.0022L32.8126 68.1223L23.755 57.1848L15.6251 70.0022Z"
          fill="#5A5254"
        />
      </g>
      <defs>
        <clipPath id="clip0_81_283">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AddressTabIcon;
