import React from "react";

function CardPayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_36_121"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_36_121)">
        <path
          d="M44 11V37C44 37.8 43.7 38.5 43.1 39.1C42.5 39.7 41.8 40 41 40H7C6.2 40 5.5 39.7 4.9 39.1C4.3 38.5 4 37.8 4 37V11C4 10.2 4.3 9.5 4.9 8.9C5.5 8.3 6.2 8 7 8H41C41.8 8 42.5 8.3 43.1 8.9C43.7 9.5 44 10.2 44 11ZM7 16.45H41V11H7V16.45ZM7 22.9V37H41V22.9H7Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default CardPayIcon;
