import React from "react";

function WalletsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_36_133"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_36_133)">
        <path
          d="M9 42C8.175 42 7.46875 41.7063 6.88125 41.1188C6.29375 40.5313 6 39.825 6 39V9C6 8.175 6.29375 7.46875 6.88125 6.88125C7.46875 6.29375 8.175 6 9 6H39C39.825 6 40.5313 6.29375 41.1188 6.88125C41.7063 7.46875 42 8.175 42 9V15.7H39V9H9V39H39V32.35H42V39C42 39.825 41.7063 40.5313 41.1188 41.1188C40.5313 41.7063 39.825 42 39 42H9ZM26.9 33.35C25.8825 33.35 25.0115 32.9926 24.2869 32.2778C23.5623 31.563 23.2 30.7037 23.2 29.7V18.35C23.2 17.3463 23.5623 16.487 24.2869 15.7722C25.0115 15.0574 25.8825 14.7 26.9 14.7H40.4C41.4175 14.7 42.2886 15.0574 43.0132 15.7722C43.7377 16.487 44.1 17.3463 44.1 18.35V29.7C44.1 30.7037 43.7377 31.563 43.0132 32.2778C42.2886 32.9926 41.4175 33.35 40.4 33.35H26.9ZM41.1 30.35V17.7H26.2V30.35H41.1ZM32.6118 27.2C33.5039 27.2 34.2583 26.884 34.875 26.2521C35.4917 25.6201 35.8 24.8528 35.8 23.95C35.8 23.075 35.4833 22.3313 34.85 21.7188C34.2167 21.1062 33.4667 20.8 32.6 20.8C31.7333 20.8 30.9833 21.1062 30.35 21.7188C29.7167 22.3313 29.4 23.075 29.4 23.95C29.4 24.8528 29.7123 25.6201 30.3368 26.2521C30.9613 26.884 31.7196 27.2 32.6118 27.2Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default WalletsIcon;
