import React, { Fragment, memo, useEffect, useState } from "react";
import classes from "./OrderTab.module.css";
import { IoIosSearch } from "react-icons/io";
import axios from "axios";
import Loader from "../../Loader/Loader";
import OrderDetails from "./OrderDetails";
import { FaStar } from "react-icons/fa";
import clsx from "clsx";
import { getBigComToken, getUserId } from "../../../utils/getUserId";
import OrderListFilterModal from "./OrdersListFilterModal";
import { userDetailsStore } from "../../../store/GlobalUserData";
import Bill from "./Bill/Bill";
import { useCurrencyCode } from "../../../store/CountStore";

function OrderTab() {
  const [allOrders, setAllOrders] = useState([]);
  const [dataToFilter, setDataToFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openOrderFilter, setOpenOrderFilter] = useState(false);
  const [orderIndex, setOrderIndex] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [orderDetails, closeOrderDetails] = useState(false);
  const [orderId, setOrderId] = useState("");
  const { userDetails } = userDetailsStore();
  const customer = getUserId();
  const [BillId, setBillId] = useState("");
  const bigComToken = getBigComToken();
  const { defaultCurrency } = useCurrencyCode();
  const [billPreviewOpen, setBillPreviewOpen] = useState(false);

  const getAllOrders = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_PATH}getOrders`,
        {
          customer_id: customer,
          email: userDetails.email,
          currencyCode: defaultCurrency,
        },
        { headers: { Authorization: bigComToken } }
      )
      .then((response) => {
        setAllOrders(response.data);
        setDataToFilter(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if(customer){
      getAllOrders();
    }
  }, []);

  const downloadInvoice = async (id) => {
    setBillId(id);
    setBillPreviewOpen(true);
  };
  useEffect(() => {
    if (!billPreviewOpen) {
      document.body.style.overflow = "auto";
    }
  }, [billPreviewOpen]);

  const filterOrderData = (e) => {
    let value = e.target.value
    setSearchOrder(value);
    if (value.length === 0) {
      setAllOrders(dataToFilter);
    } else {
      setAllOrders(
        dataToFilter.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  return (
    <div className={classes.tabcontent}>
      {orderDetails ? (
        <OrderDetails
          orderIndex={orderIndex}
          orderId={orderId}
          setOrderId={setOrderId}
          closeOrderDetails={closeOrderDetails}
        />
      ) : (
        <Fragment>
          <div className={classes.allOrdersHeaderWrapper}>
            <p className={classes.heading}>All Orders</p>
            <div className={classes.searchAndFilterWrapper}>
              <form className={classes.searchBoxForm}>
                <input
                  type="search"
                  placeholder="Search..."
                  name="search"
                  className={classes.searchBoxInput}
                  onkeyup="buttonUp();"
                  required=""
                  value={searchOrder}
                  onChange={(e) => filterOrderData(e)}
                  onPaste={(e) => filterOrderData(e)}
                />
                <span className={classes.searchBoxIcon}>
                  <IoIosSearch className={classes.searchIcon} />
                </span>
              </form>
              {/* <button
                // onClick={() => setOpenOrderFilter(true)}
                className={classes.filterBtn}
              >
                <i className="pe-7s-edit"></i>
                Filter
              </button> */}
            </div>
          </div>
          <div className={classes.orderListWrapper}>
            {loading ? (
              <Loader />
            ) : allOrders.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>No Records Found!</span>
              </div>
            ) : (
              allOrders?.map((value, index) => {
                return (
                  <Fragment>
                    <div className={classes.list}>
                      <div className={classes.orderDetailsWrapper}>
                        <p className={classes.orderID}>
                          <b>Order ID</b>
                          <br />
                          {value.order_id}
                        </p>
                        <p className={classes.deliveryStauts}>
                          {value.status}{" "}
                          <span
                            onClick={() => setOrderId(value.order_id)}
                            className={classes.trackOrderText}
                          >
                            (Track your order)
                          </span>
                          <br />
                          <span>On {value.date_created}</span>
                        </p>
                      </div>

                      <div className={classes.imagedetailsWrapper}>
                        <img
                          src={value?.details?.images?.edges[0]?.node.urlOriginal}
                        />
                        <div>
                          <p className={classes.productTitle}>{value.name}</p>
                          <p className={classes.productSize}>
                            {
                              value.product_options.find(
                                (v) => v?.display_name === "Size"
                              )?.display_value
                            }
                          </p>
                          <p className={classes.returnWinodw}>
                            Exchange/Return window closed on 24 April 2024
                          </p>
                        </div>
                      </div>
                      <div className={classes.orderDetailsAndRatingReview}>
                        <div
                          className={classes.productreviewOrVieworderdetails}
                        >
                          {value.product_options.length !== 0 &&  <button
                            onClick={() => {
                              closeOrderDetails(true);
                              setOrderId(value.order_id);
                              setOrderIndex(index);
                            }}
                            className={clsx(
                              classes.viewOrderDetails,
                              classes.filterBtn
                            )}
                          >
                            View Order Details
                          </button>}
                          <button
                            className={classes.btn2}
                            onClick={() => downloadInvoice(value.order_id)}
                          >
                            Get Invoice
                          </button>
                        </div>
                        {value.product_options.length !== 0 &&   <div className={classes.ratingAndReview}>
                          <div className={classes.rating}>
                            <a>
                              <p>
                                {value?.details?.reviewSummary
                                  .summationOfRatings === 0
                                  ? 0
                                  : (
                                      value.details?.reviewSummary
                                        .summationOfRatings /
                                      value.details?.reviewSummary
                                        .numberOfReviews
                                    ).toFixed(1)}
                              </p>
                              <FaStar />
                            </a>
                          </div>
                          <div className={classes.review}>
                            <a>
                              <p>
                                & {value.details?.reviewSummary.numberOfReviews}{" "}
                                Reviews
                              </p>
                            </a>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </Fragment>
                );
              })
            )}
          </div>
        </Fragment>
      )}
      <OrderListFilterModal
        openModal={openOrderFilter}
        handleClose={() => setOpenOrderFilter(false)}
      />
      {billPreviewOpen && (
        <Bill
          open={billPreviewOpen}
          handleClose={() => setBillPreviewOpen(false)}
          orderId={BillId}
        />
      )}
    </div>
  );
}

export default memo(OrderTab);
