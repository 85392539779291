import React from "react";

function OTPicon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_272_647)">
        <path
          d="M79.0002 40.0001C79.0002 61.5392 61.5392 79.0002 40.0001 79.0002C18.4609 79.0002 1 61.5392 1 40.0001C1 18.4609 18.4609 1 40.0001 1C61.5392 1 79.0002 18.4609 79.0002 40.0001Z"
          stroke="#EB2675"
          strokeWidth="2"
        />
        <path
          d="M24.3744 15.832C22.8844 15.832 21.6768 17.0396 21.6768 18.5296V61.7016C21.6768 63.1916 22.8844 64.3992 24.3744 64.3992H55.4037C56.8937 64.3992 58.1013 63.1916 58.1013 61.7016V28.3272L45.0952 15.832H24.3744Z"
          fill="#EB2675"
        />
        <path
          d="M58.1013 28.3272H47.7933C46.3033 28.3272 45.0957 27.1196 45.0957 25.6296V15.832L58.1013 28.3272Z"
          fill="#921346"
        />
        <path
          d="M44.5578 45.8754C44.5578 47.5953 43.1638 48.9893 41.4453 48.9893H18.716C16.9961 48.9893 15.6035 47.5953 15.6035 45.8754V36.3633C15.6035 34.6434 16.9963 33.2501 18.716 33.2501H41.4453C43.1638 33.2501 44.5578 34.6434 44.5578 36.3633V45.8754Z"
          fill="#921346"
        />
        <path
          d="M24.353 38.1715C25.0093 38.1715 25.5732 38.3048 26.0432 38.5707C26.5143 38.8366 26.8706 39.2155 27.1119 39.7064C27.3531 40.1971 27.4744 40.7732 27.4744 41.4358C27.4744 41.9253 27.4088 42.3699 27.2761 42.7698C27.1434 43.1697 26.9451 43.5171 26.6806 43.8101C26.4159 44.1031 26.0899 44.328 25.7047 44.4835C25.3195 44.6387 24.8768 44.7166 24.379 44.7166C23.8837 44.7166 23.4409 44.6371 23.0495 44.4772C22.658 44.3174 22.3314 44.0925 22.0693 43.8013C21.8071 43.5101 21.6094 43.1609 21.4756 42.7522C21.3422 42.3436 21.2754 41.9015 21.2754 41.4265C21.2754 40.9402 21.3449 40.4935 21.4845 40.088C21.6248 39.6819 21.8269 39.337 22.0915 39.0516C22.3562 38.7662 22.679 38.5489 23.0586 38.3973C23.4379 38.2454 23.8692 38.1715 24.353 38.1715ZM26.1747 41.4268C26.1747 40.9633 26.1001 40.5615 25.9505 40.2222C25.8009 39.883 25.5872 39.626 25.3087 39.4517C25.0314 39.2773 24.7125 39.1902 24.3525 39.1902C24.0967 39.1902 23.8597 39.2381 23.642 39.3349C23.4247 39.4314 23.2377 39.5717 23.0805 39.7562C22.9239 39.9407 22.8007 40.1757 22.7092 40.4623C22.6189 40.7491 22.5735 41.0705 22.5735 41.4273C22.5735 41.7874 22.6189 42.112 22.7092 42.4031C22.8008 42.6937 22.9283 42.935 23.0939 43.1263C23.2593 43.3177 23.4495 43.461 23.6636 43.5557C23.8776 43.6504 24.1133 43.6985 24.3692 43.6985C24.6977 43.6985 24.999 43.6165 25.2736 43.4522C25.5489 43.288 25.7675 43.0348 25.9299 42.6925C26.0931 42.3496 26.1747 41.9278 26.1747 41.4268Z"
          fill="white"
        />
        <path
          d="M32.3445 39.3375H30.9455V43.9357C30.9455 44.2004 30.886 44.3968 30.7686 44.525C30.6511 44.6533 30.4976 44.7171 30.3106 44.7171C30.1205 44.7171 29.9657 44.6521 29.8471 44.5226C29.7277 44.3931 29.6676 44.1973 29.6676 43.9352V39.337H28.2686C28.0495 39.337 27.8871 39.2891 27.7809 39.1924C27.6748 39.0958 27.6211 38.9687 27.6211 38.8102C27.6211 38.646 27.6766 38.5165 27.7872 38.4218C27.8984 38.3271 28.0588 38.279 28.2691 38.279H32.345C32.5666 38.279 32.7315 38.3283 32.8389 38.4255C32.9476 38.5234 33.0013 38.6517 33.0013 38.8095C33.0013 38.9674 32.9463 39.095 32.837 39.1917C32.7278 39.2883 32.5636 39.3375 32.3445 39.3375Z"
          fill="white"
        />
        <path
          d="M36.2171 42.1437H35.043V43.9355C35.043 44.1919 34.9824 44.3859 34.8617 44.5185C34.741 44.6512 34.5882 44.7169 34.4037 44.7169C34.2104 44.7169 34.0551 44.6512 33.9376 44.5204C33.8202 44.3896 33.7607 44.197 33.7607 43.9438V39.0652C33.7607 38.7828 33.8257 38.5815 33.9552 38.4608C34.0847 38.3401 34.2906 38.2795 34.5728 38.2795H36.2176C36.7046 38.2795 37.0778 38.3168 37.34 38.3919C37.5989 38.464 37.8231 38.5833 38.0115 38.7501C38.2004 38.9169 38.343 39.1215 38.4416 39.3634C38.5402 39.6052 38.5881 39.8768 38.5881 40.1793C38.5881 40.8242 38.3898 41.3125 37.9926 41.6454C37.5954 41.9782 37.0029 42.1437 36.2171 42.1437ZM35.9059 39.2422H35.0424V41.1768H35.9059C36.2084 41.1768 36.4611 41.1452 36.6631 41.0821C36.8665 41.019 37.0206 40.9153 37.1273 40.7713C37.234 40.6273 37.2872 40.4391 37.2872 40.2053C37.2872 39.9261 37.2051 39.6988 37.0409 39.5232C36.8571 39.3355 36.4793 39.2422 35.9059 39.2422Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_272_647">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OTPicon;
