import React from "react";

function WishlistIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_62_63"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_62_63)">
        <path
          d="M24 41.95L21.95 40.1C18.4244 36.8626 15.5097 34.0699 13.2058 31.7219C10.9019 29.374 9.06667 27.275 7.7 25.425C6.33333 23.575 5.375 21.9 4.825 20.4C4.275 18.9 4 17.3833 4 15.85C4 12.8448 5.00833 10.3352 7.025 8.32114C9.04167 6.30704 11.5333 5.29999 14.5 5.29999C16.4 5.29999 18.1583 5.74999 19.775 6.64999C21.3917 7.54999 22.8 8.84999 24 10.55C25.4 8.74999 26.8833 7.42499 28.45 6.57499C30.0167 5.72499 31.7 5.29999 33.5 5.29999C36.4667 5.29999 38.9583 6.30704 40.975 8.32114C42.9917 10.3352 44 12.8448 44 15.85C44 17.3833 43.725 18.9 43.175 20.4C42.625 21.9 41.6667 23.575 40.3 25.425C38.9333 27.275 37.0981 29.374 34.7942 31.7219C32.4903 34.0699 29.5756 36.8626 26.05 40.1L24 41.95ZM24 38C27.3745 34.9002 30.1515 32.2419 32.3309 30.0251C34.5103 27.8084 36.2417 25.8667 37.525 24.2C38.8083 22.5333 39.7083 21.0477 40.225 19.7432C40.7417 18.4387 41 17.1434 41 15.8572C41 13.6524 40.3 11.8417 38.9 10.425C37.5 9.00832 35.7037 8.29999 33.5112 8.29999C31.7938 8.29999 30.2042 8.82499 28.7425 9.87499C27.2808 10.925 26.1 12.4 25.2 14.3H22.75C21.8833 12.4333 20.7192 10.9667 19.2575 9.89999C17.7958 8.83332 16.2062 8.29999 14.4887 8.29999C12.2962 8.29999 10.5 9.00832 9.1 10.425C7.7 11.8417 7 13.6553 7 15.8658C7 17.1553 7.25833 18.4583 7.775 19.775C8.29167 21.0917 9.19167 22.5917 10.475 24.275C11.7583 25.9583 13.5 27.9 15.7 30.1C17.9 32.3 20.6667 34.9333 24 38Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default WishlistIcon;
