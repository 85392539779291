import React from "react";
import "./VTcontent.css";
import ProductDescription from "./ProductDescription";
import AdditionalInformation from "./AdditionalInformation";
import Reviews from "./Reviews";

function VTcontent(props) {
  return (
    <div
      className="section__Jobs-styledContent"
    >
      {props.activeTabId === "Product description" ? <ProductDescription/> : props.activeTabId==="Additional Information" ? <AdditionalInformation additionalInformation={props.additionalInformation}/> : <Reviews/>}
    </div>
  );
}

export default VTcontent;
