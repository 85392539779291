import React, { useState } from "react";
import classes from "./DeleteAccount.module.css";
import DeleteAccountIcon from "../../../Svgs/DeleteAccountIcon";
import axios from "axios";
import { getUserId } from "../../../utils/getUserId";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import { useCountStore } from "../../../store/CountStore";
import { addToCartCountStore } from "../../../store/CartCountStore";
import { userDetailsStore } from "../../../store/GlobalUserData";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginPath } from "../../../constant/constant";
import CommonConsentPopup from "../../CommonComponents/CommonConsentPopup";

function DeleteAccount() {
  const [ischecked, setIsChecked] = useState(false);
  const userId = getUserId();
  const [openConsent, setOpenConsent] = useState(false)
  const { updateCount, updateWishListIds } = useCountStore();
  const { setAddToCartCount, setItemIdsinCart } = addToCartCountStore();
  const { setUserDetails } = userDetailsStore();
  const navigate = useNavigate();
  const location = useLocation()

  const handleDeleteAccount = () => {
    // if (!ischecked) {
      // showErrorToast("Please agree terms and conditions ");
    // } else {
      axios
        .post(`${process.env.REACT_APP_API_PATH}deleteCustomer`,{ id : userId })
        .then((res) => {
          showSuccessToast('Account Deleted Successfully!')
          setTimeout(()=>{
            handleLogout()
          },1000)
        })
        .catch((err) => console.log({ err }));
    // }
  };

  const handleLogout = () => {
    document.cookie = "userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setUserDetails({
      user_fname: "",
      user_lname: "",
      user_phone: "",
      userId: "",
    });
    updateCount(0);
    setAddToCartCount(0);
    updateWishListIds([]);
    setItemIdsinCart([]);
    localStorage.clear();
    navigate(LoginPath,{state:{from: location.pathname + location.search}});
    localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
  };
  return (
    <div className={classes.tabcontent}>
      <div className={classes.deleteAccountWrapper}>
        <div className={classes.deleteAccountIcon}>
          <DeleteAccountIcon />
        </div>
        <p className={classes.heading}>
          Is this goodbye? Are you sure you don't want to reconsider?
        </p>
        <ul className={classes.deleteAccountUl}>
          <li>
            <p>
              <b>
                You'll lose your order history, saved details, and all other
                benefits.
              </b>
            </p>
            <p>
              Any account related benefits will be forfeited once the account is
              deleted and will no longer be available to you. You cannot recover
              the same. However, you can always create a new account. By
              deleting your account, you acknowledge you have read our Privacy
              Policy.
            </p>
          </li>
          <li>
            <p>
              <b>
                Any pending orders, exchanges, returns or refunds will no longer
                be accessible via your account.
              </b>
            </p>
            <p>
              Upvan Fashion will try to complete the open transactions in the
              next 30 days on a best effort basis. However, we cannot ensure
              tracking & traceability of transactions once the account is
              deleted.
            </p>
          </li>
          <li>
            <p>
              Upvan Fashion may not extend New User coupon if an account is
              created with the same mobile number or email id.
            </p>
          </li>
          <li>
            <p>
              Upvan Fashion may refuse or delay deletion in case there are any
              pending grievances related to orders, shipments, cancellations or
              any other services offered by Upvan Fashion.
            </p>
          </li>
          <li>
            <p>
              Upvan Fashion may retain certain data for legitimate reasons such
              as security, fraud prevention, future abuse, regulatory compliance
              including exercise of legal rights or comply with legal orders
              under applicable laws.
            </p>
          </li>
        </ul>
        {/* <div className={classes.agreeText}>
          <input
            type="checkbox"
            className="theme-checkbox"
            name="ischecked"
            value={ischecked}
            onChange={(e) => setIsChecked(!ischecked)}
          />
          <p>I agree to all the terms and conditions*</p>
        </div> */}
        <div className={classes.deleteAccountBtnsWrapper}>
          <button
            className={classes.secondaryBtn}
            onClick={()=>setOpenConsent(true)}
          >
            Delete Anyway
          </button>
          <button className={classes.primaryBtn}>Keep Account</button>
        </div>
      </div>
      <CommonConsentPopup modalType="Delete Account" open={openConsent} handleClose={()=> setOpenConsent(false)} handleSubmit={handleDeleteAccount} ><p>Once you delete, you will lose all invoices and past order data. Do you want to delete your account?</p></CommonConsentPopup>
    </div>
  );
}

export default DeleteAccount;
