import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import classes from "../navbar/NavbarMenu.module.css";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HomePath, navbarData } from "../../constant/constant";
import { useMobileScreen } from "../../utils/isMobileScreen";
import { Accordion } from "react-bootstrap";
import SearchModal from "./SearchModal/SearchModal";
import RightSideMenu from "./RightSideMenu/RightSideMenu";
import { useProductsPageStore } from "../../store/ProductsPageStore";
import _ from "lodash";
import axios from "axios";
import { getBigComToken, getRefreshToken, getUserId } from "../../utils/getUserId";
import { jwtDecode } from 'jwt-decode'
import { createBigComToken, createRefreshToken } from "../CommonComponents/CommonHelperFunctions";
import { useCountStore } from "../../store/CountStore";
import { userDetailsStore } from "../../store/GlobalUserData";

function NavbarMenu() {
  let screen = useMobileScreen();
  const {updateCount,count} = useCountStore()
  const [search,setSearch] = useState('')
  const {setSearchedProduct} = useProductsPageStore();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const refreshToken = getRefreshToken()
  const BigComToken = getBigComToken()
  const pathname = location.pathname;
  const userId = getUserId();
  const { setUserDetails } = userDetailsStore();

  const searchInputHandler = (e) => {
    setSearch(e.target.value);
    debounceSearch(e.target.value, 'products-page')
  };

  const navigate = useNavigate();

  const searchHandler = (e) => {
    setSearch(e.target.value);
    debounceSearch(e.target.value, 'any-page')
    sessionStorage.setItem("search", search);
    handleSearchModalClose();
  };

  const debounceSearch = useCallback(
    _.debounce((value, flag = 'home-page') => {
      flag === 'any-page' && navigate('/products')
      setSearchedProduct(value);
    }, 700),
    []
  );
  
  const handleSearchModalClose = () => {
    setIsSearchModalOpen(false);
  };

  const handleCheckForExpiry = async() => {
    axios
    .get(`${process.env.REACT_APP_API_PATH}createBigComToken`)
    .then((res) => {
      console.log("respones", res)
      document.cookie =
      `BigComToken=${res?.data?.data?.token}`
      updateCount(count + 1)
    }).catch(err => console.log(err))

     axios
    .get(`${process.env.REACT_APP_API_PATH}createRefreshToken`)
    .then((res) => {
      document.cookie =
    `RefreshToken=${res?.data?.token}`;
    }).catch(err=>console.log(err))
    }

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
     
    if (refreshToken && BigComToken) {
      let RefreshTokenCheck = jwtDecode(refreshToken)
      currentTime >= RefreshTokenCheck.exp && handleCheckForExpiry()
    } else {
           axios
          .get(`${process.env.REACT_APP_API_PATH}createBigComToken`)
          .then((res) => {
            console.log("respones", res)
            document.cookie =
            `BigComToken=${res?.data?.data?.token}`
            updateCount(count + 1)
          }).catch(err => console.log(err))

           axios
          .get(`${process.env.REACT_APP_API_PATH}createRefreshToken`)
          .then((res) => {
            document.cookie =
          `RefreshToken=${res?.data?.token}`;
          }).catch(err=>console.log(err))
    }
  },[]) 

  const fetchCustomerData = () => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}customer?id=${userId}`)
      .then((res) => {
        setUserDetails(res?.data[0]);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    !isNaN(userId) && fetchCustomerData();
  }, [userId]);

  return (
    <div className={classes.navbarWrapper}>
      {isSearchModalOpen && (
        <SearchModal
          setSearch={setSearch}
          debounceSearch={debounceSearch}
          search={search}
          handleClose={handleSearchModalClose}
          searchInputHandler={searchInputHandler}
          searchHandler={searchHandler}
        />
      )}
      <Navbar
        expand="md"
        className="sticky-top px-0 py-0 px-s-4"
        expanded={expanded}
      >
        <Navbar.Brand style={{margin: 0 }} href="#">
          <div style={{ display: 'flex' }}>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              onClick={() => setExpanded(!expanded)}
            />
            <Nav.Link href={HomePath}>
            <img
              className={classes.logo}
              src="../../images/upvan-icon.png"
              alt="logo"
            />
            </Nav.Link>
          </div>
          {screen && (
            <RightSideMenu
              setIsSearchModalOpen={setIsSearchModalOpen}
              searchInputHandler={searchInputHandler}
              searchHandler={searchHandler}
            />
          )}
        </Navbar.Brand>
        <Navbar.Collapse id="navbarScroll" className="ps-lg-1">
          {!screen ? (
            <Nav
              className="me-auto ms-lg-1 my-lg-0"
              style={{ color: "#5a5254" }}
              navbarScroll
            >
              {navbarData.map((item, index) => (
                <div key={index}>
                  {item?.subMenu?.length == 0 ? (
                    <Nav.Link
                      active={pathname === item.redirectURL}
                      href={item.redirectURL}
                      className={
                       pathname === item.redirectURL
                          ? "active-link"
                          : ""
                      }
                    >
                      {item.title}
                    </Nav.Link>
                  ) : (
                    <div className={classes.mega_menu}>
                      <Nav.Link
                        className={
                         pathname === item.redirectURL
                            ? "active-link"
                            : ""
                        }
                        active={pathname === item.redirectURL}
                        href={item.redirectURL}
                      >
                        {item.title}
                      </Nav.Link>
                      <div
                        className={`${classes.mega_wrap} ${classes.megaWrapDropdown}`}
                      >
                        {item?.subMenu?.map((ele, i) => (
                          <NavDropdown.Item href={ele.subTitle === "All Clothing" ? `${ele.subRedirectURL}` : `${ele.subRedirectURL}?category=${encodeURIComponent(ele.subTitle)}`} key={i}>
                            {ele.subTitle}
                          </NavDropdown.Item>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </Nav>
          ) : (
            <Accordion className="navbarAccrdion">
              {navbarData.map((item, index) => (
                <Accordion.Item
                  eventKey={JSON.stringify(index)}
                  key={index}
                  onClick={() => setExpanded(false)}
                >
                  <Accordion.Header
                    className={
                      item?.subMenu?.length <= 1 && "navbarAccrdionHeader"
                    }
                    onClick={(e) => {
                      if (item?.subMenu?.length === 0) {
                        navigate(item.redirectURL);
                        setExpanded(false);
                      } else {
                        e.stopPropagation();
                      }
                    }}
                  >
                    {item.title}
                    {item?.subMenu?.length === 0 && (
                      <span className="accordion-icon"></span>
                    )}
                  </Accordion.Header>
                  {item?.subMenu?.length > 0 && (
                    <Accordion.Body>
                      {item?.subMenu?.map((ele, i) => (
                        <div key={i}>
                          <p
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(ele.subTitle === "All Clothing" ? `${ele.subRedirectURL}` : `${ele.subRedirectURL}?category=${encodeURIComponent(ele.subTitle)}`);
                              setExpanded(false);
                            }}
                          >
                            <span>-</span>
                            {ele.subTitle}
                          </p>
                        </div>
                      ))}
                    </Accordion.Body>
                  )}
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Navbar.Collapse>
        {!screen && (
          <RightSideMenu
            searchProduct={search}
            setIsSearchModalOpen={setIsSearchModalOpen}
            searchInputHandler={searchInputHandler}
            searchHandler={searchHandler}
          />
        )}
      </Navbar>
    </div>
  );
}

export default NavbarMenu;
