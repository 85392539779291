export const CouponIcon = () => (
  <svg
    width="60"
    height="42"
    viewBox="0 0 60 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.6932 13.615V5.30734C57.6948 4.94325 57.6242 4.58247 57.4856 4.2458C57.3469 3.90913 57.143 3.60325 56.8856 3.3458C56.6281 3.08834 56.3222 2.88442 55.9855 2.7458C55.6489 2.60717 55.2881 2.53658 54.924 2.53811H18.924V4.38426H17.0778V2.53811H5.07785C4.71376 2.53658 4.35297 2.60717 4.01631 2.7458C3.67964 2.88442 3.37375 3.08834 3.1163 3.3458C2.85885 3.60325 2.65493 3.90913 2.5163 4.2458C2.37768 4.58247 2.30709 4.94325 2.30862 5.30734V13.615C4.26714 13.615 6.14545 14.3931 7.53033 15.7779C8.91521 17.1628 9.69323 19.0411 9.69323 20.9996C9.69323 22.9582 8.91521 24.8365 7.53033 26.2214C6.14545 27.6062 4.26714 28.3843 2.30862 28.3843V36.692C2.30709 37.056 2.37768 37.4168 2.5163 37.7535C2.65493 38.0902 2.85885 38.3961 3.1163 38.6535C3.37375 38.911 3.67964 39.1149 4.01631 39.2535C4.35297 39.3921 4.71376 39.4627 5.07785 39.4612H17.0778V37.615H18.924V39.4612H54.924C55.2881 39.4627 55.6489 39.3921 55.9855 39.2535C56.3222 39.1149 56.6281 38.911 56.8856 38.6535C57.143 38.3961 57.3469 38.0902 57.4856 37.7535C57.6242 37.4168 57.6948 37.056 57.6932 36.692V28.3843C55.7347 28.3843 53.8564 27.6062 52.4715 26.2214C51.0866 24.8365 50.3086 22.9582 50.3086 20.9996C50.3086 19.0411 51.0866 17.1628 52.4715 15.7779C53.8564 14.3931 55.7347 13.615 57.6932 13.615ZM18.924 33.9227H17.0778V30.2304H18.924V33.9227ZM18.924 26.5381H17.0778V22.8458H18.924V26.5381ZM18.924 19.1535H17.0778V15.4612H18.924V19.1535ZM18.924 11.7689H17.0778V8.07657H18.924V11.7689ZM30.924 24.3458L29.2048 25.615L27.3355 23.0766L25.3855 25.615L23.6778 24.3458L25.5586 21.6573L22.6971 20.7804L23.3317 18.7727L26.1471 19.6843V16.3843H28.4548V19.6381L31.2702 18.7266L31.9048 20.7343L29.0317 21.6112L30.924 24.3458ZM45.5432 24.3458L43.8471 25.615L41.9779 23.0766L40.0163 25.615L38.2971 24.3458L40.1779 21.6573L37.3048 20.7804L37.9394 18.7727L40.7548 19.6843V16.3843H43.0625V19.6381L45.8778 18.7266L46.5125 20.7343L43.6509 21.6112L45.5432 24.3458Z"
      fill="#EB2675"
    />
  </svg>
);
