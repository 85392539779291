import React, { useEffect, useState } from 'react'
import axios from 'axios'
import classes from './Blogs.module.css'
import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import { IoIosSearch } from "react-icons/io";
import BlogDetails from '../blogDetails/BlogDetails'
import { Helmet } from 'react-helmet'


function Blogs() {
    const [allblogs,setAllBlogs]=useState([])
    const [allblogTags,setAllBlogTags]=useState([])
    const [searchedBlogTag,setSearchedBlogTag]=useState("")

    const searchInputHandler =(e)=>{
        setSearchedBlogTag(e.target.value)
    }

    const searchHandler =async(e)=>{
       e.preventDefault();
       await(axios.get(`${process.env.REACT_APP_API_PATH}blogsBySearchedTag?tag=${searchedBlogTag}`)
            .then(res=>{
                setAllBlogs(res.data)
            })
        )
        
    }

    const getAllblogs = async()=>{
        await(axios.get(`${process.env.REACT_APP_API_PATH}allBlogs`)
            .then(res=>{
                setAllBlogs(res.data)
            })
        )

        await(axios.get(`${process.env.REACT_APP_API_PATH}blogTags`)
            .then(res=>{
                setAllBlogTags(res.data)
                console.log(res)
            })
        )
    }
    useEffect(()=>{
        getAllblogs()
    },[])
    
  return (
    <div className='section'>
      <Helmet>
        <title>Blogs - Upvan Fashion</title>
        <meta name="description" content="Blogs - Upvan Fashion" />
      </Helmet>
        <Container>
        <Row>
            <div className='col'>
                <div className={classes.filterPanelText}>
                    <h2>Latest Blogs</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    
                    <div className={classes.blogFilterPanel}>
                        {/* <form className={classes.Search} onSubmit={searchHandler}>
                            <span><i className='pe-7s-search'></i></span>
                            <input type="text" name="searchBlog" placeholder='Search ...' onChange={searchInputHandler} />
                        </form> */}
                        <div>
                            <ul>
                                {allblogTags.map((item)=>{
                                    return(
                                        <li><Link>{item.tag}</Link></li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
        <Row>
            {
                allblogs.map((item,index)=>{
                    var date = new Date(item.published_date_iso8601);

                // Format the date
                var options = { year: 'numeric', month: 'long', day: '2-digit' };
                var formattedDate = date.toLocaleDateString('en-US', options);

                    return(
                        <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-5 col' key={index}>
                            <Link to={`/blog-details/${item.id}/`}>
                                <div className={classes.card}>
                                    <div className={classes.image_section}>
                                        <img src={`https://store-cv6fgg2tx1.mybigcommerce.com${item.thumbnail_path}`} alt="blog"/>
                                    </div>
                                    <div className={classes.card_content}>
                                        <div className={classes.title_and_date}>
                                            <p className={classes.title}>Spotlight</p>
                                            <div className={classes.card_date}><p>{formattedDate}</p></div>
                                        </div>
                                        <div className={classes.heading_para}>
                                            <p className={classes.heading}>{item.title}</p>
                                            <div className={classes.para}>
                                            <p >{item.summary}</p>
                                            </div>
                                            
                                        </div>
                                        <button className={classes.learn_more_btn}>Learn More<i className="pe-7s-angle-right"></i></button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })
            }
        </Row>
    </Container>
    </div>
  )
}

export default Blogs