export const extractOptionsAndCountVariants = (products) => {
    const options = {};
    const variantCount = {};
    const variantCountColor = {};
    products.forEach(product => {
      const productOptions = product.node.productOptions.edges;
      productOptions.forEach(option => {
        const displayName = option.node.displayName;
        const values = option.node.values.edges;
        if (!options[displayName]) {
          options[displayName] = [];
        }
        if(displayName == "Size"){
          values.forEach(value => {
            const label = value.node.label;
            if (!options[displayName].includes(label)) {
              options[displayName].push(label);
            }
            if (!variantCount[label]) {
              variantCount[label] = 0;
            }
            variantCount[label]++;
          });
        }
        if(displayName == "Color"){
          values.forEach(value => {
            const label = value.node.label;
            if (!options[displayName].includes(label)) {
              options[displayName].push(label);
            }
            if (!variantCountColor[label]) {
              variantCountColor[label] = 0;
            }
            variantCountColor[label]++;
          });
        }
      });
    });
    return { options, variantCount, variantCountColor};
  };