import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useCountStore = create((set) => ({
    counts: 0,
    WishListIds:[],
    updateCount: (payload) => set((state) => ({ counts: payload })),
    updateWishListIds: (payload) => set((state) => ({ WishListIds: payload })),
  }));


  export const useCurrencyCode = create(persist((set) => ({
    defaultCurrency: '',
    updateCurrency: (payload) => set((state) => ({ defaultCurrency: payload })),
  }),
  {
    name: "currency", // unique name for the storage key
    getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
  }
)
)