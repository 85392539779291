import React from "react";
import PageNotFoundIcon from "../../Svgs/PageNotFoundIcon";
import { Container, Row } from "react-bootstrap";
import classes from "./PageNotFound.module.css"
import { useNavigate } from "react-router-dom";
import { HomePath } from "../../constant/constant";

function PageNotFound() {
  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <div className={classes.pageNotFoundWrapper}>
          <PageNotFoundIcon />
          <p className={classes.para}>We couldn’t find the page you were looking for</p>
          <button className={classes.btn} onClick={()=>navigate(HomePath)}>Back to Home</button>
        </div>
      </Row>
    </Container>
  );
}

export default PageNotFound;
