import axios from "axios";

export const scrollToTop = () => {
    window.scrollTo({
        top:0,
        behavior:'smooth'
    })
}

export const createBigComToken = () => {
  let now = new Date()
     axios
    .get(`${process.env.REACT_APP_API_PATH}createBigComToken`)
    .then((res) => {
      document.cookie =
    `BigComToken=${res?.data?.data?.token};expires=` +
    now.toUTCString() +
    ";path=/";
    }).catch(err => console.log(err))
}

export const createRefreshToken = () => {
  let now = new Date()
     axios
    .get(`${process.env.REACT_APP_API_PATH}createRefreshToken`)
    .then((res) => {
      document.cookie =
    `RefreshToken=${res?.data?.token};expires=` +
    now.toUTCString() +
    ";path=/";
    }).catch(err=>console.log(err))
}