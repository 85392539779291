import React, { useState, useEffect } from "react";
import classes from "./CartSteps.module.css";
import ShoppingBag from "../ShoppingBag/ShoppingBag";
import axios from "axios";
import { userDetailsStore } from "../../../store/GlobalUserData";
import { addToCartCountStore } from "../../../store/CartCountStore";
import Loader from "../../Loader/Loader";
import { scrollToTop } from "../../CommonComponents/CommonHelperFunctions";
import { getBigComToken, getUserId } from "../../../utils/getUserId";
import EmptyCard from "../../emptCard/EmptyCard";
import { useNavigate } from "react-router-dom";
import { CheckoutPath } from "../../../constant/constant";
import CommonConsentPopup from "../../CommonComponents/CommonConsentPopup";

export default function CartPage() {
  const [cartDetails, setCartDetails] = useState();
  const { userDetails, setUserDetails } = userDetailsStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {addToCartCount, setAddToCartCount , setItemIdsinCart} = addToCartCountStore();
  const [removeItem, setRemoveItem] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isAddAddressesModal, setIsAddAddressesModal] = useState(false);
  const [deafultAddress, setDeafultAddresses] = useState({});
  const userId = getUserId();
  const [giftCertificateData, setGiftCertificateData] = useState([])
  const [items, setItems] = useState([]);
  const [saleProductTotal, setSaleProductTotal] = useState(0);
  const [discountProductTotal, setDiscountProductTotal] = useState(0);
  const [currency, setCurrency] = useState("₹");
  const BigComToken = getBigComToken()
  const [cartItems, setCartItems] = useState([]);
  const [btnAnimation , setBtnAnimation] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [insufficientStokeData , setInsufficientStokeData] = useState([])
  const [insufficientStokeModal , setInsufficientStokeModal] = useState(false)
  const [checkedItems, setCheckedItems] = useState(
    items?.reduce((acc, item) => ({ ...acc, [item]: false }), {})
  );
  const [productDetails, setProductDetails] = useState();
  const [productVarientsDetails, setProductVarientsDetails] = useState([]);

  const getCartDetails = async () => {
    setLoading(true);
    setRemoveItem(false);
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}cartDetails?cartId=${userDetails?.notes}`
      )
      .then((res) => {
        setCartDetails(res?.data.data);
        setGiftCertificateData(res?.data?.data?.line_items?.gift_certificates.map(v=>{
          return {
            ...v,
            type:'gifts'
          }
        }))
        setAddToCartCount(res?.data?.data?.line_items?.physical_items?.length+res?.data?.data?.line_items?.gift_certificates?.length);
        setItemIdsinCart(res?.data?.data?.line_items?.physical_items.map(v=>v.variant_id))
        let productsIds = res?.data?.data?.line_items?.physical_items.map(
          (item) => item.product_id
        );
        
        productsIds = productsIds?.filter(
          (item, index) => productsIds.indexOf(item) === index
        );
        if(res.data?.status !== 404){
          productsVarients(productsIds);
          fetchVariantData(res?.data?.data?.line_items?.physical_items.map(v=>v.variant_id))
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    scrollToTop();
    userDetails?.notes !== "" ? getCartDetails() : setCartItems([]);
  }, [removeItem, addToCartCount]);

  const fetchAddressData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_PATH}address?id=${userId}`)
      .then((res) => {
        setDeafultAddresses(res?.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    !isNaN(userId) && fetchAddressData();
  }, [isAddAddressesModal]);

  useEffect(() => {
    let data = cartDetails?.line_items?.physical_items?.map((item) => item.id);
    setItems(data);
    cartDetails?.currency?.code === "INR" ? setCurrency("₹") : setCurrency("$");
  }, [cartDetails]);

  useEffect(() => {
    // let data = cartDetails?.line_items?.physical_items?.filter(
    //   (item) => checkedItems[item.id] === true
    // );
    // let discountData = cartDetails?.discounts?.filter(
    //   (item) => checkedItems[item.id] === true
    // );
    let data = cartDetails?.line_items?.physical_items
    let dataForGifts = giftCertificateData
    let discountData = cartDetails?.discounts
    let saleGiftsTotal = dataForGifts?.reduce((previous, current) => {
      return previous + current.amount;
    }, 0);
    let saleTotal = data?.reduce((previous, current) => {
      return previous + current.sale_price * current.quantity;
    }, 0);
    let discountDataTotal = discountData?.reduce((previous, current) => {
      return previous + current.discounted_amount;
    }, 0);
    setSaleProductTotal(saleTotal + saleGiftsTotal);
    setDiscountProductTotal(discountDataTotal);
  }, [checkedItems, cartDetails]);

  useEffect(() => {
    let data = cartDetails?.line_items?.physical_items;
    if(productVarientsDetails.length > 0){
      let newData = data?.map((item)=>{
        const matchData = productVarientsDetails.find(ele => ele.id == item.variant_id)?.option_values?.filter((item)=> item.option_display_name == "Size")[0]?.label
        return {...item,size:matchData ?  matchData: null    }
      })
      let cartData = data?.map((item)=>{
        const matchData = productVarientsDetails?.find(ele => ele.id == item.variant_id)
        return {...item,inventory_level:matchData?.inventory_level ?  matchData.inventory_level: 0    }
      })      
      let cartInsufficientData = cartData?.filter((item)=> item.inventory_level == 0  || item.inventory_level < item.quantity)
      setInsufficientStokeData(cartInsufficientData)
      let modalOpen = cartInsufficientData?.length > 0 ? true : false
      setInsufficientStokeModal(modalOpen)
      if(newData?.length > 0 ){
        setCartItems([...newData, ...giftCertificateData]);
      }
    }
  }, [cartDetails,productVarientsDetails]);
  const fetchVariantData = async(productsIds) => {    
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}getVariantByids?ids=${productsIds}`
      )
      .then((res) => {
        setProductVarientsDetails(res?.data)
      }).catch(err => console.log(err));
  }

  const productsVarients = async (productsIds) => {
    await axios
      .get(
        `${process.env.REACT_APP_API_PATH}productsVarientsById?productsIds=${productsIds}&BigComToken=${BigComToken}`
      )
      .then((res) => {
        setProductDetails(res?.data);
      }).catch(err => console.log(err));
  };

  useEffect(() => {
    // if (activeTab == 1 && !Object.values(checkedItems)?.some((value) => value === true)) {
    //   setBtnAnimation(true);
    //   setErrorMessage("Please select Product")
    // }
     if(activeTab == 2 && !deafultAddress){
      setBtnAnimation(true);
      setErrorMessage("Please add Address")
    }
    else{
      setBtnAnimation(false)
      setErrorMessage("")
    }
  }, [activeTab, checkedItems, deafultAddress]);

  const deleteCartItems = () => {
    setLoading(true)
    let productIds =  insufficientStokeData?.map((item) => {
      return item.id;
    });
    let selectedCartProduct = {
      cartId:cartDetails?.id,
      productIds:productIds,
      customer_id:userDetails?.id,
      isCustomerUpdate: cartDetails?.line_items?.physical_items?.length == insufficientStokeData?.length ? true : false
    }
    axios
    .post(`${process.env.REACT_APP_API_PATH}deleteInsufficientCartItems`, {
      selectedCartProduct,
      addToCartCount,
    }).then((res) => {
      if (
        Array.isArray(res?.data[0]?.data) &&
        res.data[0].data[0] !== undefined
      ) {
        setUserDetails(res.data[0].data[0]);
      }
      const lastCartCount = res.data[res.data.length - 1]?.cartCount;
      setAddToCartCount(lastCartCount || 0);
      setLoading(false)
      setRemoveItem(true);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false)
    });
    setInsufficientStokeModal(false)
  }
  const handleDeleteCoupon = () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_API_PATH}deleteCoupon`, {
        coupon_code: cartDetails?.coupons[0]?.code,
        checkoutId: cartDetails?.id,
      })
      .then((res) => {
        getCartDetails()
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(()=>{
    if(cartDetails?.coupons?.length > 0) {
      handleDeleteCoupon()
    }

  },[cartDetails?.coupons])

  return (
    <div style={{paddingTop:"0px"}} className={classes.wizard}>
      <CommonConsentPopup handleSubmit={deleteCartItems} modalType={'Remove Item from Cart'} open={insufficientStokeModal} handleClose={()=> deleteCartItems()} deleteCartModal={true}>
        <div>
        <h6>insufficient Stoke of Below Product</h6>
        {insufficientStokeData?.map((item,index)=>(
          <p key={index}>{item.name}</p>
        ))}
        </div>
        </CommonConsentPopup>
      {loading && <Loader />}
      {(cartItems?.length === 0 || cartItems === undefined) ? 
      <EmptyCard pageName={"cart"}/> :
      <>
      <div className={classes.shoppingCartHeading}>
        <p  style={{fontSize: "16px", margin: "0px", fontWeight: 'bolder', textTransform: "uppercase",}}>Shopping Cart</p>
        </div>
        <hr/>
      <form role="form" action="indexphysicalItem.html" className="login_box">
        <div className={classes.tab_content} id="main_form">
          <div className={`${classes.tab_pane} ${classes.active}`}>
              <ShoppingBag
                loading={loading}
                giftCertificateData={giftCertificateData}
                physicalItem={cartItems}
                setRemoveItem={setRemoveItem}
                checkedItems={checkedItems}
                items={items}
                productDetails={productDetails}
                setCheckedItems={setCheckedItems}
                currency={currency}
                setCartItems={setCartItems}
              />
            <div className={classes.shopping_bag_content}>
              <div className={classes.right}>
                {deafultAddress && (
                  <div className={classes.default_address_section}>
                    <div className={classes.address_with_pincode}>
                      <p className={classes.deafult_address}>Deafult Address</p>
                      <p className={classes.name_and_pincode}>
                        {deafultAddress?.first_name} {deafultAddress?.last_name}
                      </p>
                    </div>
                    <p className={classes.address}>
                      {" "}
                      {deafultAddress?.address1}, {deafultAddress?.address2},{" "}
                      {deafultAddress?.city},{" "}
                      {deafultAddress?.state_or_province},{" "}
                      {deafultAddress?.postal_code}
                    </p>
                  </div>
                )}
                <div className={classes.price_detil_section}>
                  <p className={classes.heading}>
                    Price Details (Total{" "}
                    {
                      Object.values(checkedItems)?.filter(
                        (value) => value === true
                      ).length
                    }{" "}
                    items)
                  </p>
                  <div className={classes.price_info}>
                    <div className={classes.two_values}>
                      <p>Total MRP</p>
                      <p className={classes.price}>
                        {currency}
                        {saleProductTotal}
                      </p>
                    </div>
                    <div className={classes.two_values}>
                      <p>{cartDetails.coupons.length!==0?`Discount on MRP (Coupon: ${cartDetails.coupons[0].code})`:"Discount on MRP"}</p>
                      <p className={`${classes.price} ${classes.green}`}>
                        {currency}
                        {discountProductTotal}
                      </p>
                    </div>
                    {/* <div className={classes.two_values}>
                      <p>Platform Fee</p>
                      <p className={classes.price}>$2</p>
                    </div> */}
                    <div className={classes.two_values}>
                      <p>Shipping Fee</p>
                      <p className={`${classes.price} ${classes.green}`}>
                        FREE
                      </p>
                    </div>
                  </div>
                  <div className={classes.total_amount}>
                    <p>Total Amount</p>
                    <p className={classes.price}>
                      {currency}
                      {saleProductTotal - discountProductTotal}
                    </p>
                  </div>
                  <button
                    type="button"
                    className={`${classes.default_btn} ${classes.next_step} ${btnAnimation && classes.horizontalShake}`}
                    onClick={() => !btnAnimation && navigate(CheckoutPath, {state:checkedItems})}
                  >
                    PROCEED TO BUY
                  </button>
                  {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      </>
      }
    </div>
  );
}
