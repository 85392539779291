import React, { useRef, useState } from "react";
import LoginSignupWrapper from "../../components/loginSignupWrapper/loginSignupWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classes from "./Signup.module.css";
import ErrorMessage from "../../components/errorMessage/ErrorMessage";
import axios from "axios";
import _ from "lodash";
import {
  HomePath,
  LoginPath,
  PrivacyPolicyPath,
  TermsPath,
} from "../../constant/constant";
import { Field, Form, Formik } from "formik";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { signupValidationSchema } from "../../components/CommonComponents/ValidationSchema";
import Loader from "../../components/Loader/Loader";
import OTPComponent from "../../components/otpComponent/OTPComponent";
import { userDetailsStore } from "../../store/GlobalUserData";
import { Helmet } from "react-helmet";
import { showErrorToast } from "../../utils/toastUtils";

function Signup() {
  const [error, setError] = useState({});
  const [isOTPScreen, setIsOTPScreen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { setUserDetails } = userDetailsStore();
  const loginData = useLocation();
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const formikRef = useRef();
  let now = new Date();
  let time = now.getTime();
  let expireTime = time + 86400 * 1000 * 7;

  const submitLoginHandler = async (data) => {
    setLoading(true);
    if (!isOTPScreen) {
      await axios
        .get(
          `${process.env.REACT_APP_API_PATH}isCustomerNumberExist?phone=${data.phone}&email=${data.email}`
        )
        .then((response) => {
          if (response?.data?.isEmail && response?.data?.isMobile) {
            setError({
              ...error,
              email: response?.data?.eamilMessage,
              phone: response?.data?.mobileMessage,
            });
          } else if (response?.data?.isEmail) {
            setError({ ...error, email: response?.data?.message });
          } else if (response?.data?.isMobile) {
            setError({ ...error, phone: response?.data?.message });
          } else {
            setIsOTPScreen(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err)
        });
    } else if (isOTPScreen) {
      if (resendOTP) {
        await axios
          .post(`${process.env.REACT_APP_API_PATH}sendSMS`, {
            mobile_Number: data.phone,
          })
          .then(() => {
            setResendOTP(false);
            setLoading(false);
          })
          .catch((err) => console.log({ err }));
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_PATH}verify_otp`, {
            mobile_Number: data.phone,
            otp: data.otp.join(""),
          })
          .then(async (res) => {            
            if (!res.data.success) {
              showErrorToast(res.data.message);
            } else {
            await axios
              .post(`${process.env.REACT_APP_API_PATH}userRegistration`, data)
              .then((res) => {
                setLoading(false);
                now.setTime(expireTime);
                document.cookie =
                  `userId=${res?.data?.data[0]?.id};expires=` +
                  now.toUTCString() +
                  ";path=/";
                setUserDetails(res?.data?.data[0]);
                navigate(HomePath);
              })
              .catch((err) => {
                console.log(err);
                setError({
                  ...error,
                  email: err?.response?.data?.details[".customer_create"],
                });
              });
            }
            setLoading(false);
          })
          .catch((err) => {
            console.log({err});
            
            setLoading(false)});
      }
    }
  };
  return (
    <LoginSignupWrapper title={isOTPScreen ? "" : "Create Account"}>
       <Helmet>
        <title>Upvan Fashion - Registration</title>
        <meta name="description" content="Upvan Fashion - Registration" />
      </Helmet>
      {loading && <Loader />}
      <Formik
        innerRef={formikRef}
        initialValues={{
          full_name: "",
          email: loginData?.state?.email ?? "",
          phone: loginData?.state?.phone ?? "",
          password: "",
          confirm_password: "",
          otp: Array(6).fill(""),
        }}
        validationSchema={signupValidationSchema()}
        onSubmit={async (values) => {
          submitLoginHandler(values);
        }}
      >
        {({ setFieldValue, errors, values }) => {
          return (
            <Form autoComplete='off' className={classes.formWrapper}>
              {!isOTPScreen ? (
                <>
                  <div className={classes.inputGroup}>
                    <label>Full name*</label>
                    <Field
                      type="text"
                      placeholder="Enter your full name"
                      name="full_name"
                    />
                    {errors.full_name && (
                      <ErrorMessage message={errors.full_name} />
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Mobile number*</label>
                    <PhoneInput
                      name="phone"
                      value={values.phone}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      country={"in"}
                      onChange={(phone) => {
                        setFieldValue("phone", phone);
                        setError({ ...error, phone: "" });
                      }}
                    />
                    {(errors.phone || error.phone) && (
                      <ErrorMessage message={errors.phone ?? error.phone} />
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Email address*</label>
                    <Field
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                        setError({ ...error, email: "" });
                      }}
                    />
                    {(errors.email || error.email) && (
                      <ErrorMessage message={errors.email ?? error.email} />
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Password*</label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        autoComplete="off"
                      />
                      <div
                        className={classes.isPasswordVisible}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                      </div>
                    </div>
                    {errors.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                  </div>
                  <div className={classes.inputGroup}>
                    <label>Confirm password*</label>
                    <div style={{ position: "relative" }}>
                      <Field
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Enter confirm  password"
                        name="confirm_password"
                        autoComplete="off"
                      />
                      <div
                        className={classes.isPasswordVisible}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? <IoMdEye /> : <IoMdEyeOff />}
                      </div>
                    </div>
                    {errors.confirm_password && (
                      <ErrorMessage message={errors.confirm_password} />
                    )}
                  </div>
                </>
              ) : (
                <OTPComponent
                  values={values}
                  setFieldValue={setFieldValue}
                  formikRef={formikRef}
                  phone={values.phone}
                  setResendOTP={setResendOTP}
                />
              )}
              {!isOTPScreen && (
                <>
                  <p className={classes.subText}>
                    To verify your number, we will send you a text message with
                    a temporary code. Message and data rates may apply.
                  </p>
                  <div className={classes.inputGroup}>
                    <button className={classes.submitButton} type="submit">
                      Verify Mobile Number
                    </button>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
      {!isOTPScreen && (
        <>
          <div className={classes.line} />
          <div className={classes.footerGroup}>
            <p className={classes.privacyText}>
              Already have an account?{" "}
              <span onClick={() => navigate(LoginPath)}> Sign in </span>
            </p>
            <p className={classes.privacyText}>
              By creating an account or logging in, you agree to Upvan{" "}
              <span onClick={() => navigate(TermsPath)}>Conditions of Use</span>{" "}
              and
              <span onClick={() => navigate(PrivacyPolicyPath)}>
                {" "}
                Privacy Notice.
              </span>
            </p>
          </div>
        </>
      )}
    </LoginSignupWrapper>
  );
}

export default Signup;
