import React from "react";

function NetBankingPayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_36_103"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_36_103)">
        <path
          d="M10.6 35.95V19H13.6V35.95H10.6ZM22.7 35.95V19H25.7V35.95H22.7ZM4 41.95V38.95H44V41.95H4ZM34.4 35.95V19H37.4V35.95H34.4ZM4 16V13.35L24 1.95001L44 13.35V16H4ZM10.7 13H37.3L24 5.40001L10.7 13Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default NetBankingPayIcon;
