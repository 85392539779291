import React from "react";

function UPIPayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <rect
        x="0.4"
        y="0.4"
        width="47.2"
        height="47.2"
        rx="3.6"
        stroke="black"
        strokeWidth="0.8"
      />
      <path
        d="M17.6037 16.4545H20.2386V25.9574C20.2386 26.9991 19.9924 27.9152 19.5 28.706C19.0123 29.4967 18.3258 30.1146 17.4403 30.5597C16.5549 31 15.5204 31.2202 14.3366 31.2202C13.1482 31.2202 12.1113 31 11.2259 30.5597C10.3404 30.1146 9.65388 29.4967 9.16619 28.706C8.6785 27.9152 8.43466 26.9991 8.43466 25.9574V16.4545H11.0696V25.7372C11.0696 26.3433 11.2022 26.883 11.4673 27.3565C11.7372 27.83 12.116 28.2017 12.6037 28.4716C13.0914 28.7367 13.669 28.8693 14.3366 28.8693C15.0043 28.8693 15.5819 28.7367 16.0696 28.4716C16.562 28.2017 16.9408 27.83 17.206 27.3565C17.4711 26.883 17.6037 26.3433 17.6037 25.7372V16.4545ZM23.1026 31V16.4545H28.5572C29.6746 16.4545 30.6121 16.6629 31.3697 17.0795C32.132 17.4962 32.7073 18.0691 33.0955 18.7983C33.4885 19.5227 33.685 20.3466 33.685 21.2699C33.685 22.2027 33.4885 23.0312 33.0955 23.7557C32.7025 24.4801 32.1225 25.0507 31.3555 25.4673C30.5884 25.8793 29.6438 26.0852 28.5217 26.0852H24.9066V23.919H28.1665C28.82 23.919 29.355 23.8054 29.7717 23.5781C30.1883 23.3509 30.4961 23.0384 30.695 22.6406C30.8986 22.2429 31.0004 21.786 31.0004 21.2699C31.0004 20.7538 30.8986 20.2992 30.695 19.9062C30.4961 19.5133 30.186 19.2079 29.7646 18.9901C29.3479 18.7675 28.8105 18.6562 28.1523 18.6562H25.7376V31H23.1026ZM38.6087 16.4545V31H35.9737V16.4545H38.6087Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}

export default UPIPayIcon;
