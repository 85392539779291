import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from "./WishList.module.css";
import {
  TiStarFullOutline,
  TiStarOutline,
  TiStarHalfOutline,
} from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";
import _ from "lodash";
import Loader from "../../components/Loader/Loader";
import { getBigComToken, getUserId } from "../../utils/getUserId";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import { useCountStore, useCurrencyCode } from "../../store/CountStore";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import { scrollToTop } from "../../components/CommonComponents/CommonHelperFunctions";
import EmptyCard from "../../components/emptCard/EmptyCard";
import { addToCartCountStore } from "../../store/CartCountStore";
import { userDetailsStore } from "../../store/GlobalUserData";
import { CartPath, LoginPath } from "../../constant/constant";
import CommonConsentPopup from "../../components/CommonComponents/CommonConsentPopup";
import { Helmet } from "react-helmet";

function WishList() {
  const mainContext = useContext(MainContext);
  const [wishListData, setWishListData] = useState([]);
  const [count, setCount] = useState(0);
  const BigComToken = getBigComToken()
  const [loading, setLoading] = useState(true);
  const customerId = getUserId();
  const { updateCount,updateWishListIds } = useCountStore();
  const { defaultCurrency } = useCurrencyCode()
  const navigate = useNavigate();
  const userId = getUserId()
  const [id, setId] = useState('')
  const [openConsent,setOpenConsent] = useState(false)
  const { itemIdsinCart,setItemIdsinCart, setAddToCartCount } = addToCartCountStore()
  const { userDetails,setUserDetails } = userDetailsStore();
  const [cartId, setCartId] = useState()
  const location = useLocation()

  useEffect(()=>{
    if (isNaN(userId)) { 
      navigate(LoginPath,{state:{from: location.pathname + location.search}});
      localStorage.setItem('previous', JSON.stringify( {state: {from: location.pathname + location.search}}))
    } 
 },[userId])

  const fetchWishlistProductData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_PATH}wishlist?id=${customerId}`)
      .then((res) => {
        let wishListIdsData = res.data?.map((ele) => ele.items[0]);
        let ids = res.data?.map((ele) => ele.items[0].product_id);
        updateWishListIds(ids)
        if (ids.length > 0) {
          const details = {
            productId: ids.join(",")
          };
          axios
            .post(
              `${
                process.env.REACT_APP_API_PATH
              }getProductsByFilter`,{ details: details,sort: "", wishlistPage:true, currencyCode: defaultCurrency }, {
                headers: {
                  Authorization: 'Bearer ' + BigComToken
                }
              }
            )
            .then((res) => {
              setLoading(false);
              const data = res?.data?.data?.site?.products?.edges?.map(
                (item) => item.node
              );
              const wishlist = data?.map((item) => {
                return {
                  id: item.entityId,
                  image: item.images?.edges[0].node.urlOriginal,
                  name: item?.name,
                  basePrice: item?.prices.basePrice.value,
                  basePriceCurrency: item?.prices.basePrice.currencyCode,
                  price: item?.prices.price.value,
                  priceCurrency: item?.prices.price.currencyCode,
                  review: item?.reviews?.edges?.map((ele) => ele?.node?.rating),
                };
              });
              const newData = wishlist?.map((item) => {
                const matchingData = wishListIdsData.find(
                  (d) => d.product_id === item.id
                );
                return {
                  ...item,
                  wishListId: matchingData ? matchingData.id : null,
                  variant_id: matchingData ? matchingData.variant_id : null
                };
              });
              setWishListData(newData);
              setCount(newData.length);
              updateCount(newData.length);
            })
            .catch((err) => console.log(err));
        } else {
          setLoading(false);
          setWishListData([]);
          setCount(0);
          updateCount(0);
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteWishlistHandler = async () => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_PATH}deleteWishlist`,{id : id})
      .then((res) => {
        setLoading(false);
        // showSuccessToast("Product Removed from Wishlist!");
        fetchWishlistProductData();
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)});
  };

  
  const addTocartHandler = async (e, data) => {
    e.stopPropagation()
    e.preventDefault()
      setLoading(true)
      const payload = {
        cart: {
          "customer_id": customerId,
          "line_items": [
            {
              "quantity": 1,
              "product_id": data.id,
              "list_price": data?.price,
              "variant_id": data.variant_id,
              "name": data.name
            }
          ]
        },
        cartId: userDetails?.notes
      }
      await (axios.post(`${process.env.REACT_APP_API_PATH}addToCart`, { payload })
        .then((res) => {
          if(res.data.success === false) {
            showErrorToast(res.data?.message)
          } else {
          showSuccessToast("Product added to cart!")
          setCartId(res.data.data.id)
          setAddToCartCount(res?.data?.data.line_items?.physical_items.length)
          setItemIdsinCart(res?.data?.data?.line_items?.physical_items.map(v=>v.variant_id))
          }
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          showErrorToast(err?.response?.data?.message)
        }))
  
  }

  const updateCustomer = async () => {
    const payload = {
      id: userDetails.id,
      notes: cartId
    }
    await (axios.post(`${process.env.REACT_APP_API_PATH}updatecustomer`, payload)
      .then((res) => {
        setUserDetails(res?.data[0])
      })
      .catch(err => console.log(err?.response?.data?.error)))
  }
  useEffect(() => {
    cartId !== undefined && updateCustomer()
  }, [cartId])

  useEffect(() => {
    scrollToTop()
    if (customerId) {
      fetchWishlistProductData();
    } else {
      setLoading(false);
    }
  }, [defaultCurrency]);

  useEffect(() => {
    setCount(mainContext.wishlistCount);
  }, [mainContext.wishlistCount]);

  return (
    <Fragment>
      <Helmet>
        <title>Wishlist - Upvan Fashion</title>
        <meta name="description" content="Wishlist - Upvan Fashion" />
      </Helmet>
    <div className={classes.wishListWrapper}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {wishListData?.length === 0 ? (
            <EmptyCard pageName={"Wishlist"}/>
          ) : (
            <div className={classes.container}>
              <div className={classes.wishlistContentSection}>
                <div className={classes.headingWrapper}>
                  <span className={classes.heading}>My Wishlist </span>
                  <span className={classes.count}>({count} items)</span>
                </div>
                <ul className={classes.products}>
                  {wishListData.map((item, index) => {
                    let reviewsRating = 0;
                    let reviewHalfRating = 0;
                    let rating_sum = item.review.reduce((acc, d) => {
                      return acc + d;
                    }, 0);
                    let rating = (rating_sum / item.review.length).toFixed(1);
                    _.isNaN(parseInt(rating))
                      ? (reviewsRating = 0)
                      : (reviewsRating = rating);
                    let halfRating =
                      !_.isNaN(parseInt(rating)) || _.isInteger(rating);
                    halfRating
                      ? (reviewHalfRating = 1)
                      : (reviewHalfRating = 0);
                    return (
                      <li className={classes.product} key={index}>
                        <div
                          className={classes.productInnerSection}
                          onClick={() => {
                            navigate(`/product_details/${item.id}`);
                          }}
                        >
                          <Link to={`/product_details/${item.id}`}>
                          <figure>
                            <IoIosCloseCircle
                              className={classes.closeIcon}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation();
                                setId(item.wishListId)
                                setOpenConsent(true)
                              }}
                            />
                            <div className={classes.productWrap}>
                              <img src={item.image} alt="Product" />
                            </div>
                          </figure>
                          <div className={classes.content}>
                            <h6>{item.name}</h6>
                            <div className={classes.priceStarWrapper}>
                              <div className={classes.price}>
                                <del>{item.basePriceCurrency==="INR"?"₹":"$"}{item.basePrice}</del>
                                <ins>{item.priceCurrency==="INR"?"₹":"$"}{item.price}</ins>
                              </div>
                              <span className={classes.starRating}>
                                {Array.from(
                                  { length: parseInt(reviewsRating) },
                                  (_, index) => (
                                    <TiStarFullOutline
                                      fill="#EB2675"
                                      width="14"
                                      key={index}
                                    />
                                  )
                                )}
                                {Array.from(
                                  { length: reviewHalfRating },
                                  (_, index) => (
                                    <TiStarHalfOutline
                                      fill="#EB2675"
                                      width="14"
                                      key={index}
                                    />
                                  )
                                )}
                                {Array.from(
                                  {
                                    length:
                                      5 -
                                      reviewHalfRating -
                                      parseInt(reviewsRating),
                                  },
                                  (_, index) => (
                                    <TiStarOutline
                                      fill="#EB2675"
                                      width="14"
                                      key={index}
                                    />
                                  )
                                )}
                              </span>
                            </div>
                            <button
                              className={classes.addToCart}
                              onClick={(e) => {
                                e.preventDefault() 
                                addTocartHandler(e,item)
                              }}
                            >
                              Add to Cart
                            </button>
                          </div>
                          </Link>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      <CommonConsentPopup handleSubmit={ deleteWishlistHandler} modalType={'Remove Item from Wishlist'} open={openConsent} handleClose={()=> setOpenConsent(false)}><p>Are you sure you want to remove product from wishlist?.</p></CommonConsentPopup>
    </div>
    </Fragment>
  );
}

export default WishList;
