import React, { useState } from "react";
import classes from "../profile/ProfileDetailsTab/EditProfileModal/EditProfileModal.module.css";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import _ from "lodash";
import { getUserId } from "../../utils/getUserId";

function CommonConsentPopup({ children, open, handleClose, handleSubmit, modalType,deleteCartModal }) {
  const userId = getUserId();

  return (
    <Modal
      show={open}
      className={`${classes.modal} editprofileModal`}
      id="newsletter"
      tabIndex="-1"
      onHide={handleClose}
      centered
    >
      <div className={classes.modalContent}>
      <div style={{ position: "relative" }}>
                  <h5 className={classes.modalHeader}>
                    {modalType}
                  </h5>
                  <button
                    className={classes.closeBtn}
                    onClick={handleClose}
                    tabIndex={0}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className={classes.addressFormUI}>{children}</div>
                <div className={classes.modalFooter}>
                  {!deleteCartModal && <button
                    className={classes.secondaryBtn}
                    onClick={handleClose}
                  >
                    Close
                  </button>}
                  <button
                    onClick={() => {
                        handleSubmit()
                        handleClose()
                    }}
                    className={classes.primaryBtn}
                  >
                    {deleteCartModal ? "Close": "Confirm"}
                  </button>
                </div>
      </div>
    </Modal>
  );
}

export default CommonConsentPopup;
