import React, { useEffect, useRef, useState } from "react";
import classes from "./RecommendedProduct.module.css";
import { Row } from "react-bootstrap";
import {
  TiStarFullOutline,
  TiStarOutline,
  TiStarHalfOutline,
} from "react-icons/ti";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import _ from "lodash";

function RecommendedProduct({title,data}) {
  const sliderRef = useRef(null);

    const goToFirstSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
    };
    useEffect(()=>{
      setTimeout(()=>{
        goToFirstSlide()
      },500)
    },[])
  const settings = {
    initialSlide: 0,
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,
    lazyLoad: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul className="recommendedProductSliderUL" style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          height: "19px",
          width: "19px",
          margin: "20px 5px",
          cursor: "pointer",
          borderRadius: '50%',
          backgroundColor: "white",
          border: "2px solid #c7c7c7"
        }}
      ></div>
    ),
  };
 
  return (
    <div className={classes.recommendedProduct}>
      <Row>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className={classes.cover}>
            <h4 className={classes.recommendedProductTitle}>
              {title}
            </h4>
          </div>
        </div>

        <div className="recommendedProductSlider">
          <ul className={classes.productSlider}>
            <Slider {...settings} ref={sliderRef}>
              {data.map((item, index) =>{
                let reviewsRating = 0;
                let reviewHalfRating = 0;
                 let rating = (item.reviews_rating_sum / item.reviews_count).toFixed(1);
                _.isNaN(parseInt(rating))
                  ? reviewsRating = 0
                  : reviewsRating = rating;
                let halfRating = !_.isNaN(parseInt(rating)) && _.isInteger(rating);
                halfRating ? reviewHalfRating = 1 : reviewHalfRating = 0;
                return (
                  <li className={classes.productWrapper} key={index}>
                    <figure>
                      <div className={classes.productWrap}>
                        <img src={item.images[0].url_thumbnail} alt="Product" />
                      </div>
                    </figure>
                    <div className={classes.product}>
                      <p className={classes.productName}>
                        {item.name}
                      </p>
                      <div className={classes.productDetailsWrap}>
                        <div className={classes.price}>
                          <del>₹{item.price}</del>
                          <ins>₹{item.calculated_price}</ins>
                        </div>
  
                        <span className="star-rating">
                          {Array.from({ length: parseInt(reviewsRating) }, (_, index) => (
                            <TiStarFullOutline fill="#EB2675" width="14" key={index} />
                          ))}
                          {Array.from({ length: reviewHalfRating }, (_, index) => (
                            <TiStarHalfOutline fill="#EB2675" width="14" key={index} />
                          ))}
                          {Array.from({ length: 5 - reviewHalfRating - parseInt(reviewsRating) }, (_, index) => (
                            <TiStarOutline fill="#EB2675" width="14" key={index} />
                          ))}
                        </span>
                      </div>
                    </div>
                  </li>
                )
              } 
              )}
            </Slider>
          </ul>
        </div>
      </Row>
    </div>
  );
}

export default RecommendedProduct;
